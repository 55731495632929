/**
 * @author Simon
 * @version 1.0.0
 */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-const */
/* eslint-disable key-spacing */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import Base64Util from './base64-util.js';
import StringUtil from './string-util.js';
import USB from './usb.js';
import Queue from './Queue.js';
import log from './log.js';
import TaskQueue from './task-queue.js';
// const Base64Util = require("./base64-util");
// const StringUtil = require("./string-util");
// const USB = require("./usb");
// const Queue = require("./Queue");
// const log = require("./log");
// const TaskQueue = require("./task-queue");
const loadingDiv = document.createElement("div");
loadingDiv.innerText = "Loading...";
loadingDiv.style.display = "none";
loadingDiv.style.position = "fixed";
loadingDiv.style.top = 0;
loadingDiv.style.left = 0;
loadingDiv.style.width = "100%";
loadingDiv.style.height = "100%";
loadingDiv.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
loadingDiv.style.color = "rgb(239, 147, 62)";
loadingDiv.style.fontSize = "1em";
loadingDiv.style.textAlign = "center";
loadingDiv.style.paddingTop = "50vh";
loadingDiv.style.zIndex = '999'
document.body.appendChild(loadingDiv);

const BLECommand = {
    CMD_CHECK_VERSION: 0x01,
    CMD_MOVE_POS: 0x10,
    CMD_MOVE_SPEED: 0x11,
    CMD_DANCE: 0x12,
    CMD_ACTION: 0x13,
    CMD_PLAY_TONE: 0x15,
    CMD_PLAY_MUSIC: 0x16,
    CMD_EYE_LED: 0x17,
    CMD_SET_NEW_PROTOCOL: 0x7e,
    CMD_BUTTON_EVENT: 0x72,
    CMD_IR_EVENT: 0x73,
    CMD_VARIABLE: 0x74,
    CMD_CREATE_FILE: 0x75,
    CMD_TRANSPORT_FILE: 0x76,
    CMD_IR_MESSAGE: 0x77,
    CMD_AUDIO_UPLOAD: 0x78,
    CMD_AUDIO_FINISH: 0x79,
    CMD_STOP_ALL: 0x84,
    CMD_HEARTBEAT: 0x87,
    CMD_GENERAL_RSP: 0x88,
    CMD_CUSTOM_SEQUENCE: 0x70,
    CMD_MQTT_EVENT: 0x7a
};

const VinciButton = {
    SQUARE: 1,
    TRIANGLE: 2,
    CIRCULAR: 4,
    NONE: 0
};

/**
 * A time interval to wait (in milliseconds) before reporting to the BLE socket
 * that data has stopped coming from the peripheral.
 */
const BLETimeout = 4500;
/**
 *  custom
 */
let cmdSequence = 0x00;

/**
 * A time interval to wait (in milliseconds) while a block that sends a BLE message is running.
 * @type {number}
 */
// const BLESendInterval = 1000;

/**
 * A string to report to the BLE socket when the matatacar has stopped receiving data.
 * @type {string}
 */
const BLEDataStoppedError = "vincibot extension stopped receiving data";

const BLE_INFO = {
    service: "6e400001-b5a3-f393-e0a9-e50e24dcca9e",
    rxChar: "6e400003-b5a3-f393-e0a9-e50e24dcca9e",
    txChar: "6e400002-b5a3-f393-e0a9-e50e24dcca9e",
    name: "VinciBot",
    namePrefix: "VinciBot"
};

/**
 * Manage communication with a MatataCar peripheral over a Scrath Link client socket.
 */
class VinciBot {
    /**
     * Construct a MatataCar communication object.
     * @param {Runtime} runtime - the Scratch 3.0 runtime
     * @param {string} extensionId - the id of the extension
     */
    constructor(runtime, extensionId) {

        /**
         * The Scratch 3.0 runtime used to trigger the green flag button.
         * @type {Runtime}
         * @private
         */
        this._runtime = runtime;

        /**
         * The BluetoothLowEnergy connection socket for reading/writing peripheral data.
         * @type {BLE|APP|USB}
         * @private
         */
        this._ble = null;
        // this._runtime.registerPeripheralExtension(extensionId, this);
        // this._runtime.on('MATA_CLICK_STOP', this.stopAll.bind(this));
        // this._runtime.on('MATA_CLICK_START', this.startClick.bind(this));
        // this._runtime.on('MATA_RUN_STOP', this.whenRunFinish.bind(this));
        /**
         * The id of the extension this peripheral belongs to.
         */
        this._extensionId = extensionId;
        this._peripheralId = null;

        /**
         * Interval ID for data reading timeout.
         * @type {number}
         * @private
         */
        this._timeoutID = null;

        /**
         * A flag that is true while we are busy sending data to the BLE socket.
         * @type {boolean}
         * @private
         */
        this._busy = false;

        /**
         * ID for a timeout which is used to clear the busy flag if it has been
         * true for a long time.
         */
        this._busyTimeoutID = null;

        /**
         * The polling interval, in milliseconds.
         * @type {number}
         * @private
         */
        this._pollingInterval = 150;

        /**
         * The polling interval ID.
         * @type {number}
         * @private
         */
        this._sensorsIntervalID = null;

        /**
         * The counter keeping track of polling cycles.
         * @type {string[]}
         * @private
         */
        this._pollingCounter = 0;

        /**
         * when click start button
         * @type {boolean}
         * @private
         */
        this._startRobot = false;

        this._isAcquisition = false;
        this._tofArray = [];
        this._isAudioRecord = false;
        this._audioArray = [];
        this._isIllumination = false;
        this._illuminationArray = [];
        this._leftIlluminationArray = [];
        this._rightIlluminationArray = [];

        /**
         * The most recently received value for each sensor.
         * @type {Object.<string, Object>}
         * @private
         */
        this._sensors = {
            tempoValue: 0,
            irMessage: "",
            ircValue: "",
            triangle: 0,
            button: VinciButton.NONE,
            leftAmbientLight: 0,
            rightAmbientLight: 0,
            reflectedLight: [0, 0, 0, 0],
            color: [0, 0, 0],
            soundIntensity: 0,
            distance: 0,
            volume: 0,
            ledMatrixState: new Uint8Array(5)
        };

        this._otaData = {
            wifiName: null,
            wifiPassword: null,
            host: null,
            url: null
        };

        /**
         * Processing for receiving data frames
         */
        this._receivedCommand = [];
        this._receivedCommandStart = false;
        this._receivedCommandLength = 0;
        this._lastFrameReservedData = null;
        this.device = "VinciBot";
        this.version = [0, 0, 0];
        this.versionString = "0.0.0";
        this.nvsReadValue = 90.0;
        this.extensionsFileExistsValue = false;
        this.mqttEventValue = null;

        this.decoder = new TextDecoder("utf-8");

        this._aiResult = {
            classifyResult: false,
            classify: [],
            classifyScore: 0,
            version: false
        };

        this.commandResults = {};

        this.commandSyncFlag = {
            setNewProtocolFlag: false,
            getVersionFlag: false
        };

        this.commandSequence = {
            movePosition: 0x00,
            moveAngle: 0x01,
            startMoving: 0x02,
            stopMoving: 0x03,
            movePositionSpeed: 0x04,
            moveAngleSpeed: 0x05,
            motorRun: 0x06,
            motorRunWithSpeed: 0x07,
            setMotorSpeed: 0x08,
            stopMotor: 0x09,
            doDance: 0x0a,
            doEmotion: 0x0b,
            doAction: 0x0c,
            displayShowImageWithTime: 0x0f,
            displayShowImage: 0x10,
            displayWriteText: 0x11,
            displayTurnOff: 0x12,
            displaySetBrightness: 0x13,
            displaySetPixel: 0x14,
            displaySetOrientation: 0x15,
            setRgbLedPanel: 0x16,
            displayScreenRotate: 0x17,
            rgbLedAllSet: 0x18,
            rgbLedSingleSet: 0x19,
            rgbLedAllSet1: 0x1a,
            rgbLedSingleSet1: 0x1b,
            rgbLedAllOff: 0x1c,
            doEffect: 0x1e,
            doEffectUntilDone: 0x1f,
            doSing: 0x20,
            singUntilDone: 0x21,
            say: 0x22,
            sayUntilDone: 0x23,
            audioPlay: 0x24,
            audioPlayUntilDone: 0x25,
            stopAllSounds: 0x26,
            setVolume: 0x27,
            changeVolume: 0x28,
            playDrumForBeats: 0x2b,
            restForBeats: 0x2c,
            playNoteForBeats: 0x2d,
            setInstrument: 0x2e,
            setTempo: 0x2f,
            changeTempo: 0x30,
            sendIRMessage: 0x34,
            createFile: 0x50,
            writeFile: 0x51,
            setWifiName: 0x80,
            setWifiPwd: 0x81,
            setCloudHost: 0x82,
            setCloudUrl: 0x83,
            upgrade: 0x84,
            otaStart: 0x85,
            otaImport: 0x86,
            otaEnable: 0x87,
            otaUpgrade: 0x88,
            colorCalibration: 0x89,
            nvsCalibration: 0x90,
            nvsRead: 0x91,
            legoMotorRun: 0x93,
            extensionsFileExists: 0x94,
            dcMotorRun: 0x96,
            servoRun: 0x98,
            loadModel: 0x99,
            classify: 0x9a,
            classifyResult: 0x9b,
            classifyScore: 0x9c,
            checkAiVersion: 0x9d,
            recordAudio: 0x9e,
            stopAudio: 0x9f,
            connectWifi: 0xa0,
            weatherSun: 0xa1,
            startOfDataCollection: 0xf1,
            connectRetry: 0xf2,
            networkDisconnect: 0xf3,
            addMqttByName: 0xf4,
            sendMqttMsgByName: 0xf5,
            mqttMsgValue: 0xf6,
            weatherNow: 0xf7,
            sendMqttMsgValueByName: 0xf8,
            isWiFiConnected: 0xf9,
            airNow: 0xfa,
            autoColor: 0xfb,
            wlsReadValue: 0xfc,
            wifiTest: 0xfd,
            common: 0xff
        };

        this.commandExecFlag = {
            movePosition: false,
            moveAngle: false,
            startMoving: false,
            stopMoving: false,
            movePositionSpeed: false,
            moveAngleSpeed: false,
            motorRun: false,
            motorRunWithSpeed: false,
            setMotorSpeed: false,
            stopMotor: false,
            doDance: false,
            doEmotion: false,
            doAction: false,
            displayShowImageWithTime: false,
            displayShowImage: false,
            displayWriteText: false,
            displayTurnOff: false,
            displaySetBrightness: false,
            displaySetPixel: false,
            displaySetOrientation: false,
            setRgbLedPanel: false,
            displayScreenRotate: false,
            rgbLedAllSet: false,
            rgbLedSingleSet: false,
            rgbLedAllSet1: false,
            rgbLedSingleSet1: false,
            rgbLedAllOff: false,
            doEffect: false,
            doEffectUntilDone: false,
            doSing: false,
            singUntilDone: false,
            say: false,
            sayUntilDone: false,
            audioPlay: false,
            audioPlayUntilDone: false,
            stopAllSounds: false,
            setVolume: false,
            changeVolume: false,
            playDrumForBeats: false,
            restForBeats: false,
            playNoteForBeats: false,
            setInstrument: false,
            setTempo: false,
            changeTempo: false,
            sendIRMessage: false,
            createFile: false,
            writeFile: false,
            setWifiName: false,
            setWifiPwd: false,
            setCloudHost: false,
            setCloudUrl: false,
            upgrade: false,
            otaStart: false,
            otaImport: false,
            otaEnable: false,
            colorCalibration: false,
            nvsCalibration: false,
            nvsRead: false,
            legoMotorRun: false,
            extensionsFileExists: false,
            dcMotorRun: false,
            servoRun: false,
            loadModel: false,
            classify: false,
            classifyResult: false,
            classifyScore: false,
            checkAiVersion: false,
            recordAudio: false,
            stopAudio: false,
            connectWifi: false,
            startOfDataCollection: false,
            common: false
        };

        this.irRemoteMessage = {
            volume: 1,
            mode: 2,
            speed: 3,
            forward: 4,
            left: 5,
            play: 6,
            right: 7,
            backward: 8,
            dance: 9,
            led: 10,
            music: 11,
            1: 12,
            2: 13,
            3: 14
        };

        this.reset = this.reset.bind(this);
        this._onConnect = this._onConnect.bind(this);
        this._onMessage = this._onMessage.bind(this);
    }

    /**
     * Stop the motors on the Boost peripheral.
     */
    stopAll() {
        this._startRobot = false;
        if (!this.isConnected()) return;
        // this.reset();
        this._sensors = {
            tempoValue: 0,
            irMessage: "",
            ircValue: "",
            triangle: 0,
            button: VinciButton.NONE,
            leftAmbientLight: 0,
            rightAmbientLight: 0,
            reflectedLight: [0, 0, 0, 0],
            color: [0, 0, 0],
            soundIntensity: 0,
            distance: 0,
            volume: 0,
            ledMatrixState: new Uint8Array(5)
        };
        const cmd = [BLECommand.CMD_STOP_ALL];
        this.send(this.packCommand(cmd));
    }

    startClick() {
        this._startRobot = true;
    }

    whenRunFinish() {
        // console.log('执行完成了');
        this._startRobot = false;
    }

    /**
     * Called by the runtime when user wants to scan for a peripheral.
     */
    // scan () {
    //     if (this._ble) {
    //         this._ble.disconnect();
    //     }
    //     if (isWebview()) {
    //         this._ble = new APP(this._runtime, this._extensionId, {
    //             filters: [
    //                 {services: [BLE_INFO.service]},
    //                 {namePrefix: BLE_INFO.namePrefix}
    //             ]
    //             // optionalServices: [BLE_INFO.service]
    //         }, this._onConnect, this.reset);
    //     } else {
    //         this._ble = new BLE(this._runtime, this._extensionId, {
    //             filters: [
    //                 {services: [BLE_INFO.service]},
    //                 {namePrefix: BLE_INFO.namePrefix}
    //             ]
    //             // optionalServices: [BLE_INFO.service]
    //         }, this._onConnect, this.reset);
    //     }
    // }

    usb(peripheralId) {
        console.log(peripheralId);
        console.log(this._extensionId);
        this._ble = new USB(this._extensionId, {
            filters: [
                { services: [BLE_INFO.service] },
                { namePrefix: BLE_INFO.namePrefix }
            ]
        }, this._onConnect, this.reset);
        this._peripheralId = peripheralId;
    }

    /**
     * Called by the runtime when user wants to connect to a certain peripheral.
     * @param {number} id - the id of the peripheral to connect to.
     */
    connect(id) {
        this._peripheralId = id;
        if (this._ble) {
            this._ble.connectPeripheral(id);
        }
    }

    /**
     * Disconnect from the matatacar.
     */
    disconnect() {
        if (this._ble) {
            this._ble.disconnect();
        }
        this.reset();
    }

    /**
     * Reset all the state and timeout/interval ids.
     */
    reset() {
        if (this._timeoutID) {
            window.clearTimeout(this._timeoutID);
            this._timeoutID = null;
        }
        this._sensors = {
            tempoValue: 0,
            irMessage: "",
            ircValue: "",
            triangle: 0,
            button: VinciButton.NONE,
            leftAmbientLight: 0,
            rightAmbientLight: 0,
            reflectedLight: [0, 0, 0, 0],
            color: [0, 0, 0],
            soundIntensity: 0,
            distance: 0.0,
            volume: 0,
            ledMatrixState: new Uint8Array(5)
        };
    }

    /**
     * Return true if connected to the matatacar.
     * @return {boolean} - whether the matatacar is connected.
     */
    isConnected() {
        let connected = false;
        if (this._ble) {
            connected = this._ble.isConnected();
        }
        return connected;
    }

    /**
     * Send a message to the peripheral BLE socket.
     * @param {object} message - the message to write
     */
    sendEntity(message) {
        if (!this.isConnected()) return;
        Queue.enqueue(() => this.sendEntityPromise(message))
            .then(r => r);
    }

    sendEntityPromise = message => new Promise(r => {
        const output = new Uint8Array(message.length);
        for (let i = 0; i < message.length; i++) {
            output[i] = message[i];
        }
        // eslint-disable-next-line no-invalid-this
        this.writeEntityPromise(output, r);
    });

    sleep = (delay = 500) => {
        let t = Date.now();
        while (Date.now() - t <= delay) {
            continue;
        }
    };

    /**
     * Send a message to the peripheral BLE socket.
     * @param {Uint8Array} message - the message to write
     */
    writeEntity(message) {
        if (message.length > 20) {
            let s = message.slice(0, 20);
            const data = Base64Util.uint8ArrayToBase64(s);
            this._ble.write(BLE_INFO.service, BLE_INFO.txChar, data, "base64", false)
                .then(
                    () => {
                        // console.log(`BLE message sent: ${s}`);
                        // this.sleep(100);
                        this.writeEntity(message.slice(20, message.length));
                    }
                );
        } else {
            const data = Base64Util.uint8ArrayToBase64(message);
            this._ble.write(BLE_INFO.service, BLE_INFO.txChar, data, "base64", false)
                .then(
                    () => {
                        this._busy = false;
                        window.clearTimeout(this._busyTimeoutID);
                        // console.log(`BLE message sent: ${message}`);
                    }
                );
        }
    }

    writeEntityPromise(message, r) {
        if (message.length > 20) {
            let s = message.slice(0, 20);
            const data = Base64Util.uint8ArrayToBase64(s);
            this._ble.write(BLE_INFO.service, BLE_INFO.txChar, data, "base64", false)
                .then(
                    () => {
                        this.writeEntity(message.slice(20, message.length));
                    }
                );
        } else {
            const data = Base64Util.uint8ArrayToBase64(message);
            this._ble.write(BLE_INFO.service, BLE_INFO.txChar, data, "base64", false)
                .then(
                    () => r(message)
                );
        }
    }

    /**
     * Send a message to the peripheral BLE socket.
     * @param {any[]} message - the message to write
     */
    send(message) {
        if (!this.isConnected()) return;
        if (this._busy) return;
        // Set a busy flag so that while we are sending a message and waiting for
        // the response, additional messages are ignored.
        this._busy = true;

        // Set a timeout after which to reset the busy flag. This is used in case
        // a BLE message was sent for which we never received a response, because
        // e.g. the peripheral was turned off after the message was sent. We reset
        // the busy flag after a while so that it is possible to try again later.
        this._busyTimeoutID = window.setTimeout(() => {
            this._busy = false;
        }, 3000);
        const output = new Uint8Array(message.length);
        for (let i = 0; i < message.length; i++) {
            output[i] = message[i];
        }
        // console.log('BLE message send: ', output);
        const data = Base64Util.uint8ArrayToBase64(output);
        // console.log(`BLE message send: ${data}`);
        this._ble.write(BLE_INFO.service, BLE_INFO.txChar, data, "base64", false)
            .then(
                () => {
                    this._busy = false;
                    window.clearTimeout(this._busyTimeoutID);
                }
            );
    }

    crc16(buffer, crc_init) {
        let crc = crc_init & 0xffff;
        for (let i = 0; i < buffer.length; i++) {
            crc = ((crc >> 8) | (crc << 8)) & 0xffff;
            crc ^= buffer[i] & 0xffff;
            crc ^= ((crc & 0xff) >> 4) & 0xffff;
            crc ^= ((crc << 8) << 4) & 0xffff;
            crc ^= (((crc & 0xff) << 4) << 1) & 0xffff;
        }
        return crc;
    }

    crc16P(buffer, crc_init) {
        console.log(buffer.toString(), crc_init);
        let crc = crc_init & 0xffff;
        for (let i = 0; i < buffer.length; i++) {
            crc = ((crc >> 8) | (crc << 8)) & 0xffff;
            crc ^= buffer[i] & 0xffff;
            crc ^= ((crc & 0xff) >> 4) & 0xffff;
            crc ^= ((crc << 8) << 4) & 0xffff;
            crc ^= (((crc & 0xff) << 4) << 1) & 0xffff;
        }
        return crc;
    }

    packCommand(command_data) {
        // console.log(command_data);
        let command_array = [];
        let message_len = command_data.length + 2;
        const message_len_array = [];
        message_len_array.push(message_len);
        // console.log(message_len_array);
        let crc = this.crc16(message_len_array, 0xffff);
        crc = this.crc16(command_data, crc);
        // console.log(crc);
        command_array.push(0xfe);
        command_array.push(message_len);
        for (let i = 0; i < command_data.length; i++) {
            if (command_data[i] === 0xfe) {
                command_array.push(0xfd);
                command_array.push(0xde);
            } else if (command_data[i] === 0xfd) {
                command_array.push(0xfd);
                command_array.push(0xdd);
            } else {
                command_array.push(command_data[i]);
            }
        }
        if ((crc >> 8) === 0xfe) {
            command_array.push(0xfd);
            command_array.push(0xde);
        } else if ((crc >> 8) === 0xfd) {
            command_array.push(0xfd);
            command_array.push(0xdd);
        } else {
            command_array.push(crc >> 8);
        }
        if ((crc & 0xff) === 0xfe) {
            command_array.push(0xfd);
            command_array.push(0xde);
        } else if ((crc & 0xff) === 0xfd) {
            command_array.push(0xfd);
            command_array.push(0xdd);
        } else {
            command_array.push(crc & 0xff);
        }
        // let log_string = 'vincibot send: ';
        // for (let i = 0; i < command_array.length; i++) {
        //     log_string = `${log_string}0x${command_array[i].toString(16)},`;
        // }
        // console.log(log_string);
        // console.log(command_array);
        return command_array;
    }

    packNewCommand(command_data, sequence) {
        command_data.unshift(0x70, 0x00, sequence);
        // console.log(command_data);
        let command_array = [];
        let message_len = command_data.length + 2;
        const message_len_array = [];
        message_len_array.push(message_len);
        let crc = this.crc16(message_len_array, 0xffff);
        crc = this.crc16(command_data, crc);
        command_array.push(0xfe);
        command_array.push(message_len);
        for (let i = 0; i < command_data.length; i++) {
            if (command_data[i] === 0xfe) {
                command_array.push(0xfd);
                command_array.push(0xde);
            } else if (command_data[i] === 0xfd) {
                command_array.push(0xfd);
                command_array.push(0xdd);
            } else {
                command_array.push(command_data[i]);
            }
        }
        if ((crc >> 8) === 0xfe) {
            command_array.push(0xfd);
            command_array.push(0xde);
        } else if ((crc >> 8) === 0xfd) {
            command_array.push(0xfd);
            command_array.push(0xdd);
        } else {
            command_array.push(crc >> 8);
        }
        if ((crc & 0xff) === 0xfe) {
            command_array.push(0xfd);
            command_array.push(0xde);
        } else if ((crc & 0xff) === 0xfd) {
            command_array.push(0xfd);
            command_array.push(0xdd);
        } else {
            command_array.push(crc & 0xff);
        }
        // let log_string = 'vincibot send: ';
        // for (let i = 0; i < command_array.length; i++) {
        //     log_string = `${log_string}0x${command_array[i].toString(16)},`;
        // }
        // console.log(log_string);
        return command_array;
    }

    depackCommand(command_data) {
        // console.log(`接收: ${StringUtil.toHexString(command_data)}`);
        let command_data_temp = [];
        if (this._lastFrameReservedData !== null) {
            command_data_temp.push(this._lastFrameReservedData);
            this._lastFrameReservedData = null;
        }
        // for (let i = 0; i < command_data.length; i++) {
        //     command_data_temp.push(command_data[i]);
        // }
        // console.log(`继续接收${command_data}`);
        command_data_temp.push(...command_data);
        // console.log(`接收: ${StringUtil.toHexString(command_data_temp)}`);
        for (let i = 0; i < command_data_temp.length; i++) {
            // console.log(`接收: ${StringUtil.toHexString(command_data_temp)}`);
            if ((command_data_temp[i] === 0xfe) && (this._receivedCommandStart === false)) {
                this._receivedCommand.push(0xfe);
                this._receivedCommandStart = true;
                // console.log(`开始`);
            } else if (this._receivedCommandStart === true) {
                if (command_data_temp[i] === 0xfd) {
                    if ((i + 1) < command_data_temp.length) {
                        if (command_data_temp[i + 1] === 0xdd) {
                            this._receivedCommand.push(0xfd);
                            if (this._receivedCommandLength !== 0 && (this._receivedCommand.length >= this._receivedCommandLength + 2)) {
                                // console.log(`receive frame 1: ${StringUtil.toHexString(this._receivedCommand)}`);
                                this.parseCommand();
                                break;
                            } else {
                                i++;
                                // console.log(`0xdd ++`);
                                continue;
                            }
                        } else if (command_data_temp[i + 1] === 0xde) {
                            this._receivedCommand.push(0xfe);
                            // console.log(`add 0xfe`);
                            if (this._receivedCommandLength !== 0 && (this._receivedCommand.length >= this._receivedCommandLength + 2)) {
                                // console.log(`receive frame 2: ${StringUtil.toHexString(this._receivedCommand)}`);
                                this.parseCommand();
                                break;
                            } else {
                                i++;
                                // console.log(`0xde ++`);
                                continue;
                            }
                        } else {
                            this._receivedCommand.push(0xfd);
                            // console.log(`0xfd`);
                        }
                    } else {
                        this._lastFrameReservedData = 0xfd;
                        // console.log(`last frame 0xfd`);
                        continue;
                    }
                } else if (command_data_temp[i] === 0xfe) {
                    this.parseCommand();
                    break;
                } else {
                    this._receivedCommand.push(command_data_temp[i]);
                    // console.log(command_data_temp[i].toString(16));
                }
                if (this._receivedCommand.length > 3) {
                    this._receivedCommandLength = this._receivedCommand[1] & 0xff;
                    if (this._receivedCommand.length >= 512) {
                        console.log(`length error ${this._receivedCommand}`);
                        this._receivedCommandLength = 0;
                        this._receivedCommandStart = false;
                        this._receivedCommand = [];
                    }
                    if (this._receivedCommand.length >= this._receivedCommandLength + 2) {
                        // console.log(`receive frame: ${StringUtil.toHexString(this._receivedCommand)}`);
                        this.parseCommand();
                    }
                }
            }
        }
    }

    checkCRC() {
        let crc_data_temp = this._receivedCommand.slice(1, this._receivedCommandLength);
        let crc_calculation = this.crc16(crc_data_temp, 0xffff);
        // eslint-disable-next-line max-len
        let crc_received = (this._receivedCommand[this._receivedCommandLength] << 8) & 0xff00 | ((this._receivedCommand[this._receivedCommandLength + 1]) & 0xff);
        return crc_calculation === crc_received;
    }

    hasChineseChar(array) {
        const pattern = /[\u4E00-\u9FA5\uF900-\uFA2D]/;
        for (let str of array) {
            if (pattern.test(str)) {
                return true;
            }
        }
        return false;
    }

    /**
     * clear flag
     * @param commandDatum
     */
    clearCommandExecFlag(command_data) {
        let commandDatum = command_data[3];
        Object.keys(this.commandSequence)
            // eslint-disable-next-line array-callback-return
            .find(key => {
                if (this.commandSequence[key] === commandDatum) {
                    this.commandResults[key] = this.decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
                    // this.commandResults[key] = String.fromCharCode.apply(null, command_data.slice(5, command_data.length));
                    this.commandExecFlag[key] = false;
                }
            });
        if (commandDatum === this.commandSequence.otaImport) {
            const command = `wifi.set_wifi_name("${this._otaData.wifiName}")`;
            let str2hex = StringUtil.str2hex(command);
            this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.setWifiName));
            this.commandExecFlag.setWifiName = true;
        } else if (commandDatum === this.commandSequence.setWifiName) {
            const command2 = `wifi.set_wifi_password("${this._otaData.wifiPassword}")`;
            let str2hex2 = StringUtil.str2hex(command2);
            this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.setWifiPwd));
            this.commandExecFlag.setWifiPwd = true;
            // console.log(`WiFi密码设置完成${command2}`);
        } else if (commandDatum === this.commandSequence.setWifiPwd) {
            const command2 = `wifi.set_cloud_host("${this._otaData.host}")`;
            let str2hex2 = StringUtil.str2hex(command2);
            this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.setCloudHost));
            this.commandExecFlag.setCloudHost = true;
            // console.log(`WiFi Host设置完成${command2}`);
        } else if (commandDatum === this.commandSequence.setCloudHost) {
            const command2 = `wifi.set_cloud_url("${this._otaData.url}")`;
            let str2hex2 = StringUtil.str2hex(command2);
            this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.setCloudUrl));
            this.commandExecFlag.setCloudUrl = true;
            // console.log(`WiFi URL设置完成${command2}`);
        } else if (commandDatum === this.commandSequence.setCloudUrl) {
            const command2 = `wifi.set_ota_ble("disable")`;
            let str2hex2 = StringUtil.str2hex(command2);
            this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.otaEnable));
            this.commandExecFlag.otaEnable = true;
            // console.log(`启用蓝牙状态${command2}`);
        } else if (commandDatum === this.commandSequence.otaEnable) {
            let version = localStorage.getItem("vincibotVersion");
            if (StringUtil.compareVersions(version, "1.0.40") < 1) {
                const command2 = `ota.ota_start()`;
                let str2hex2 = StringUtil.str2hex(command2);
                this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.otaStart));
                this.commandExecFlag.otaStart = true;
                this._runtime.emit("OTA_ERROR", 28);
            } else {
                const command2 = `wifi.wifi_test()`;
                let str2hex2 = StringUtil.str2hex(command2);
                this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.wifiTest));
                this.commandExecFlag.wifiTest = true;
            }
        } else if (commandDatum === this.commandSequence.wifiTest) {
            let statusResult = this.decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
            // console.log(statusResult);
            this._runtime.emit("OTA_ERROR", statusResult);
            if (statusResult == 28) {
                const command2 = `ota.ota_start()`;
                let str2hex2 = StringUtil.str2hex(command2);
                this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.otaStart));
                this.commandExecFlag.otaStart = true;
            }
            // console.log(`${command2}`);
            // setTimeout(() => {
            //     this._runtime.disconnectPeripheral(this._extensionId);
            //     // console.log('断开蓝牙');
            // }, 1000);
        } else if (commandDatum === this.commandSequence.otaStart) {
            console.log("等待重启");
            this.commandExecFlag.upgrade = false;
            // this._runtime.disconnectPeripheral(this._extensionId);
        }
    }

    irMessageParser(data) {
        Object.keys(this.irRemoteMessage)
            // eslint-disable-next-line array-callback-return
            .find(key => {
                if (this.irRemoteMessage[key] === data) {
                    // console.log(key);
                    this._sensors.ircValue = key;
                }
            });
    }

    otaUpgrade(command_data) {
        if (command_data[3] === 0) {
            let state = String.fromCharCode.apply(null, command_data.slice(5, command_data.length));
            // console.log(state);
            if (state.indexOf("ota_state:7") > -1) {
                const command2 = `upgrade_start()`;
                let str2hex2 = StringUtil.str2hex(command2);
                this.sendEntity(this.packNewCommand(str2hex2, this.commandSequence.otaUpgrade));
                // console.log(`开始更新${command2}`);
            } else if (state.indexOf("process") > -1) {
                let number = parseInt(state.split(":")[1], 10);
                this._runtime.emit("OTA_PROCESS", number);
                if (number === 100) {
                    this.commandExecFlag.upgrade = false;
                    this._runtime.emit("OTA_ERROR", -98);
                }
            } else if (state.indexOf("ota_state:14") > -1) {
                this._runtime.emit("OTA_ERROR", 14);
                this.commandExecFlag.upgrade = false;
            } else if (state.indexOf("ota_state:18") > -1) {
                this._runtime.emit("OTA_ERROR", 18);
                this.commandExecFlag.upgrade = false;
            } else if (state.indexOf("ota_state:23") > -1) {
                this._runtime.emit("OTA_ERROR", 23);
                this.commandExecFlag.upgrade = false;
            } else if (state.indexOf("ota_state:24") > -1) {
                this._runtime.emit("OTA_ERROR", 24);
                this.commandExecFlag.upgrade = false;
            }
        }
    }

    clearCommandSyncFlag() {
        Object.keys(this.commandSequence)
            // eslint-disable-next-line array-callback-return
            .find(key => {
                this.commandExecFlag[key] = false;
            });
    }

    /* eslint eqeqeq: "off"*/
    parseCommand() {
        if (this.checkCRC() === false) {
            console.log(`checkCRC error! ${this._receivedCommand}`);
            this._receivedCommand = this._receivedCommand.slice(this._receivedCommandLength + 2);
            this._receivedCommandLength = 0;
            this._receivedCommandStart = false;
            return;
        }
        // console.log(this._receivedCommand);
        let command_data = this._receivedCommand.slice(1, this._receivedCommandLength);
        // console.log(command_data);
        // console.log(String.fromCharCode.apply(null, command_data.slice(5, command_data.length)));
        switch (command_data[1]) {
            case BLECommand.CMD_SET_NEW_PROTOCOL:
                {
                    loadingDiv.style.display = 'block'
                    console.log("switch protocol finish!");
                    this.commandSyncFlag.setNewProtocolFlag = false;
                    break;
                }
            case BLECommand.CMD_BUTTON_EVENT:
                {
                    // console.log(command_data[2]);
                    if (command_data[2] <= 4) {
                        this._sensors.buttons = command_data[2];
                    } else {
                        this._sensors.buttons = VinciButton.NONE;
                    }
                    break;
                }
            case BLECommand.CMD_IR_EVENT:
                {
                    // let log_string = 'receive: ';
                    // for (let i = 0; i < command_data.length; i++) {
                    //     log_string = `${log_string}0x${command_data[i].toString(16)},`;
                    // }
                    // console.log(log_string);
                    // console.log(command_data[2]);
                    if (command_data[2] === 0) {
                        // console.log('0');
                        this._sensors.ircValue = "";
                    }
                    this.irMessageParser(command_data[2]);
                    break;
                }
            case BLECommand.CMD_VARIABLE:
                {
                    this._sensors.leftAmbientLight = command_data[2];
                    this._sensors.rightAmbientLight = command_data[3];
                    this._sensors.reflectedLight = [command_data[4], command_data[5], command_data[6], command_data[7], command_data[8]];
                    this._sensors.soundIntensity = command_data[9];
                    this._sensors.color = [command_data[10], command_data[11], command_data[12]];
                    if (StringUtil.compareVersions(this.versionString, "1.0.21") === 1) {
                        this._sensors.distance = ((command_data[13] << 8 | command_data[14]) / 10);
                    } else {
                        this._sensors.distance = (command_data[13] << 8 | command_data[14]);
                    }
                    // console.log(this._sensors.distance);
                    // console.log(typeof this._sensors.distance);
                    this._sensors.volume = command_data[15];
                    this._sensors.tempoValue = command_data[16];
                    if (this._isAcquisition) {
                        this._tofArray.push(this._sensors.distance);
                    }
                    if (this._isIllumination) {
                        // this._illuminationArray.push([this._sensors.leftAmbientLight, this._sensors.rightAmbientLight]);
                        this._leftIlluminationArray.push(this._sensors.leftAmbientLight);
                        this._rightIlluminationArray.push(this._sensors.rightAmbientLight);
                    }
                    break;
                }
            case BLECommand.CMD_CREATE_FILE:
                // console.log(command_data[4]);
                this.commandExecFlag.createFile = false;
                break;
            case BLECommand.CMD_TRANSPORT_FILE:
                // let log_string = 'receive: ';
                // for (let i = 0; i < command_data.length; i++) {
                //     log_string = `${log_string}0x${command_data[i].toString(16)},`;
                // }
                // console.log(log_string);
                this.commandExecFlag.writeFile = false;
                break;
            case BLECommand.CMD_IR_MESSAGE:
                // console.log(command_data);
                // for (let i = 0; i < command_data[2]; i++) {
                //     this._sensors.irMessage += command_data[i + 3];
                // }
                this._sensors.irMessage = String.fromCharCode.apply(null, command_data.slice(3, command_data[2] + 3));
                break;
            case BLECommand.CMD_GENERAL_RSP:
                {
                    // console.log('0x88 response!');
                    this.clearCommandSyncFlag();
                    break;
                }
            case BLECommand.CMD_AUDIO_UPLOAD:
                {
                    // console.log(command_data);
                    this._isAudioRecord = true;
                    this._audioArray.push(...command_data.slice(2, command_data.length));
                    break;
                }
            case BLECommand.CMD_AUDIO_FINISH:
                {
                    console.log("audio finish!");
                    this._isAudioRecord = false;
                    break;
                }
            case BLECommand.CMD_CUSTOM_SEQUENCE:
                {
                    // let log_string = 'receive: ';
                    // for (let i = 0; i < command_data.length; i++) {
                    //     log_string = `${log_string}0x${command_data[i].toString(16)},`;
                    // }
                    // console.log(log_string);
                    // console.log(command_data[3]);
                    switch (command_data[3]) {
                        case this.commandSequence.nvsRead:
                            this.nvsReadValue = this.decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
                            this.commandExecFlag.nvsRead = false;
                            break;
                        case this.commandSequence.extensionsFileExists:
                            this.extensionsFileExistsValue = (String.fromCharCode(command_data[5]) == "T");
                            this.commandExecFlag.extensionsFileExists = false;
                            break;
                        case this.commandSequence.classifyResult:
                            this._aiResult.classifyResult = (String.fromCharCode(command_data[5]) == "T");
                            this.commandExecFlag.classifyResult = false;
                            break;
                        case this.commandSequence.classify:
                            this._aiResult.classify = this.decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
                            this.commandExecFlag.classify = false;
                            break;
                        case this.commandSequence.classifyScore:
                            this._aiResult.classifyScore = this.decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
                            this.commandExecFlag.classifyScore = false;
                            break;
                        case this.commandSequence.checkAiVersion:
                            this._aiResult.version = this.decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
                            this.commandExecFlag.checkAiVersion = false;
                            break;
                            // case this.commandSequence.weatherNow:
                            //     // eslint-disable-next-line no-case-declarations
                            //     const decoder = new TextDecoder('utf-8');
                            //     this.commandResults.weatherNow = decoder.decode(new Uint8Array(command_data.slice(5, command_data.length)));
                            //     this.commandExecFlag.weatherNow = false;
                            //     console.log(this.commandResults.weatherNow);
                            //     break;
                        default:
                            this.clearCommandExecFlag(command_data);
                            this.otaUpgrade(command_data);
                            break;
                    }
                    break;
                }
            case BLECommand.CMD_MQTT_EVENT:
                {
                    this.mqttEventValue = this.decoder.decode(new Uint8Array(command_data.slice(2, command_data.length)));
                    // console.log(this.mqttEventValue);
                    break;
                }
            case BLECommand.CMD_CHECK_VERSION:
                {
                    this.version[0] = command_data[3];
                    this.version[1] = command_data[4];
                    this.version[2] = command_data[5];
                    this.versionString = `${command_data[3]}.${command_data[4]}.${command_data[5]}`;
                    this.commandSyncFlag.getVersionFlag = false;
                    console.log(`version:${this.versionString}`);
                    if (this.versionString) {
                        sessionStorage.setItem('version', this.versionString);
                        let strPython = JSON.parse(localStorage.getItem("pythonStr"))
                        this.downloadCode(strPython)
                    }
                    break;
                }
            case BLECommand.CMD_HEARTBEAT:
                {
                    if (command_data[2] === 0x02) {
                        this.device = "MatataCon";
                    } else if (command_data[2] === 0x01) {
                        this.device = "MatataBot";
                    } else {
                        this.device = "VinciBot";
                    }
                    break;
                }
            default:
                {
                    break;
                }
        }

        this._receivedCommand = this._receivedCommand.slice(this._receivedCommandLength + 2);
        this._receivedCommandLength = 0;
        this._receivedCommandStart = false;
    }

    isChineseChar(char) {
        const asciiCode = char.charCodeAt(0);
        return (asciiCode < 32 || asciiCode > 126);
    }

    Uint8ArrayToString(fileData) {
        let dataString = "";
        for (let i = 0; i < fileData.length; i++) {
            dataString += String.fromCharCode(fileData[i]);
        }

        return dataString;
    }

    setNewProtocol(callback) {
        const setNewProtocolData = [];
        setNewProtocolData.push(BLECommand.CMD_SET_NEW_PROTOCOL);
        setNewProtocolData.push(0x02);
        setNewProtocolData.push(0x02);
        setNewProtocolData.push(0x00);
        setNewProtocolData.push(0x00);
        this.commandSyncFlag.setNewProtocolFlag = true;
        let tryAgain = false;
        this.send(this.packCommand(setNewProtocolData));
        return new Promise(resolve => {
            let count = 0;
            let interval = setInterval(() => {
                if (count > 4000) {
                    if (!tryAgain) {
                        this.send(this.packCommand(setNewProtocolData));
                        tryAgain = true;
                        count = 0;
                        return;
                    }
                    console.log("setNewProtocol timeout!");
                    clearInterval(interval);
                    this.commandSyncFlag.setNewProtocolFlag = false;
                    // matata.showFirmwareModal(this.device, 'unknown');
                    this.disconnect();
                    // eslint-disable-next-line no-unused-expressions
                    callback && callback(false);
                    resolve(false);
                } else if (this.commandSyncFlag.setNewProtocolFlag === false) {
                    console.log("setNewProtocol success!");
                    clearInterval(interval);
                    // eslint-disable-next-line no-unused-expressions
                    callback && callback(true);
                    resolve(true);
                }
                count += 100;
            }, 100);
        });
    }

    checkVersion() {
        const cmd = [BLECommand.CMD_CHECK_VERSION, 0x01];
        this.send(this.packCommand(cmd));
        this.commandSyncFlag.getVersionFlag = true;
        let tryAgain = false;
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 3000) {
                    if (!tryAgain) {
                        this.send(this.packCommand(cmd));
                        tryAgain = true;
                        count = 0;
                        return;
                    }
                    console.log("checkVersion timeout!");
                    this.commandSyncFlag.getVersionFlag = false;
                    // matata.showFirmwareModal(this.device, 'unknown');
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandSyncFlag.getVersionFlag === false) {
                    const version = `${this.version[0]}.${this.version[1]}.${this.version[2]}`;
                    localStorage.setItem("vincibotVersion", version);
                    // this._runtime.emit("CONNECT_READY");
                    // console.log(version);
                    // const version_array = MATATACAR_LATEST_FIRMWARE_VERSION.split('.');
                    // // eslint-disable-next-line max-len
                    // const latest_version = (parseInt(version_array[0], 10) << 16) + (parseInt(version_array[1], 10) << 8) + parseInt(version_array[2], 10);
                    // const current_version = (this.version[0] << 16) + (this.version[1] << 8) + this.version[2];
                    // console.log(latest_version);
                    // console.log(current_version);
                    // if (latest_version > current_version) {
                    //     matata.showFirmwareModal(this.device, version);
                    //     this.disconnect();
                    // }
                    clearInterval(interval);
                    resolve(true);
                }
                count += 10;
            }, 10);
        });
    }

    cancelUpgrade() {
        this.commandExecFlag.upgrade = false;
    }

    upgrade(ssid, password, host, url) {
        this._otaData.wifiName = ssid;
        if (StringUtil.isDoubleByte(ssid)) {
            // eslint-disable-next-line no-control-regex
            this._otaData.wifiName = ssid.replace(/[^\x00-\x7f]/g, $ => StringUtil.encodeUnicode($));
        }
        this._otaData.wifiPassword = password;
        this._otaData.host = host;
        this._otaData.url = url;
        const command = `import ota,wifi`;
        let str2hex = StringUtil.str2hex(command);
        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.otaImport));
        this.commandExecFlag.upgrade = true;
        // this.commandExecFlag.setWifiName = true;
        this.commandExecFlag.otaImport = true;
        this._runtime.emit("OTA_ERROR", -99);
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 3000 * 60) {
                    console.log("upgrade timeout!");
                    // this._runtime.emit('OTA_ERROR', -98);
                    this.commandExecFlag.upgrade = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.upgrade === false) {
                    clearInterval(interval);
                    resolve(true);
                } else if (!this._ble.isConnected()) {
                    // this.connect(this._peripheralId);
                    // console.log('没有连接上');
                }
                count += 100;
            }, 100);
        });
    }

    colorCalibration() {
        const command = `sensor.color_calibration()`;
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.colorCalibration = true;
        // console.log(str2hex);
        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.colorCalibration));
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 1000) {
                    console.log("colorCalibration timeout!");
                    this.commandExecFlag.colorCalibration = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.colorCalibration === false) {
                    clearInterval(interval);
                    resolve(true);
                }
                count += 100;
            }, 100);
        });
    }

    /**
     * 启动自动开关传感器灯
     * @param {number}status 1启用，0关闭
     * @returns {Promise<unknown>} 返回true或false
     */
    sensorAutoColor(status) {
        const command = `sensor.set_auto_color(${status})`;
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.autoColor = true;
        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.autoColor));
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 1000) {
                    console.log("autoColor timeout!");
                    this.commandExecFlag.autoColor = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.autoColor === false) {
                    clearInterval(interval);
                    resolve(true);
                }
                count += 100;
            }, 100);
        });
    }

    dataIllumination(flag, callBack) {
        this._isIllumination = flag;
        if (flag) {
            this._illuminationArray = [];
            this._leftIlluminationArray = [];
            this._rightIlluminationArray = [];
            const command = `timer.get_timer()`;
            let str2hex = StringUtil.str2hex(command);
            this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.startOfDataCollection));
        } else {

            // let max = Math.max(...this._leftIlluminationArray);
            // let min = Math.min(...this._leftIlluminationArray);
            // // let timestamp = 0;
            // // let result = this._tofArray.map(i => [timestamp++, (i - min) / (max - min)]);
            // let result = this._leftIlluminationArray.map(i => {
            //     if (max === min) {
            //         return 0;
            //     }
            //     return (i - min) / (max - min);
            // });
            //
            // let maxRight = Math.max(...this._rightIlluminationArray);
            // let minRight = Math.min(...this._rightIlluminationArray);
            //
            // let resultRight = this._rightIlluminationArray.map(i => {
            //     if (maxRight === minRight) {
            //         return 0;
            //     }
            //     return (i - minRight) / (maxRight - minRight);
            // });

            // const normalizedData = result.map((item, index) => [item, resultRight[index]]);
            const normalizedData = this._leftIlluminationArray.map((item, index) => [item, this._rightIlluminationArray[index]]);

            // eslint-disable-next-line no-unused-expressions
            callBack && callBack(normalizedData);
        }
    }

    dataAcquisition(flag, callBack, data) {
        this._isAcquisition = flag;
        if (flag) {
            this._tofArray = [];
            const command = `timer.get_timer()`;
            let str2hex = StringUtil.str2hex(command);
            this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.startOfDataCollection));
        } else {
            // let max = Math.max(...this._tofArray);
            // let min = Math.min(...this._tofArray);
            // // let timestamp = 0;
            // // let result = this._tofArray.map(i => [timestamp++, (i - min) / (max - min)]);
            // let result = this._tofArray.map(i => {
            //     if (max === min) {
            //         return 0;
            //     }
            //     return (i - min) / (max - min);
            // });
            let result = this._tofArray.map(i => {
                if (i > data) {
                    return data;
                }
                return i;
            });
            // console.log(result);
            // eslint-disable-next-line no-unused-expressions
            callBack && callBack(result);
        }
    }

    nvsCalibration() {

        let commandCount = 0;
        let commandArray = [];

        const moveCommand1 = `motion.move_position('forward', 10, 'cm')`;
        let str2hex1 = StringUtil.str2hex(moveCommand1);
        commandArray.push(str2hex1);

        const moveCommand2 = `motion.move_angle('left', 90, 'degrees')`;
        let str2hex2 = StringUtil.str2hex(moveCommand2);
        commandArray.push(str2hex2);

        const moveCommand3 = `motion.move_position('forward', 10, 'cm')`;
        let str2hex3 = StringUtil.str2hex(moveCommand3);
        commandArray.push(str2hex3);

        const moveCommand4 = `motion.move_angle('left', 90, 'degrees')`;
        let str2hex4 = StringUtil.str2hex(moveCommand4);
        commandArray.push(str2hex4);

        const moveCommand5 = `motion.move_position('forward', 10, 'cm')`;
        let str2hex5 = StringUtil.str2hex(moveCommand5);
        commandArray.push(str2hex5);

        const moveCommand6 = `motion.move_angle('left', 90, 'degrees')`;
        let str2hex6 = StringUtil.str2hex(moveCommand6);
        commandArray.push(str2hex6);

        const moveCommand7 = `motion.move_position('forward', 10, 'cm')`;
        let str2hex7 = StringUtil.str2hex(moveCommand7);
        commandArray.push(str2hex7);

        const moveCommand8 = `motion.move_angle('left', 90, 'degrees')`;
        let str2hex8 = StringUtil.str2hex(moveCommand8);
        commandArray.push(str2hex8);

        this.commandExecFlag.common = true;
        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));

        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 10000) {
                    console.log("colorCalibration timeout!");
                    this.commandExecFlag.common = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.common === false) {
                    if (commandCount === 7) {
                        clearInterval(interval);
                        resolve(true);
                    } else {
                        this.commandExecFlag.common = true;
                        commandCount++;
                        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));
                    }
                }
                count += 100;
            }, 100);
        });
    }

    unClosCalibration() {
        let commandCount = 0;
        let commandArray = [];
        const command = `current_value = float(nvs.read("save_angle"))`;
        let str2hex = StringUtil.str2hex(command);
        commandArray.push(str2hex);
        const command2 = `current_value = current_value + 0.1`;
        let str2hex2 = StringUtil.str2hex(command2);
        commandArray.push(str2hex2);
        const command3 = `nvs.write("save_angle",str(current_value))`;
        let str2hex3 = StringUtil.str2hex(command3);
        commandArray.push(str2hex3);
        const command4 = `nvs.init_calibration_value()`;
        let str2hex4 = StringUtil.str2hex(command4);
        commandArray.push(str2hex4);

        this.commandExecFlag.common = true;
        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));

        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 10000) {
                    console.log("colorCalibration timeout!");
                    this.commandExecFlag.common = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.common === false) {
                    if (commandCount === 3) {
                        clearInterval(interval);
                        resolve(true);
                    } else {
                        this.commandExecFlag.common = true;
                        commandCount++;
                        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));
                    }
                }
                count += 100;
            }, 100);
        });
    }

    crossCalibration() {
        let commandCount = 0;
        let commandArray = [];
        const command = `current_value = float(nvs.read("save_angle"))`;
        let str2hex = StringUtil.str2hex(command);
        commandArray.push(str2hex);
        const command2 = `current_value = current_value - 0.1`;
        let str2hex2 = StringUtil.str2hex(command2);
        commandArray.push(str2hex2);
        const command3 = `nvs.write("save_angle",str(current_value))`;
        let str2hex3 = StringUtil.str2hex(command3);
        commandArray.push(str2hex3);
        const command4 = `nvs.init_calibration_value()`;
        let str2hex4 = StringUtil.str2hex(command4);
        commandArray.push(str2hex4);

        this.commandExecFlag.common = true;
        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));

        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 10000) {
                    console.log("colorCalibration timeout!");
                    this.commandExecFlag.common = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.common === false) {
                    if (commandCount === 3) {
                        clearInterval(interval);
                        resolve(true);
                    } else {
                        this.commandExecFlag.common = true;
                        commandCount++;
                        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));
                    }
                }
                count += 100;
            }, 100);
        });
    }

    nvsRead() {
        const command = `nvs.read("save_angle")`;
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.nvsRead = true;

        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.nvsRead));
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 5000) {
                    console.log("nvsRead timeout!");
                    this.commandExecFlag.nvsRead = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(null);
                } else if (this.commandExecFlag.nvsRead === false) {
                    clearInterval(interval);
                    resolve(this.nvsReadValue);
                }
                count += 100;
            }, 100);
        });
    }

    resetCalibration(currentValue = 90) {
        // console.log(currentValue);
        let commandCount = 0;
        let commandArray = [];
        const command = `current_value = ${currentValue}`;
        let str2hex = StringUtil.str2hex(command);
        commandArray.push(str2hex);
        const command2 = `nvs.write("save_angle",str(current_value))`;
        let str2hex2 = StringUtil.str2hex(command2);
        commandArray.push(str2hex2);
        const command3 = `nvs.init_calibration_value()`;
        let str2hex3 = StringUtil.str2hex(command3);
        commandArray.push(str2hex3);

        this.commandExecFlag.common = true;
        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));

        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 10000) {
                    console.log("colorCalibration timeout!");
                    this.commandExecFlag.common = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.common === false) {
                    if (commandCount === 2) {
                        clearInterval(interval);
                        resolve(true);
                    } else {
                        this.commandExecFlag.common = true;
                        commandCount++;
                        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));
                    }
                }
                count += 100;
            }, 100);
        });
    }

    checkFileExists(fileName) {
        const command = `if_file_exists("/extention/${fileName}")`;
        // console.log(command);
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.extensionsFileExists = true;

        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.extensionsFileExists));
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 5000) {
                    console.log("check extention timeout!");
                    this.commandExecFlag.extensionsFileExists = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(null);
                } else if (this.commandExecFlag.extensionsFileExists === false) {
                    clearInterval(interval);
                    resolve(this.extensionsFileExistsValue);
                }
                count += 100;
            }, 100);
        });
    }

    uploadPy(code, file) {
        this._runtime.stopAll();
        window.peripheral.send(window.peripheral.packCommand([0x84]));
        const that = this;
        setTimeout(() => {
            that.uploadPy2(code, file);
        }, 1000);
    }

    uploadPy2(code, file) {
        // eslint-disable-next-line no-control-regex
        code = code.replace(/[^\x00-\x7f]/g, $ => StringUtil.encodeUnicode($));
        const length = (code.length).toString(16);
        const data = StringUtil.hexToArray(length);
        // console.log(data);
        const fileName = `/extention/${file}`;
        const str2hex = StringUtil.str2hex(fileName);
        const cmd = [0x75, 0x70, this.commandSequence.createFile].concat(...data)
            .concat(...str2hex);
        // console.log(cmd);
        this.commandExecFlag.createFile = true;
        this.sendEntity(this.packCommand(cmd));
        new Promise(resolve => {
            const interval = setInterval(() => {
                if (this.commandExecFlag.createFile === false) {
                    clearInterval(interval);
                    resolve(true);
                }
            }, 10);
        }).then(r => {
            this.commandExecFlag.writeFile = true;
            const n = 240;
            const cmdArr = [];
            for (let i = 0, l = code.length; i < l / n; i++) {
                if (this.commandExecFlag.writeFile) {
                    // eslint-disable-next-line no-shadow
                    const writeData = [0x76, 0x70, this.commandSequence.writeFile];
                    const myData = code.slice(n * i, n * (i + 1));
                    // console.log(myData);
                    writeData.push(...StringUtil.getCmdSequence(i));
                    writeData.push(parseInt((myData.length).toString(16), 16));
                    const str2hex1 = StringUtil.str2hex(myData);
                    // console.log(str2hex1);
                    writeData.push(...str2hex1);
                    // console.log(writeData);
                    cmdArr.push(writeData);
                }
            }
            this.sendEntity(this.packCommand(cmdArr.shift()));
            new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (this.commandExecFlag.writeFile === false) {
                            const commandData = cmdArr.shift();
                            // console.log(commandData);
                            if (commandData == undefined) {
                                clearInterval(interval);
                                resolve(file);
                                return;
                            }
                            this.commandExecFlag.writeFile = true;
                            this.sendEntity(this.packCommand(commandData));
                        }
                    }, 100);
                }).then(r => {
                    console.log("执行完成");
                    this._runtime.emit("SCRIPT_UPLOAD_FINISHED", { name: file, data: true });
                })
                .catch(error => {
                    this._runtime.emit("SCRIPT_UPLOAD_FINISHED", { name: file, data: false });
                });
        });
    }

    recordAudio(time) {
        const command = `audio.start_record_and_upload(${time})`;
        // console.log(command);
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.recordAudio = true;
        this._audioArray = [];

        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.recordAudio));
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 10000) {
                    console.log("start_record_and_upload timeout!");
                    this.commandExecFlag.recordAudio = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(null);
                } else if (this.commandExecFlag.recordAudio === false) {
                    clearInterval(interval);
                    resolve();
                }
                count += 100;
            }, 100);
        });
    }

    stopRecordAudio() {
        const command = `audio.stop_record_and_upload()`;
        // console.log(command);
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.stopAudio = true;
        this._isAudioRecord = true;

        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.stopAudio));
        return new Promise((resolve, reject) => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 140000) {
                    console.log("stop_record_and_upload timeout!");
                    this.commandExecFlag.stopAudio = false;
                    // this.disconnect();
                    clearInterval(interval);
                    reject(null);
                } else if (this.commandExecFlag.stopAudio === false && this._isAudioRecord === false) {
                    // console.log(`原始数据：${this._audioArray}`);
                    let value;
                    let audio_buffer = [];
                    let i = 0;
                    let len_buff = this._audioArray.length / 2;
                    for (; i < len_buff; i += 1) {
                        value = this._audioArray[i * 2 + 1] * 256 + this._audioArray[i * 2];
                        audio_buffer.push(StringUtil.getSign16(value));
                    }
                    // let audio_buffer = this._audioArray.map((value, i) => StringUtil.getSign16(this._audioArray[i * 2 + 1] * 256 + this._audioArray[i * 2]));
                    // this._audioArray = audio_buffer;
                    clearInterval(interval);
                    resolve(audio_buffer);
                }
                count += 100;
            }, 100);
        });
    }

    // TODO 待优化
    writeFile(code, fileName) {
        // console.log(code);
        // eslint-disable-next-line no-control-regex
        code = code.replace(/[^\x00-\x7f]/g, $ => StringUtil.encodeUnicode($));
        const length = (code.length).toString(16);
        const data = StringUtil.hexToArray(length);
        // console.log(data);
        const file_name = `/extention/tf_model/${fileName}.json`;
        const str2hex = StringUtil.str2hex(file_name);
        const cmd = [0x75, 0x70, this.commandSequence.createFile].concat(...data)
            .concat(...str2hex);
        // console.log(cmd);
        this.commandExecFlag.createFile = true;
        this.sendEntity(this.packCommand(cmd));
        new Promise(resolve => {
            const interval = setInterval(() => {
                if (this.commandExecFlag.createFile === false) {
                    clearInterval(interval);
                    resolve(true);
                }
            }, 10);
        }).then(r => {
            this.commandExecFlag.writeFile = true;
            const n = 128;
            const cmdArr = [];
            for (let i = 0, l = code.length; i < l / n; i++) {
                // eslint-disable-next-line no-shadow
                const writeData = [0x76, 0x70, this.commandSequence.writeFile];
                const myData = code.slice(n * i, n * (i + 1));
                // console.log(myData);
                writeData.push(...StringUtil.getCmdSequence(i));
                writeData.push(myData.length);
                const str2hex1 = StringUtil.str2hex(myData);
                // console.log(str2hex1);
                writeData.push(...str2hex1);
                // console.log(writeData);
                cmdArr.push(writeData);
                // console.log(cmdArr);
            }
            this.sendEntity(this.packCommand(cmdArr.shift()));
            new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (this.commandExecFlag.writeFile === false) {
                            const commandData = cmdArr.shift();
                            // console.log(commandData);
                            if (commandData == undefined) {
                                clearInterval(interval);
                                resolve(file_name);
                                return;
                            }
                            this.commandExecFlag.writeFile = true;
                            this.sendEntity(this.packCommand(commandData));
                        }
                    }, 10);
                }).then(r => {
                    console.log(`${r}写入完成`);
                    this._runtime.emit("SCRIPT_UPLOAD_FINISHED", { name: fileName, data: true });
                    // this.sleep(3000);
                    // this.writeTfLite(tflite, fileName);
                })
                .catch(error => {
                    console.log(error);
                    this._runtime.emit("SCRIPT_UPLOAD_FINISHED", { name: fileName, data: false });
                });
        });
    }

    writeTfLite(jsonData, code, fileName) {
        this._runtime.stopAll();
        window.peripheral.send(window.peripheral.packCommand([0x84]));
        const that = this;
        setTimeout(() => {
            that.writeTfLite2(jsonData, code, fileName);
        }, 1000);
    }

    writeTfLite2(jsonData, code, fileName) {
        let newFileName = fileName;
        if (StringUtil.isDoubleByte(fileName)) {
            // eslint-disable-next-line no-control-regex
            newFileName = newFileName.replace(/[^\x00-\x7f]/g, $ => StringUtil.encodeUnicode($));
        }
        const length = (code.byteLength).toString(16);
        const data = StringUtil.hexToArray(length);
        // console.log(data);
        const file_name = `/extention/tf_model/${newFileName}.tflite`;
        const str2hex = StringUtil.str2hex(file_name);
        const cmd = [0x75, 0x70, this.commandSequence.createFile].concat(...data)
            .concat(...str2hex);
        // console.log(cmd);
        this.commandExecFlag.createFile = true;
        this.sendEntity(this.packCommand(cmd));
        new Promise(resolve => {
            const interval = setInterval(() => {
                if (this.commandExecFlag.createFile === false) {
                    clearInterval(interval);
                    resolve(true);
                }
            }, 10);
        }).then(r => {
            this.commandExecFlag.writeFile = true;
            const n = 128;
            const cmdArr = [];
            for (let i = 0, l = code.byteLength; i < l / n; i++) {
                // eslint-disable-next-line no-shadow
                const writeData = [0x76, 0x70, this.commandSequence.writeFile];
                const myData = code.slice(n * i, n * (i + 1));
                // console.log(myData);
                writeData.push(...StringUtil.getCmdSequence(i));
                writeData.push(parseInt((myData.byteLength).toString(16), 16));
                // const str2hex1 = StringUtil.str2hex(myData);
                // console.log(str2hex1);
                writeData.push(...myData);
                // console.log(writeData);
                cmdArr.push(writeData);
                // console.log(cmdArr);
            }
            this.sendEntity(this.packCommand(cmdArr.shift()));
            new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (this.commandExecFlag.writeFile === false) {
                            const commandData = cmdArr.shift();
                            // console.log(commandData);
                            if (commandData == undefined) {
                                clearInterval(interval);
                                resolve(file_name);
                                return;
                            }
                            this.commandExecFlag.writeFile = true;
                            this.sendEntity(this.packCommand(commandData));
                        }
                    }, 10);
                }).then(r => {
                    console.log(`${r}写入完成`);
                    this.writeFile(jsonData, newFileName);
                    // this._runtime.emit('SCRIPT_UPLOAD_FINISHED', {name: fileName, data: true});
                })
                .catch(error => {
                    console.log(error);
                    this._runtime.emit("SCRIPT_UPLOAD_FINISHED", { name: fileName, data: false });
                });
        });
    }

    checkJsonVersion(fileName) {
        let newFileName = fileName;
        if (StringUtil.isDoubleByte(fileName)) {
            // eslint-disable-next-line no-control-regex
            newFileName = newFileName.replace(/[^\x00-\x7f]/g, $ => StringUtil.encodeUnicode($));
        }
        const command = `tf.get_created_time("/extention/tf_model/${newFileName}.tflite")`;
        // console.log(command);
        let str2hex = StringUtil.str2hex(command);
        this.commandExecFlag.checkAiVersion = true;

        this.sendEntity(this.packNewCommand(str2hex, this.commandSequence.checkAiVersion));
        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 5000) {
                    console.log("checkJsonVersion timeout!");
                    this.commandExecFlag.checkAiVersion = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(null);
                } else if (this.commandExecFlag.checkAiVersion === false) {
                    clearInterval(interval);
                    resolve(this._aiResult.version);
                }
                count += 100;
            }, 100);
        });
    }

    resetProgram() {
        // console.log(currentValue);
        let commandCount = 0;
        let commandArray = [];
        const command = `uos.remove('main.py')`;
        let str2hex = StringUtil.str2hex(command);
        commandArray.push(str2hex);
        const command2 = `time.sleep(1)`;
        let str2hex2 = StringUtil.str2hex(command2);
        commandArray.push(str2hex2);
        const command3 = `machine.reset()`;
        let str2hex3 = StringUtil.str2hex(command3);
        commandArray.push(str2hex3);

        this.commandExecFlag.common = true;
        this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));

        return new Promise(resolve => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > 10000) {
                    console.log("resetProgram timeout!");
                    this.commandExecFlag.common = false;
                    this.disconnect();
                    clearInterval(interval);
                    resolve(false);
                } else if (this.commandExecFlag.common === false) {
                    this.commandExecFlag.common = true;
                    commandCount++;
                    this.sendEntity(this.packNewCommand(commandArray[commandCount], this.commandSequence.common));
                    if (commandCount === 2) {
                        clearInterval(interval);
                        resolve(true);
                    }
                }
                count += 100;
            }, 100);
        });
    }
    downloadCode(code) {
        loadingDiv.style.display = 'block'
        console.log(code, 'code')
        this.send(this.packCommand([0x84]));
        const that = this;
        setTimeout(() => {
            that.downloadCode2(code);
        }, 1000);
    }

    downloadCode2(code) {
        this.commandSequence = {
            createFile: 0x50,
            writeFile: 0x51
        };
        this.commandExecFlag = {
            createFile: false,
            writeFile: false
        };
        if (sessionStorage.getItem('version')) {
            // if (this.isConnected()) {
            // eslint-disable-next-line no-control-regex
            code = code.replace(/[^\x00-\x7f]/g, $ => StringUtil.encodeUnicode($));
            const length = (code.length).toString(16);
            const data = StringUtil.hexToArray(length);
            // console.log(this.commandSequence, data);
            const fileName = '/main.py';
            const str2hex = StringUtil.str2hex(fileName);
            const cmd = [0x75, 0x70, this.commandSequence.createFile].concat(...data)
                .concat(...str2hex);
            // console.log(cmd);
            this.commandExecFlag.createFile = true;
            this.sendEntity(this.packCommand(cmd));
            new Promise((resolve, reject) => {
                let count = 0;
                const interval = setInterval(() => {
                    if (count > 5000) {
                        loadingDiv.style.display = 'none'
                        console.log('下载超时');
                        this.commandExecFlag.createFile = false;
                        this.disconnect();
                        clearInterval(interval);
                        // this.runtime.emit('SCRIPT_UPLOAD_FINISHED', 0);
                        reject();
                    } else if (this.commandExecFlag.createFile === false) {
                        clearInterval(interval);
                        resolve();
                    }
                    count += 10;
                }, 10);
            }).then(r => {
                this.commandExecFlag.writeFile = true;
                const n = 200;
                const cmdArr = [];
                for (let i = 0, l = code.length; i < l / n; i++) {
                    if (this.commandExecFlag.writeFile) {
                        // eslint-disable-next-line no-shadow
                        const writeData = [0x76, 0x70, this.commandSequence.writeFile, 0x00];
                        const myData = code.slice(n * i, n * (i + 1));
                        // console.log(myData);
                        writeData.push(parseInt(i.toString(16), 16));
                        writeData.push(parseInt((myData.length).toString(16), 16));
                        const str2hex1 = StringUtil.str2hex(myData);
                        // console.log(str2hex1);
                        writeData.push(...str2hex1);
                        // console.log(writeData);
                        cmdArr.push(writeData);
                    }
                }
                this.sendEntity(this.packCommand(cmdArr.shift()));
                new Promise((resolve, reject) => {
                        let count = 0;
                        const interval = setInterval(() => {
                            if (count > 5000) {
                                loadingDiv.style.display = 'none'
                                console.log('下载超时');
                                this.commandExecFlag.writeFile = false;
                                this.disconnect();
                                clearInterval(interval);
                                // this.runtime.emit('SCRIPT_UPLOAD_FINISHED', 0);
                                reject();
                            } else if (this.commandExecFlag.writeFile === false) {
                                const commandData = cmdArr.shift();
                                // console.log(commandData);
                                if (commandData == undefined) {
                                    clearInterval(interval);
                                    resolve();
                                    return;
                                }
                                count = 0;
                                this.commandExecFlag.writeFile = true;
                                this.sendEntity(this.packCommand(commandData));
                            }
                            count += 100;
                        }, 100);
                    }).then(r => {
                        loadingDiv.style.display = 'none'
                        console.log('写入完成');
                        // this.runtime.emit('SCRIPT_UPLOAD_FINISHED', 1);
                    })
                    .catch(error => {
                        loadingDiv.style.display = 'none'
                        console.log('下载异常');
                        // this.runtime.emit('SCRIPT_UPLOAD_FINISHED', 0);
                    });
            }).catch(error => {
                loadingDiv.style.display = 'none'
                console.log(error)
            });
        } else {
            const blob = new Blob([code], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'main.py';
            a.click();
            URL.revokeObjectURL(url);
        }
    }

    /**
     * Starts reading data from peripheral after BLE has connected to it.
     * @private
     */
    _onConnect() {
        this._ble.startNotifications(BLE_INFO.service, BLE_INFO.rxChar, this._onMessage);
        this.setNewProtocol(state => {
            if (state) {
                this.checkVersion();
            }
        });
    }

    /**
     * Process the sensor data from the incoming BLE characteristic.
     * @param {object} base64 - the incoming BLE data.
     * @private
     */
    _onMessage(base64) {
        // parse data
        const dataReceived = Base64Util.base64ToUint8Array(base64);
        // console.log(`VinciBot recv:${dataReceived}`);
        this.depackCommand(dataReceived);
    }

    /**
     * @return {boolean} - the latest value received for the A button.
     */
    get TRIANGLE() {
        // console.log(this._sensors.buttons);
        return this._sensors.buttons === 2;
    }

    /**
     * @return {boolean} - the latest value received for the A button.
     */
    get SQUARE() {
        return this._sensors.buttons === 1;
    }

    /**
     * @return {boolean} - the latest value received for the A button.
     */
    get CIRCULAR() {
        return this._sensors.buttons === 4;
    }

    /**
     * the latest value received for the tempo button.
     * @returns {object}
     * @constructor
     */
    get TEMPO_VALUE() {
        return this._sensors.tempoValue;
    }

    isButtonPressed(port) {
        return this._sensors.buttons[port] === 1;
    }

    isIRMessage(port) {
        return this._sensors.irMessage === port;
    }

    irPressed(port) {
        return this._sensors.ircValue === port;
    }
}
// module.exports = VinciBot;
export default VinciBot;