const atob = require('atob');
const btoa = require('btoa');
const { fromByteArray } = require("base64-js");

class Base64Util {

    static get BLE_EXTENSION_ID() {
        return 'VinciBot';
    }

    /**
     * Convert a base64 encoded string to a Uint8Array.
     * @param {object} base64 - a base64 encoded string.
     * @return {Uint8Array} - a decoded Uint8Array.
     */
    static base64ToUint8Array(base64) {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const array = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            array[i] = binaryString.charCodeAt(i);
        }
        return array;
    }

    /**
     * Convert a Uint8Array to a base64 encoded string.
     * @param {Uint8Array} array - the array to convert.
     * @return {string} - the base64 encoded string.
     */
    static uint8ArrayToBase64(array) {
        // let str = String.fromCharCode.apply(null, array);
        // const base64 = btoa(str);
        // console.log(base64)
        const base64 = fromByteArray(array);
        return base64;
    }

    // static uint8ArrayToBase64 (array) {
    //     let str = String.fromCharCode.apply(null, array);
    //     const base64 = Buffer.from(str, 'binary').toString('base64'); // 使用 Buffer 类来编码
    //     console.log(base64)
    //     return base64;
    // }

    /**
     * Convert an array buffer to a base64 encoded string.
     * @param {array} buffer - an array buffer to convert.
     * @return {string} - the base64 encoded string.
     */
    static arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }

}

// module.exports = Base64Util;
export default Base64Util;