const log = require('./log');

class StringUtil {
    static withoutTrailingDigits(s) {
        let i = s.length - 1;
        while ((i >= 0) && ('0123456789'.indexOf(s.charAt(i)) > -1)) i--;
        return s.slice(0, i + 1);
    }

    static unusedName(name, existingNames) {
        if (existingNames.indexOf(name) < 0) return name;
        name = StringUtil.withoutTrailingDigits(name);
        let i = 2;
        while (existingNames.indexOf(name + i) >= 0) i++;
        return name + i;
    }

    static str2hex(str) {
        // console.log(str);
        const hex = [];
        for (let i = 0; i < str.length; i++) {
            hex.push(parseInt(str.charCodeAt(i)
                .toString(16), 16));
            // const string = str.charCodeAt(i)
            //     .toString(16);
            // hex.push(parseInt(string, 16));
            // if (string.indexOf('fd') > 0) {
            //     hex.push(parseInt('dd', 16));
            // }
            // if (string.indexOf('fe') > 0) {
            //     hex.push(parseInt('de', 16));
            // }
        }
        return hex;
    }

    static encodeUnicode(str) {
        const res = [];
        for (let i = 0; i < str.length; i++) {
            res[i] = (`00${str.charCodeAt(i)
                .toString(16)}`).slice(-4);
        }
        return `\\u${res.join('\\u')}`;
    }

    /*
     * 数组转为十六进制字符串
     * @param data {[number]}
     */
    static toHexString(data) {
        let hexString = '';
        for (const b of data) {
            hexString += `0x${b.toString(16)},`;
        }
        return hexString;
    }

    /**
     * Split a string on the first occurrence of a split character.
     * @param {string} text - the string to split.
     * @param {string} separator - split the text on this character.
     * @returns {string[]} - the two parts of the split string, or [text, null] if no split character found.
     * @example
     * // returns ['foo', 'tar.gz']
     * splitFirst('foo.tar.gz', '.');
     * @example
     * // returns ['foo', null]
     * splitFirst('foo', '.');
     * @example
     * // returns ['foo', '']
     * splitFirst('foo.', '.');
     */
    static splitFirst(text, separator) {
        const index = text.indexOf(separator);
        if (index >= 0) {
            return [text.substring(0, index), text.substring(index + 1)];
        }
        return [text, null];
    }

    /**
     * A customized version of JSON.stringify that sets Infinity/NaN to 0,
     * instead of the default (null).
     * Needed because null is not of type number, but Infinity/NaN are, which
     * can lead to serialization producing JSON that isn't valid based on the parser schema.
     * It is also consistent with the behavior of saving 2.0 projects.
     * This is only needed when stringifying an object for saving.
     *
     * @param {!object} obj - The object to serialize
     * @return {!string} The JSON.stringified string with Infinity/NaN replaced with 0
     */
    static stringify(obj) {
        return JSON.stringify(obj, (_key, value) => {
            if (typeof value === 'number' &&
                (value === Infinity || value === -Infinity || isNaN(value))) {
                return 0;
            }
            return value;
        });
    }

    /**
     * A function to replace unsafe characters (not allowed in XML) with safe ones. This is used
     * in cases where we're replacing non-user facing strings (e.g. variable IDs).
     * When replacing user facing strings, the xmlEscape utility function should be used
     * instead so that the user facing string does not change how it displays.
     * @param {!string | !Array.<string>} unsafe Unsafe string possibly containing unicode control characters.
     * In some cases this argument may be an array (e.g. hacked inputs from 2.0)
     * @return {string} String with control characters replaced.
     */
    static replaceUnsafeChars(unsafe) {
        if (typeof unsafe !== 'string') {
            if (Array.isArray(unsafe)) {
                // This happens when we have hacked blocks from 2.0
                // See #1030
                unsafe = String(unsafe);
            } else {
                log.error('Unexpected input recieved in replaceUnsafeChars');
                return unsafe;
            }
        }
        return unsafe.replace(/[<>&'"]/g, c => {
            switch (c) {
                case '<':
                    return 'lt';
                case '>':
                    return 'gt';
                case '&':
                    return 'amp';
                case '\'':
                    return 'apos';
                case '"':
                    return 'quot';
            }
        });
    }

    static hexToArray = hex => {
        // eslint-disable-next-line no-undefined,eqeqeq
        if (hex === undefined) {
            return new Array(8).fill(0)
                .join('');
        }
        const binary = [];
        if (hex.length < 8) {
            hex = String(hex)
                .padStart(8, '0');
        }
        for (let i = 0; i < hex.length; i += 2) {
            const code = hex.substring(i, i + 2);
            // const binaryCode = `0x${code}`;
            binary.push(parseInt(code, 16));
        }
        return binary;
    };

    // eslint-disable-next-line valid-jsdoc
    /**
     * 将16进制字符串转为十进制数字
     * 如  HexTools.hex2Number('0x11') //17
     *     HexTools.hex2Number('21') //33
     *     HexTools.hex2Number('0xffff') //65535
     *     HexTools.hex2Number('ffff') //65535
     * @param str 可传入16进制的8位或16位字符串
     * @returns {number}
     */
    static hex2Number(str = '') {
        if (str.indexOf('0x') === 0) {
            str = str.slice(2);
        }
        return parseInt(`0x${str}`, 16);
    }

    // eslint-disable-next-line valid-jsdoc
    /**
     * 将16进制字符串转为指定字节的字符串
     * @param {string} 十六进制字符串
     * @param {byteLen} 字节大小
     * @return {string}
     */
    static hex2ByteString(str = '', byteLen = 2) {
        if (str.indexOf('0x') === 0) {
            str = str.slice(2);
        }
        const len = str.length;
        let total = (byteLen * 2) - len;

        if (total > 0) {
            while (total) {
                str = `0${str}`;
                total--;
            }
        }
        return str;
    }

    /**
     * 十进制数字转为指定字节的16进制字符串
     * @param num
     * @param {byteLen} 字节大小
     * @returns {string} 得到n字节的16进制字符串
     */
    static num2HexBytes(num = 0, byteLen = 1) {
        const str = num.toString(16);
        return StringUtil.hex2ByteString(str, byteLen);
    }

    /**
     * 十进制数字(这里最大为255)转为8位16进制字符串
     * @param num
     * @returns {string} 得到8位的16进制字符串
     */
    static num2Hex(num = 0) {
        return (`00${num.toString(16)}`).slice(-2);
    }

    /**
     * num转为hex数组
     * 与{hexArrayToNum}含义相反
     * @param num
     * @returns {*} 一个字节代表8位
     */
    static num2HexArray(num) {
        if (num === void 0) {
            return [];
        }
        num = parseInt(num);
        if (num === 0) {
            return [0];
        }
        let str = num.toString(16);
        // eslint-disable-next-line no-unused-expressions
        str.length % 2 && (str = `0${str}`);
        const array = [];
        for (let i = 0, len = str.length; i < len; i += 2) {
            array.push(`0x${str.substr(i, 2)}`);
        }
        return array;
    }

    /**
     * number 转 小端序 ArrayBuffer (默认两个字节)
     * @param {object} dataview
     * @param {string} num        数字
     * @param {number} offset    偏移量
     * @param {number} bytes    几个字节
     * @returns {object} dataview
     */
    static num2ArrayBuffer(dataview, num, offset, bytes = 2) {
        switch (bytes) {
            case 1:
                dataview.setUint8(offset, num);
                break;
            case 2:
                dataview.setUint16(offset, num, true);
                break;
            case 4:
                dataview.setUint32(offset, num, true);
                break;
            default:
        }
        return dataview;
    }

    // 10进制转16进制补0
    // 默认4位
    static dec2hex(dec, len = 4) {
        let hex = '';
        while (dec) {
            const last = dec & 15;
            hex = String.fromCharCode(((last > 9) ? 55 : 48) + last) + hex;
            dec >>= 4;
        }
        if (len) {
            while (hex.length < len) hex = `0${hex}`;
        }
        const numBytes = hex.length / 2;
        const byteArray = new Uint8Array(numBytes);
        for (let i = 0; i < numBytes; i++) {
            byteArray[i] = parseInt(hex.substr(i * 2, 2), 16);
        }
        return byteArray;
    }

    static getCmdSequence(x) {
        const bytes = [];
        let i = 2;
        do {
            bytes[--i] = x & (255);
            x = x >> 8;
        } while (i);
        return bytes;
    }

    static getSign16(vx) {
        if (!vx || vx < 0x8000) {
            return vx;
        }
        return vx - 0x10000;
    }

    /*
     * 数组转为16进制
     * @param decimalArray {[number]}
     * @returns {*}
     */
    static decimalArrayToHexString(decimalArray) {
        return decimalArray.map(num => num.toString(16));
    }

    static isDoubleByte(str) {
        // eslint-disable-next-line no-control-regex
        return /[^\x00-\x7f]/.test(str);
    }

    function;

    /*
     * 比较版本号
     * @param version1
     * @param version2
     * @returns {number}
     */
    static compareVersions(v1, v2) {
        // eslint-disable-next-line no-eq-null,eqeqeq
        if (v1 == null || v2 == null) {
            return -1;
        }
        v1 = v1.split('.')
            .map(Number);
        v2 = v2.split('.')
            .map(Number);
        const length = Math.max(v1.length, v2.length);
        for (let i = 0; i < length; i++) {
            // eslint-disable-next-line no-undefined
            if (v1[i] === undefined) v1[i] = 0;
            // eslint-disable-next-line no-undefined
            if (v2[i] === undefined) v2[i] = 0;
            if (v1[i] > v2[i]) return 1;
            if (v1[i] < v2[i]) return -1;
        }
        return 0;
    }

}

// module.exports = StringUtil;
export default StringUtil;