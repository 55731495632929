class FakeWebsocket {
    constructor() {
        this._onOpen = null;
        this._onClose = null;
        this._onError = null;
        this._handleMessage = null;
    }
}

// module.exports = FakeWebsocket;
export default FakeWebsocket;