/**
 * @author simon
 */

class Queue {
    static queue = [];
    static pendingPromise = false;
    static stop = false;
    static callDequeue = false;

    static enqueue(promise) {
        return new Promise((resolve, reject) => {
            this.queue.push({
                promise,
                resolve,
                reject
            });
            // console.log(`入栈`);
            if (!this.callDequeue) {
                // console.log('触发出栈');
                this.callDequeue = true;
                this.dequeue();
            }
        });
    }

    static dequeue() {
        if (this.workingOnPromise) {
            const interval = setInterval(() => {
                this.workingOnPromise = false;
                if (this.queue.length > 0) {
                    // console.log('继续循环');
                } else {
                    this.stop = true;
                    clearInterval(interval);
                    // console.log('执行完毕');
                }
                this.dequeue();
            }, 10);
            return false;
        }
        if (this.stop) {
            this.queue = [];
            this.stop = false;
            this.callDequeue = false;
            return;
        }
        const item = this.queue.shift();
        // console.log(`出栈${item.promise}`);
        if (!item) {
            return false;
        }
        try {
            this.workingOnPromise = true;
            item.promise()
                .then(value => {
                    this.workingOnPromise = false;
                    item.resolve(value);
                    this.dequeue();
                })
                .catch(err => {
                    this.workingOnPromise = false;
                    item.reject(err);
                    this.dequeue();
                });
        } catch (err) {
            this.workingOnPromise = false;
            item.reject(err);
            this.dequeue();
        } finally {
            // this.workingOnPromise = false;
            this.dequeue();
            // console.log('finally');
            // this.callDequeue = false;
        }
        return true;
    }
}

// module.exports = Queue;
export default Queue;