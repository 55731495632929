/**
 * (c) 2021, vincibot Educational Foundation and contributors
 *
 * SPDX-License-Identifier: MIT
 */
import {
  Box,
  Divider,
  List,
  ListItem,
  HStack,
  VStack,
  Text,
  Stack,
} from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import { Link, useMediaQuery } from "@chakra-ui/react";
import { ReactNode, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AreaHeading from "../../common/AreaHeading";
import HeadedScrollablePanel from "../../common/HeadedScrollablePanel";
import { Anchor, useRouterTabSlug, useRouterState } from "../../router-hooks";
import { useAnimationDirection } from "../common/documentation-animation-hooks";
import { RiArrowDownLine } from "react-icons/ri";
import { heightMd, widthXl } from "../../common/media-queries";
import { ExtensionModule } from "../mapping/model";

interface ApiDocumentationProps {
  extensions: ExtensionModule[];
}

export const ExtensionsDocumentation = ({
  extensions,
}: ApiDocumentationProps) => {
  const [anchor, setAnchor] = useRouterTabSlug("api");
  const handleNavigate = useCallback(
    (id: string | undefined) => {
      setAnchor(id ? { id } : undefined, "documentation-user");
    },
    [setAnchor]
  );
  const direction = useAnimationDirection(anchor);
  return (
    <ActiveLevel
      key={anchor ? 0 : 1}
      anchor={anchor}
      onNavigate={handleNavigate}
      extensions={extensions}
      direction={direction}
    />
  );
};

interface ActiveLevelProps {
  anchor: Anchor | undefined;
  extensions: ExtensionModule[];
  onNavigate: (state: string | undefined) => void;
  direction: "forward" | "back" | "none";
}

const ActiveLevel = ({
  anchor,
  onNavigate,
  extensions,
  direction,
}: ActiveLevelProps) => {
  const intl = useIntl();
  const [isShortWindow] = useMediaQuery(heightMd);
  const [isWideScreen] = useMediaQuery(widthXl);
  const extensionString = intl.formatMessage({ id: "extensions-tab" });
  // console.log('ActiveLevel docs', docs);
  //   const module = anchor ? resolveModule(docs, anchor.id) : undefined;
  // console.log('module', module);
  const [, setParams] = useRouterState();

  const handleReferenceLink = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setParams({ tab: "reference" });
    },
    [setParams]
  );

  return (
    <HeadedScrollablePanel
      direction={direction}
      heading={
        <AreaHeading
          name={extensionString}
          description={
            <FormattedMessage
              id="extensions-description"
              values={{
                link: (chunks: ReactNode) => (
                  <Link color="brand.500" onClick={handleReferenceLink} href="">
                    {chunks}
                  </Link>
                ),
              }}
            />
          }
        />
      }
    >
      <List flex="1 1 auto" m={3}>
        {extensions.map((module, index) => (
          <ListItem key={index}>
            <HStack
              my={3}
              mr={3}
              ml={5}
              spacing={isShortWindow || !isWideScreen ? 3 : 5}
            >
              <VStack
                alignItems="stretch"
                spacing={isShortWindow || !isWideScreen ? 0 : 1}
                flex="1 1 auto"
              >
                {/* 图片 */}
                <img src={module.src} alt="" />
                <HStack justifyContent="space-between">
                  {/* 标题和描述 */}
                  <Stack alignItems="start">
                    <Text as="h3" fontSize="lg" fontWeight="semibold">
                      {module.name}
                    </Text>
                    <Box fontSize="sm" noOfLines={1}>
                      {module.desc}
                    </Box>
                  </Stack>
                  {/* 下载按钮 */}
                  <IconButton
                    icon={<RiArrowDownLine />}
                    aria-label={module.name}
                    size="sm"
                    color="brand.800"
                    variant="ghost"
                    fontSize="2xl"
                  />
                </HStack>
              </VStack>
            </HStack>
            <Divider ml={3} borderWidth="1px" />
          </ListItem>
        ))}
      </List>
    </HeadedScrollablePanel>
  );
};
