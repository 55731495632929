/**
 * (c) 2021, vincibot Educational Foundation and contributors
 *
 * SPDX-License-Identifier: MIT
 */
import { ApiDocumentation } from "./api/ApiDocumentation";
import Spinner from "../common/Spinner";
import { useDocumentation } from "./documentation-hooks";

const ApiArea = () => {
  const { api } = useDocumentation(); // api: <useDocumentation />,
  // console.log('api', api);
  return api ? <ApiDocumentation docs={api} /> : <Spinner />;
};

export default ApiArea;
