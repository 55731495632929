/**
 * (c) 2021, vincibot Educational Foundation and contributors
 *
 * SPDX-License-Identifier: MIT
 */
import { fetchContent } from "../../common/sanity";
import { Toolkit, ToolkitTopic, ToolkitTopicEntry } from "./model";

export const fetchReferenceToolkit = async (
  languageId: string
): Promise<Toolkit> => {
 return adaptContent("") as Toolkit;
};


export const getTopicAndEntry = (
  toolkit: Toolkit,
  topicOrEntryId: string | undefined
): [ToolkitTopic | undefined, ToolkitTopicEntry | undefined] => {
  const topic = toolkit.contents?.find(
    (t) => t.slug.current === topicOrEntryId
  );
  if (topic) {
    return [topic, undefined];
  }
  const entry = toolkit.contents
    ?.flatMap((topic) => topic.contents ?? [])
    .find((entry) => entry.slug.current === topicOrEntryId);
  if (!entry) {
    return [undefined, undefined];
  }
  return [entry.parent, entry];
};

// For now we just slurp the whole toolkit at once.
// Might revisit depending on eventual size.
const toolkitQuery = (languageId: string): string => {
  return `
  *[_type == "toolkit" && language == "${languageId}" && (slug.current == "explore" || slug.current == "reference") && !(_id in path("drafts.**"))]{
    id, name, description, language,
    contents[]->{
      name, slug, compatibility, subtitle, image,
      introduction[] {
        ...,
        markDefs[]{
          ...,
          _type == "toolkitInternalLink" => {
            "slug": @.reference->slug,
            "targetType": @.reference->_type
          }
        }
      },
      contents[]->{
        name, slug, compatibility, 
        content[] {
          ...,
          markDefs[]{
            ...,
            _type == "toolkitInternalLink" => {
              "slug": @.reference->slug,
              "targetType": @.reference->_type
            }
          }
        },
        alternativesLabel, alternatives, 
        detailContent[] {
          ...,
          markDefs[]{
            ...,
            _type == "toolkitInternalLink" => {
              "slug": @.reference->slug,
              "targetType": @.reference->_type
            }
          }
        },
      }
    }
  }`;
};

const adaptContent = (result: any): Toolkit | undefined => {
  // const toolkits = result as Toolkit[];
  // if (toolkits.length === 0) {
  //   return undefined;
  // }
  // if (toolkits.length > 1) {
  //   throw new Error("Unexpected results");
  // }
  // Add topic entry parent for toolkit navigation.
  // const toolkit = toolkits[0];
  const  toolkit:any = {
    contents: [
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "d7d0a5b800f9",
                _type: "block",
                children: [
                  {
                    _key: "944363562b540",
                    _type: "span",
                    marks: [
                    ],
                    text: "Set variables to their initial values at the start of a program using the ",
                  },
                  {
                    _key: "a0e0976d60b4",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "=",
                  },
                  {
                    _key: "463b62c4826b",
                    _type: "span",
                    marks: [
                    ],
                    text: " sign:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "e4c05c8a4fa0",
                _type: "python",
                main: "count = 0",
              },
            ],
            name: "Assigning number variables",
            slug: {
              _type: "slug",
              current: "assigning-number-variables",
            },
          },
          {
            alternatives: [
              {
                _key: "0f4b02e018ff",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e58314132a09",
                    _type: "block",
                    children: [
                      {
                        _key: "ebc4366fbb85",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "103cc6483ef8",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "+=",
                      },
                      {
                        _key: "0360a836b8d8",
                        _type: "span",
                        marks: [
                        ],
                        text: " to increase, or increment, the value of a variable:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "8a235ae98e9f",
                    _type: "python",
                    main: "from microbit import *\n\n\nscore = 2\nscore += 5\ndisplay.scroll(score)",
                  },
                  {
                    _key: "0b6de4b1b75a",
                    _type: "block",
                    children: [
                      {
                        _key: "d8aab3f88e6f",
                        _type: "span",
                        marks: [
                        ],
                        text: " This increases the value of the variable ",
                      },
                      {
                        _key: "c39e715d537a",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "score",
                      },
                      {
                        _key: "54b0f3abf927",
                        _type: "span",
                        marks: [
                        ],
                        text: " by 5, and will show 7. It does the same  as ",
                      },
                      {
                        _key: "2d5c568afb43",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "count = count + 5",
                      },
                      {
                        _key: "51f8952e7691",
                        _type: "span",
                        marks: [
                        ],
                        text: " - it’s just shorter and easier to type.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "increment",
                slug: {
                  _type: "slug",
                  current: "increment",
                },
              },
              {
                _key: "695f4d2d04c6",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "65523efae01a",
                    _type: "block",
                    children: [
                      {
                        _key: "483956261fcd",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "fa55454d889c",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "-=",
                      },
                      {
                        _key: "3a92bc9e5e5f",
                        _type: "span",
                        marks: [
                        ],
                        text: " to decrease, or decrement, the value of a variable: ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "b043c589753f",
                    _type: "python",
                    main: "from microbit import *\n\n\nscore = 7\nscore -= 5\ndisplay.scroll(score)",
                  },
                  {
                    _key: "e0238150d151",
                    _type: "block",
                    children: [
                      {
                        _key: "05756fd6043c",
                        _type: "span",
                        marks: [
                        ],
                        text: "This decreases the value of the variable ",
                      },
                      {
                        _key: "478c79a24df8",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "score",
                      },
                      {
                        _key: "b19e1f76b915",
                        _type: "span",
                        marks: [
                        ],
                        text: " by 5, and will show 2. It does the same as ",
                      },
                      {
                        _key: "aade18ad5a07",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "score = score - 5",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "decrement",
                slug: {
                  _type: "slug",
                  current: "decrement",
                },
              },
              {
                _key: "301e443f3c07",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "73fb0251b725",
                    _type: "block",
                    children: [
                      {
                        _key: "6548acc6b13a0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "de4dd05c7549",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "*=",
                      },
                      {
                        _key: "5199f2bfea28",
                        _type: "span",
                        marks: [
                        ],
                        text: " to multiply the value of a variable:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "95c57cc34ae1",
                    _type: "python",
                    main: "from microbit import *\n \nscore = 2\nscore *= 5\ndisplay.scroll(score)",
                  },
                  {
                    _key: "76f3a9976015",
                    _type: "block",
                    children: [
                      {
                        _key: "aa1000f85b580",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example multiplies the value of the variable score by 5, and will show 10. It does the same as ",
                      },
                      {
                        _key: "04bba3e5dbbe",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "score = score * 5",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "b8ab09568c9e",
                    _type: "block",
                    children: [
                      {
                        _key: "d5266624b48b",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "multiply",
                slug: {
                  _type: "slug",
                  current: "multiply",
                },
              },
              {
                _key: "c42b25663631",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e64dd48e839a",
                    _type: "block",
                    children: [
                      {
                        _key: "2d176fbb7ecf0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "03d9ae55aea1",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "/=",
                      },
                      {
                        _key: "3b4439173cf1",
                        _type: "span",
                        marks: [
                        ],
                        text: " to divide the value of a variable:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "facf4cd5ff61",
                    _type: "python",
                    main: "from microbit import *\n\n\nscore = 10\nscore /= 2\ndisplay.scroll(score)",
                  },
                  {
                    _key: "ead5a0d98248",
                    _type: "block",
                    children: [
                      {
                        _key: "d8b43586682a0",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example divides the value of the variable score by 2, and will show 5.0. It does the same as ",
                      },
                      {
                        _key: "f54fabe64d09",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "score = score / 2",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "divide",
                slug: {
                  _type: "slug",
                  current: "divide",
                },
              },
            ],
            alternativesLabel: "Select operation:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "822b83c5d761",
                _type: "block",
                children: [
                  {
                    _key: "4abdc6700c2f",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can modify number variables by adding, subtracting, multiplying and dividing:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Changing number variables",
            slug: {
              _type: "slug",
              current: "changing-number-variables",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "7b798d8d291f",
                _type: "block",
                children: [
                  {
                    _key: "4ec0986dcff9",
                    _type: "span",
                    marks: [
                    ],
                    text: "A group of words or symbols is called a string. Put quotation marks around the contents of a string:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d8c480c12af0",
                _type: "python",
                main: "from microbit import *\n\n\nname = 'micro:bit'\ndisplay.scroll(name)",
              },
            ],
            detailContent: [
              {
                _key: "8414644af314",
                _type: "block",
                children: [
                  {
                    _key: "36907b2f0cee",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use single ",
                  },
                  {
                    _key: "220067a1077f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "'",
                  },
                  {
                    _key: "2be01f6aa9d9",
                    _type: "span",
                    marks: [
                    ],
                    text: " or double quotation marks ",
                  },
                  {
                    _key: "de2bdecbe010",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "\"",
                  },
                  {
                    _key: "6462275d253c",
                    _type: "span",
                    marks: [
                    ],
                    text: " around strings, but you should be consistent. We use single quotation marks in examples because they are easier to type and double quotation marks can be confused for two single quotation marks.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1463118483f4",
                _type: "block",
                children: [
                  {
                    _key: "86041e8d8d8e",
                    _type: "span",
                    marks: [
                    ],
                    text: "See ",
                  },
                  {
                    _key: "ca88ef126c6f",
                    _type: "span",
                    marks: [
                      "834048879981",
                    ],
                    text: "String manipulation",
                  },
                  {
                    _key: "0503cae77209",
                    _type: "span",
                    marks: [
                    ],
                    text: " for examples of how you can manipulate strings.",
                  },
                ],
                markDefs: [
                  {
                    _key: "834048879981",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "307144e2-4972-4b92-862f-b70be6e38aa2",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "string-manipulation",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
            ],
            name: "Assigning strings",
            slug: {
              _type: "slug",
              current: "assigning-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "99940d3b07b8",
                _type: "block",
                children: [
                  {
                    _key: "eb0b9c1d7007",
                    _type: "span",
                    marks: [
                    ],
                    text: "Boolean variables only have two values: ",
                  },
                  {
                    _key: "273e91dd7604",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "86b3dd9a0145",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "788a7f114e10",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "3d96f94e65be",
                    _type: "span",
                    marks: [
                    ],
                    text: ". They can be useful for adding control to your program:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "242ee73b7381",
                _type: "python",
                main: "from microbit import *\n\n\nrunning = False\nwhile True:\n    if button_a.was_pressed():\n        running = not running\n    if running:\n        display.show(1)\n    else:\n        display.show(0)",
              },
            ],
            detailContent: [
              {
                _key: "68e5698e2d36",
                _type: "block",
                children: [
                  {
                    _key: "4f81b65f38d5",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example uses a Boolean variable ",
                  },
                  {
                    _key: "afda3996318b",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "running",
                  },
                  {
                    _key: "2b71d99d4931",
                    _type: "span",
                    marks: [
                    ],
                    text: " to create a toggle button. The program has two states: 'running' (showing 1 on the display) and 'not running' (showing 0). Pressing button A toggles the state of the ",
                  },
                  {
                    _key: "022b11d942fe",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "running",
                  },
                  {
                    _key: "a728e2fbcfb0",
                    _type: "span",
                    marks: [
                    ],
                    text: " variable between ",
                  },
                  {
                    _key: "3bd2407c501a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "04222a2d30c9",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "16e8385191bf",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "f0d154aa0227",
                    _type: "span",
                    marks: [
                    ],
                    text: " every time you press it:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ff40af949daa",
                _type: "block",
                children: [
                  {
                    _key: "65dc0321a631",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "not",
                  },
                  {
                    _key: "81695a90530f",
                    _type: "span",
                    marks: [
                    ],
                    text: " is a logical operator that flips the state of the variable. If its value was ",
                  },
                  {
                    _key: "45e7c8cfcf58",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "dc8150b36647",
                    _type: "span",
                    marks: [
                    ],
                    text: ", it becomes ",
                  },
                  {
                    _key: "ecf49b1a7a32",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "4de6922c7a52",
                    _type: "span",
                    marks: [
                    ],
                    text: ". If it was ",
                  },
                  {
                    _key: "fe642d49facc",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "d0dd8e0aedf3",
                    _type: "span",
                    marks: [
                    ],
                    text: ", it becomes ",
                  },
                  {
                    _key: "5c0e37c4332c",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "1520af4bcb1d",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "aed1d6314cb6",
                _type: "block",
                children: [
                  {
                    _key: "989ec569fb5c",
                    _type: "span",
                    marks: [
                    ],
                    text: "Note that ",
                  },
                  {
                    _key: "bc57ae76bdd4",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "975c91e5c180",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "a9799a2f25d9",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "36e7a630ac81",
                    _type: "span",
                    marks: [
                    ],
                    text: " must start with capital letters.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Boolean variables",
            slug: {
              _type: "slug",
              current: "boolean-variables",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "4384f30d93f1",
                _type: "block",
                children: [
                  {
                    _key: "40bbdc2ffa93",
                    _type: "span",
                    marks: [
                    ],
                    text: "should describe what they contain so your code is easier to understand",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "5eeba32fa111",
                _type: "block",
                children: [
                  {
                    _key: "5e9240083a0e0",
                    _type: "span",
                    marks: [
                    ],
                    text: "must start with a letter, not a number",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "153af3272c2a",
                _type: "block",
                children: [
                  {
                    _key: "caa730a661be0",
                    _type: "span",
                    marks: [
                    ],
                    text: "cannot contain any spaces",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "952f4fa54e9d",
                _type: "block",
                children: [
                  {
                    _key: "1fa3dcb107130",
                    _type: "span",
                    marks: [
                    ],
                    text: "can’t be the same as Python keywords like ",
                  },
                  {
                    _key: "035e1abf862a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "b5c6b706fdc8",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "a9e224ab799d",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "if",
                  },
                  {
                    _key: "317e8c87b764",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "22c03ba757be",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "while",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1aab9ca74573",
                _type: "block",
                children: [
                  {
                    _key: "b196876c15e50",
                    _type: "span",
                    marks: [
                    ],
                    text: "are case-sensitive: ",
                  },
                  {
                    _key: "fb301fed6731",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "score",
                  },
                  {
                    _key: "cab4a9473d54",
                    _type: "span",
                    marks: [
                    ],
                    text: " would be a different variable to ",
                  },
                  {
                    _key: "1637ddab03b1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "Score",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Variable names",
            slug: {
              _type: "slug",
              current: "variable-names",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-b80a810d48ec07575359e04f0eb45ab48e458388-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "a440995e96c6",
            _type: "block",
            children: [
              {
                _key: "d22146858b50",
                _type: "span",
                marks: [
                ],
                text: "Variables are used to store data that may change, such as the number of points you have in a game. Variables can have different ",
              },
              {
                _key: "ecf606a7ca74",
                _type: "span",
                marks: [
                  "66b713591c98",
                ],
                text: "data types",
              },
              {
                _key: "32470a7079e9",
                _type: "span",
                marks: [
                ],
                text: ".",
              },
            ],
            markDefs: [
              {
                _key: "66b713591c98",
                _type: "toolkitInternalLink",
                reference: {
                  _ref: "38feee7a-be99-48db-a3ce-c0b366bafa43",
                  _type: "reference",
                },
                slug: {
                  _type: "slug",
                  current: "data-types",
                },
                targetType: "toolkitTopic",
              },
            ],
            style: "normal",
          },
        ],
        name: "Variables",
        slug: {
          _type: "slug",
          current: "variables",
        },
        subtitle: "Keep track of data that changes",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "8118b5d9d756",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d64032fe5207",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.HEART)\nsleep(400)",
                  },
                ],
                name: "HEART",
                slug: {
                  _type: "slug",
                  current: "heart",
                },
              },
              {
                _key: "b48b05d1cf97",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "4c9000d56464",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.HEART_SMALL)\nsleep(400)",
                  },
                ],
                name: "HEART_SMALL",
                slug: {
                  _type: "slug",
                  current: "heartsmall",
                },
              },
              {
                _key: "cdf2e556b4bd",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6e46afc722f2",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.HAPPY)\nsleep(400)",
                  },
                ],
                name: "HAPPY",
                slug: {
                  _type: "slug",
                  current: "happy",
                },
              },
              {
                _key: "32d9939f5083",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "282aa7c6b082",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.SMILE)\nsleep(400)",
                  },
                ],
                name: "SMILE",
                slug: {
                  _type: "slug",
                  current: "smile",
                },
              },
              {
                _key: "303aed5cba72",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a7a3c0031b1c",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.SAD)\nsleep(400)",
                  },
                ],
                name: "SAD",
                slug: {
                  _type: "slug",
                  current: "sad",
                },
              },
              {
                _key: "3693f6c6cb31",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d61a18bd49ae",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.CONFUSED)\nsleep(400)",
                  },
                ],
                name: "CONFUSED",
                slug: {
                  _type: "slug",
                  current: "confused",
                },
              },
              {
                _key: "171676c0e821",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "06dc9a1ebd67",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.ANGRY)\nsleep(400)",
                  },
                ],
                name: "ANGRY",
                slug: {
                  _type: "slug",
                  current: "angry",
                },
              },
              {
                _key: "448e95994818",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a5dfd9a0be04",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.ASLEEP)\nsleep(400)",
                  },
                ],
                name: "ASLEEP",
                slug: {
                  _type: "slug",
                  current: "asleep",
                },
              },
              {
                _key: "358d6e7ca29c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "dcae6ac54fa6",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.SURPRISED)\nsleep(400)",
                  },
                ],
                name: "SURPRISED",
                slug: {
                  _type: "slug",
                  current: "surprised",
                },
              },
              {
                _key: "d38021d9509c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "871398392123",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.SILLY)\nsleep(400)",
                  },
                ],
                name: "SILLY",
                slug: {
                  _type: "slug",
                  current: "silly",
                },
              },
              {
                _key: "9d2c8099e05b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "581086d57663",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.FABULOUS)\nsleep(400)",
                  },
                ],
                name: "FABULOUS",
                slug: {
                  _type: "slug",
                  current: "fabulous",
                },
              },
              {
                _key: "0fe7052b697f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "ec6c73cb25b8",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.YES)\nsleep(400)",
                  },
                ],
                name: "YES",
                slug: {
                  _type: "slug",
                  current: "yes",
                },
              },
              {
                _key: "bea89cbb076c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "230f05b19b45",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.NO)\nsleep(400)",
                  },
                ],
                name: "NO",
                slug: {
                  _type: "slug",
                  current: "no",
                },
              },
              {
                _key: "23fdcb962080",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6338401628c3",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.MEH)\nsleep(400)",
                  },
                ],
                name: "MEH",
                slug: {
                  _type: "slug",
                  current: "meh",
                },
              },
              {
                _key: "51a2aea731672b8b95688d183a0caf8d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "230f05b19b45",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.DUCK)\nsleep(400)",
                  },
                ],
                name: "DUCK",
                slug: {
                  _type: "slug",
                  current: "duck",
                },
              },
              {
                _key: "b31e19788244",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "1c305b69d604",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.GIRAFFE)\nsleep(400)",
                  },
                ],
                name: "GIRAFFE",
                slug: {
                  _type: "slug",
                  current: "giraffe",
                },
              },
              {
                _key: "131e4b83aea1b922a371cd946fb38880",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "230f05b19b45",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.PACMAN)\nsleep(400)",
                  },
                ],
                name: "PACMAN",
                slug: {
                  _type: "slug",
                  current: "pacman",
                },
              },
              {
                _key: "dd3d0832f6b609c81ea4cb07690b8499",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "230f05b19b45",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.GHOST)\nsleep(400)",
                  },
                ],
                name: "GHOST",
                slug: {
                  _type: "slug",
                  current: "ghost",
                },
              },
              {
                _key: "bf11cca28da3",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "02078e8c055f",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.show(Image.SKULL)\nsleep(400)",
                  },
                ],
                name: "SKULL",
                slug: {
                  _type: "slug",
                  current: "skull",
                },
              },
            ],
            alternativesLabel: "Select image:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "8eb8640e5ff1",
                _type: "block",
                children: [
                  {
                    _key: "65adada32e4b",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit has lots of built-in images that you can show on the display.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "f0c46e0e8815",
                _type: "block",
                children: [
                  {
                    _key: "b9344af09fd8",
                    _type: "span",
                    marks: [
                    ],
                    text: "The API documentation contains a full list of ",
                  },
                  {
                    _key: "d408188fd0c9",
                    _type: "span",
                    marks: [
                      "d9a5428fa642",
                    ],
                    text: "the built-in images",
                  },
                  {
                    _key: "cfad341c9304",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                  {
                    _key: "d9a5428fa642",
                    _type: "toolkitApiLink",
                    name: "microbit.Image",
                  },
                ],
                style: "normal",
              },
            ],
            name: "Images: built-in",
            slug: {
              _type: "slug",
              current: "images-builtin",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "95d3b68acbb9",
                _type: "block",
                children: [
                  {
                    _key: "df74bca0ee28",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can scroll words and numbers on the micro:bit’s LED display:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a93e94475fd6",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll('score')    \ndisplay.scroll(23)    ",
              },
            ],
            detailContent: [
              {
                _key: "9334fe5290cd",
                _type: "block",
                children: [
                  {
                    _key: "69818077d6840",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can add optional extra parameters to control the way scrolling works.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7085e52ce296",
                _type: "block",
                children: [
                  {
                    _key: "f1d74cc2e15c",
                    _type: "span",
                    marks: [
                    ],
                    text: "The ",
                  },
                  {
                    _key: "6106d4cfa23b",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "delay",
                  },
                  {
                    _key: "37ece849f89f",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameter controls the speed of scrolling. A delay less than 150ms will make scrolling faster than normal.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "5869cee33103",
                _type: "block",
                children: [
                  {
                    _key: "7b96e7fd471c0",
                    _type: "span",
                    marks: [
                    ],
                    text: "If the ",
                  },
                  {
                    _key: "58b7e32a02cd",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "loop",
                  },
                  {
                    _key: "6cd2d941a9ca",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameter has a value of ",
                  },
                  {
                    _key: "3e34952f2e00",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "cb4ab6785fc0",
                    _type: "span",
                    marks: [
                    ],
                    text: ", the scrolling will loop continuously. The default value is ",
                  },
                  {
                    _key: "8264b4880452",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "176236299f9a",
                    _type: "span",
                    marks: [
                    ],
                    text: " which means there is no looping.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "c30596e75e6c",
                _type: "block",
                children: [
                  {
                    _key: "ec9e95d483a60",
                    _type: "span",
                    marks: [
                    ],
                    text: "The ",
                  },
                  {
                    _key: "9a52d3ba91c3",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "wait",
                  },
                  {
                    _key: "3da80cb1d809",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameter controls whether the program waits for the scrolling to stop before carrying out the next instruction.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "61ca589d6a2c",
                _type: "block",
                children: [
                  {
                    _key: "508856599ea70",
                    _type: "span",
                    marks: [
                    ],
                    text: "This code will keep scrolling the word 'score' faster than normal in a loop, and play music at the same time:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "13fc5945c776",
                _type: "python",
                main: "from microbit import *\nimport music\n\n\ndisplay.scroll('score', delay=100, loop=True, wait=False) \nmusic.play(music.ODE)",
              },
            ],
            name: "Scroll",
            slug: {
              _type: "slug",
              current: "scroll",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "95d3b68acbb9",
                _type: "block",
                children: [
                  {
                    _key: "059d137edbee",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can show words and numbers on the LED display one character at a time:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a93e94475fd6",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.show('score')\ndisplay.show(23)   ",
              },
            ],
            detailContent: [
              {
                _key: "0f8a60f322b1",
                _type: "block",
                children: [
                  {
                    _key: "8c93d04e2d2a0",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can add optional ",
                  },
                  {
                    _key: "0bd58893cbee",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "delay",
                  },
                  {
                    _key: "4b2164a13197",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "6f1db902bc15",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "loop",
                  },
                  {
                    _key: "785a118321d7",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "853024522efc",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "wait",
                  },
                  {
                    _key: "8b6ce42fd624",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameters.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "aa24dbe5cf55",
                _type: "block",
                children: [
                  {
                    _key: "d42c11e964210",
                    _type: "span",
                    marks: [
                    ],
                    text: "The ",
                  },
                  {
                    _key: "70904c2dfb5e",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "delay",
                  },
                  {
                    _key: "15af6798f3ef",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameter controls the speed at which multiple characters, digits or images are displayed. Any delay below 400ms will be faster than normal, anything above 400ms slower than normal.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "45f339801a38",
                _type: "block",
                children: [
                  {
                    _key: "dd6279a7f8500",
                    _type: "span",
                    marks: [
                    ],
                    text: "If the ",
                  },
                  {
                    _key: "5a969bfb2fd1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "loop",
                  },
                  {
                    _key: "c8cd37e50a5c",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameter is set to ",
                  },
                  {
                    _key: "17976370cdd7",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "5b79f6effa6b",
                    _type: "span",
                    marks: [
                    ],
                    text: ", the ",
                  },
                  {
                    _key: "5046ede49053",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "show",
                  },
                  {
                    _key: "703c3b2bea72",
                    _type: "span",
                    marks: [
                    ],
                    text: " function will loop continuously. The default value is ",
                  },
                  {
                    _key: "b1ddfea819d9",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "e1b8455485f8",
                    _type: "span",
                    marks: [
                    ],
                    text: " which means there is no looping.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a7eacda798e4",
                _type: "block",
                children: [
                  {
                    _key: "316b06f7abc00",
                    _type: "span",
                    marks: [
                    ],
                    text: "The ",
                  },
                  {
                    _key: "273312b95a48",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "wait",
                  },
                  {
                    _key: "775fd5c19844",
                    _type: "span",
                    marks: [
                    ],
                    text: " parameter controls how long the program waits before the next instruction is executed.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d5cc9cc94349",
                _type: "block",
                children: [
                  {
                    _key: "24ab61839877",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example will keep showing a countdown in a loop with 1 second between each number and will not wait before executing the next instruction:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "13fc5945c776",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.show(9876543210, delay=1000, loop=True, wait=False)\n",
              },
            ],
            name: "Show",
            slug: {
              _type: "slug",
              current: "show",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "070d2f890a29",
                _type: "block",
                children: [
                  {
                    _key: "50964ee11485",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can make your own images. Each LED's brightness is represented as a number between 0 (off) and 9 (brightest):",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "c3acde86c37d",
                _type: "simpleImage",
                alt: "diagram showing how numbers between 0 and 9 make LEDs darker and brighter",
                asset: {
                  _ref: "image-813bf91ece2f0a458709b5395c58963e83a997b0-1117x566-png",
                  _type: "reference",
                },
              },
              {
                _key: "9b264dcd3365",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.show(Image('00300:'\n                   '03630:'\n                   '36963:'\n                   '03630:'\n                   '00300'))    ",
              },
            ],
            detailContent: [
              {
                _key: "9357ac7b6e26",
                _type: "block",
                children: [
                  {
                    _key: "082b67ce22a7",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example shows a star that is bright in the middle and gets fainter towards the edges of the LED display.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Images: make your own",
            slug: {
              _type: "slug",
              current: "images-make-your-own",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "ea7463f721cf",
                _type: "block",
                children: [
                  {
                    _key: "4197bd896b3b",
                    _type: "span",
                    marks: [
                    ],
                    text: "Clear the display, turning all the LEDs off:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ed5772ff8ce7",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.clear()",
              },
            ],
            name: "Clear the display",
            slug: {
              _type: "slug",
              current: "clear-the-display",
            },
          },
          {
            alternatives: [
              {
                _key: "80a97150164b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "4d986ab60529",
                    _type: "block",
                    children: [
                      {
                        _key: "8a72e134a56a",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use numbers 0 to 9 to select how bright you want each pixel to be, with 9 being the brightest. This will light the top left LED as bright as it can go:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "914f58c441ad",
                    _type: "python",
                    main: "from microbit import *\n\n\ndisplay.set_pixel(0,0,9)",
                  },
                  {
                    _key: "3f0dc5c0d7e8",
                    _type: "block",
                    children: [
                      {
                        _key: "4a4c1ec44ddd",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "set pixels",
                slug: {
                  _type: "slug",
                  current: "set-pixels",
                },
              },
              {
                _key: "0b734d878790",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "91ce3ef63e8d",
                    _type: "block",
                    children: [
                      {
                        _key: "dc610c231b6d0",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can find out how bright a pixel is. This will read how bright the top left pixel is and store a value between 0 and 9 in a variable called ",
                      },
                      {
                        _key: "c015ade4727c",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "top_left:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "e61a653a3500",
                    _type: "python",
                    main: "from microbit import *\n \n \ntop_left = display.get_pixel(0,0)",
                  },
                  {
                    _key: "e09ef4d3c617",
                    _type: "block",
                    children: [
                      {
                        _key: "bc6b4ff286e3",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "get pixels",
                slug: {
                  _type: "slug",
                  current: "get-pixels",
                },
              },
            ],
            alternativesLabel: "Select:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "1f09b0efacaa",
                _type: "simpleImage",
                alt: "diagram of X and Y co-ordinates of micro:bit's LED display, 0 to 4 from left to right in the X axis, and 0 to 4 from top to bottom",
                asset: {
                  _ref: "image-68df93c56b28545fba2ed65e8e75e4e325ed2240-680x550-png",
                  _type: "reference",
                },
              },
              {
                _key: "96692d4be0c1",
                _type: "block",
                children: [
                  {
                    _key: "962879ebdc3b0",
                    _type: "span",
                    marks: [
                    ],
                    text: "A pixel is an individual dot in an image. Each LED is a pixel in a micro:bit image. Each pixel has an x,y co-ordinate, starting at the top left with 0,0.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "60c6be5cfea4",
                _type: "block",
                children: [
                  {
                    _key: "868bb242f0b3",
                    _type: "span",
                    marks: [
                    ],
                    text: "This program will fill the LED display one pixel at a time:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "054237d28459",
                _type: "python",
                main: "from microbit import *\n\n\nfor y in range(5):\n    for x in range(5):\n        display.set_pixel(x,y,9)\n        sleep(50)",
              },
              {
                _key: "4dc4d1478ab6",
                _type: "block",
                children: [
                  {
                    _key: "0955dc795e55",
                    _type: "span",
                    marks: [
                    ],
                    text: "Note that this uses a nested ",
                  },
                  {
                    _key: "f81aebba2b9e",
                    _type: "span",
                    marks: [
                      "08132652d7c8",
                    ],
                    text: "loop",
                  },
                  {
                    _key: "09649282503f",
                    _type: "span",
                    marks: [
                    ],
                    text: ", a loop inside a loop.",
                  },
                ],
                markDefs: [
                  {
                    _key: "08132652d7c8",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "c12b474c-848e-4ddd-9d9b-fa3e9b3bbb99",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "loops",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
            ],
            name: "Pixels",
            slug: {
              _type: "slug",
              current: "pixels",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-8a752c2b7226e069f7ffca204f48ba22187de80c-144x144-svg",
            _type: "reference",
          },
        },
        name: "Display",
        slug: {
          _type: "slug",
          current: "display",
        },
        subtitle: "The micro:bit's LED display output",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "0fc99c635e39",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "660728fa968b",
                    _type: "block",
                    children: [
                      {
                        _key: "c6d8b18fe8ba",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "5b3bed9dfde3",
                    _type: "python",
                    main: "from microbit import *\n\nwhile True:\n    if button_a.was_pressed():\n        display.scroll('A')",
                  },
                ],
                name: "A",
                slug: {
                  _type: "slug",
                  current: "a",
                },
              },
              {
                _key: "f695e790aeda",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "16f94c6c7845",
                    _type: "block",
                    children: [
                      {
                        _key: "60425c5af890",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "41bda56b2763",
                    _type: "python",
                    main: "from microbit import *\n\nwhile True:\n    if button_b.was_pressed():\n        display.scroll('B')",
                  },
                ],
                name: "B",
                slug: {
                  _type: "slug",
                  current: "b",
                },
              },
              {
                _key: "cdec48595ad6e1fdfef7fecae5930733",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "48a5eba19273",
                    _type: "block",
                    children: [
                      {
                        _key: "09f5f5450bb6",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "90825b348507",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if button_a.was_pressed() or button_b.was_pressed():\n        display.scroll('A or B')",
                  },
                ],
                name: "A or B",
                slug: {
                  _type: "slug",
                  current: "a-or-b",
                },
              },
            ],
            alternativesLabel: "Choose button:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            detailContent: [
              {
                _key: "5e92af69f33c",
                _type: "block",
                children: [
                  {
                    _key: "4de1b2f1f4e0",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "edc4969036c9",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "was_pressed()",
                  },
                  {
                    _key: "7f37c5e30d55",
                    _type: "span",
                    marks: [
                    ],
                    text: " to check if a button ",
                  },
                  {
                    _key: "80dcff0d8913",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "was ",
                  },
                  {
                    _key: "1a571035292b",
                    _type: "span",
                    marks: [
                    ],
                    text: "pressed since the micro:bit was powered on, or since the last time ",
                  },
                  {
                    _key: "dacfc77b4a69",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "was_pressed()",
                  },
                  {
                    _key: "6832b421b4af",
                    _type: "span",
                    marks: [
                    ],
                    text: " was used.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "88ae6bf04954",
                _type: "block",
                children: [
                  {
                    _key: "c32922d47ead",
                    _type: "span",
                    marks: [
                    ],
                    text: "In the example above, text will only scroll on the display once if you hold the button down.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a322af3df597",
                _type: "block",
                children: [
                  {
                    _key: "be75647d6969",
                    _type: "span",
                    marks: [
                    ],
                    text: "",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Button was pressed",
            slug: {
              _type: "slug",
              current: "button-was-pressed",
            },
          },
          {
            alternatives: [
              {
                _key: "0fc99c635e39",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "660728fa968b",
                    _type: "block",
                    children: [
                      {
                        _key: "c6d8b18fe8ba",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "5b3bed9dfde3",
                    _type: "python",
                    main: "from microbit import *\n\nwhile True:\n    if button_a.is_pressed():\n        display.scroll('A')",
                  },
                ],
                name: "A",
                slug: {
                  _type: "slug",
                  current: "a",
                },
              },
              {
                _key: "f695e790aeda",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "16f94c6c7845",
                    _type: "block",
                    children: [
                      {
                        _key: "60425c5af890",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "41bda56b2763",
                    _type: "python",
                    main: "from microbit import *\n\nwhile True:\n    if button_b.is_pressed():\n        display.scroll('B')",
                  },
                ],
                name: "B",
                slug: {
                  _type: "slug",
                  current: "b",
                },
              },
              {
                _key: "f8166348a72d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "48a5eba19273",
                    _type: "block",
                    children: [
                      {
                        _key: "09f5f5450bb6",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "90825b348507",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if button_a.is_pressed() and button_b.is_pressed():\n        display.scroll('A and B')\n",
                  },
                ],
                name: "A and B",
                slug: {
                  _type: "slug",
                  current: "a-and-b",
                },
              },
              {
                _key: "cdec48595ad6e1fdfef7fecae5930733",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "48a5eba19273",
                    _type: "block",
                    children: [
                      {
                        _key: "09f5f5450bb6",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "90825b348507",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if button_a.is_pressed() or button_b.is_pressed():\n        display.scroll('A or B')",
                  },
                ],
                name: "A or B",
                slug: {
                  _type: "slug",
                  current: "a-or-b",
                },
              },
            ],
            alternativesLabel: "Choose button:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            detailContent: [
              {
                _key: "a44cc3a7019d",
                _type: "block",
                children: [
                  {
                    _key: "8212808a7222",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "9731fe358909",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "is_pressed()",
                  },
                  {
                    _key: "f78d17ca26f0",
                    _type: "span",
                    marks: [
                    ],
                    text: " to check if a button ",
                  },
                  {
                    _key: "c84a617a5bc8",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "is currently",
                  },
                  {
                    _key: "4f3dd9985fc4",
                    _type: "span",
                    marks: [
                    ],
                    text: " being pressed. In the example above, the text will keep scrolling if you hold your finger down on the button.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d4414e604f31",
                _type: "block",
                children: [
                  {
                    _key: "0ac11195e868",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can check which button or buttons are being pressed:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "11c5abfb83f8",
                _type: "python",
                main: "from microbit import *\n \n \nwhile True:\n    if button_a.is_pressed() and button_b.is_pressed():\n        display.scroll('A and B')\n    elif button_a.is_pressed():\n        display.scroll('A')\n    elif button_b.is_pressed():\n        display.scroll('B')\n    sleep(100)\n",
              },
              {
                _key: "7745b6dc3fa9",
                _type: "block",
                children: [
                  {
                    _key: "10f9e88e38f00",
                    _type: "span",
                    marks: [
                    ],
                    text: "You must check if buttons A and B are pressed together ",
                  },
                  {
                    _key: "10f9e88e38f01",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "before",
                  },
                  {
                    _key: "10f9e88e38f02",
                    _type: "span",
                    marks: [
                    ],
                    text: " a single button press otherwise you’ll never capture both buttons being pressed at the same time.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Button is pressed",
            slug: {
              _type: "slug",
              current: "button-is-pressed",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "4d4cc49ce5ee",
                _type: "block",
                children: [
                  {
                    _key: "38df8a9943b7",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "83773da2da8f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "get_presses()",
                  },
                  {
                    _key: "fcc86d15ddf5",
                    _type: "span",
                    marks: [
                    ],
                    text: " to count how many times a button was pressed:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "bacad03618ef",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('Press A')\nsleep(3000)\ndisplay.scroll(button_a.get_presses())",
              },
            ],
            detailContent: [
              {
                _key: "84f0758f35e6",
                _type: "block",
                children: [
                  {
                    _key: "77c8d4f7269b",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example, press button A as many times as you can in 3 seconds. The number of presses is shown on the display.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "c821571bdf2e",
                _type: "block",
                children: [
                  {
                    _key: "f785c6f2d25a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "get_presses()",
                  },
                  {
                    _key: "e2b1f63eb9d9",
                    _type: "span",
                    marks: [
                    ],
                    text: " counts the number of times a button was pressed since the micro:bit started or the last time ",
                  },
                  {
                    _key: "1368d795328c",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "get_presses()",
                  },
                  {
                    _key: "794f4aba5d52",
                    _type: "span",
                    marks: [
                    ],
                    text: " was used.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1b427a702f89",
                _type: "block",
                children: [
                  {
                    _key: "347255715fa0",
                    _type: "span",
                    marks: [
                    ],
                    text: "",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Count button presses",
            slug: {
              _type: "slug",
              current: "count-button-presses",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-3f4b95ffe1074dd4e391011c2835fc39dfef4a63-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "4af57f17a7da",
            _type: "simpleImage",
            asset: {
              _ref: "image-09a2e279df571cb7224ac1b677db6aab6f64df33-1012x687-png",
              _type: "reference",
            },
            halfWidth: true,
          },
          {
            _key: "63f4ac05f012",
            _type: "block",
            children: [
              {
                _key: "92220c234793",
                _type: "span",
                marks: [
                ],
                text: "The micro:bit has two buttons on the front. Button A and button B can be used as inputs to make things happen in your program when they're pressed.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Buttons",
        slug: {
          _type: "slug",
          current: "buttons",
        },
        subtitle: "Use button inputs in your code",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "a6190ff43b3c",
                _type: "block",
                children: [
                  {
                    _key: "2956d9c33110",
                    _type: "span",
                    marks: [
                    ],
                    text: "Python uses indentations to show which instructions are inside and outside a loop.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "050ece268825",
                _type: "python",
                main: "from microbit import *\n\n\nfor i in range(3):  \n    display.scroll('micro:bit')\ndisplay.scroll('Python')",
              },
            ],
            detailContent: [
              {
                _key: "87ceda5bc312",
                _type: "block",
                children: [
                  {
                    _key: "21bf14cba889",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example uses a ",
                  },
                  {
                    _key: "45ca039fca53",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "for loop",
                  },
                  {
                    _key: "62ab5f162fce",
                    _type: "span",
                    marks: [
                    ],
                    text: " to scroll ‘micro:bit’ on the LED display 3 times.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7e8c6774f2ac",
                _type: "block",
                children: [
                  {
                    _key: "35793e3d5af6",
                    _type: "span",
                    marks: [
                    ],
                    text: "The ",
                  },
                  {
                    _key: "77ca911748b9",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "display.scroll('Python')",
                  },
                  {
                    _key: "e3b94635468e",
                    _type: "span",
                    marks: [
                    ],
                    text: " line is not indented, so it’s not in the loop and it only runs once.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "64122071bed9",
                _type: "block",
                children: [
                  {
                    _key: "f646f1508d74",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit Python editor will add indentations for you when it thinks you need one, for example when you press ‘enter’ after typing ",
                  },
                  {
                    _key: "59e782e2dd4b",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "for i in range(3):",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "771c3a4974f6",
                _type: "block",
                children: [
                  {
                    _key: "5db65e0e4b31",
                    _type: "span",
                    marks: [
                    ],
                    text: "Indentations are usually 4 spaces or one tab character. You must be consistent, you can’t use 4 spaces on one line and 3 on another. The code highlighting in the editor helps you check that your indentations line up correctly.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "49e4c8d852bd",
                _type: "block",
                children: [
                  {
                    _key: "13444d387cd7",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can indent any lines you have highlighted by pressing the tab key on your keyboard. You can remove indentations by pressing shift+tab.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Indentations",
            slug: {
              _type: "slug",
              current: "indentations",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "85a72d1084f3",
                _type: "block",
                children: [
                  {
                    _key: "d51962710734",
                    _type: "span",
                    marks: [
                    ],
                    text: "Infinite, or 'forever', loops keep a program running.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1fb4ea7936a7",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    display.scroll('micro:bit')  ",
              },
            ],
            detailContent: [
              {
                _key: "c83122e415f5",
                _type: "block",
                children: [
                  {
                    _key: "588503487c69",
                    _type: "span",
                    marks: [
                    ],
                    text: "In the example above, the word ‘micro:bit’ will scroll across the LED display forever.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "4920ee3342c4",
                _type: "block",
                children: [
                  {
                    _key: "93f447ef330b",
                    _type: "span",
                    marks: [
                    ],
                    text: "Using ",
                  },
                  {
                    _key: "e06aa056d4c4",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "while True:",
                  },
                  {
                    _key: "dee9630eb06a",
                    _type: "span",
                    marks: [
                    ],
                    text: " is a common way to continuously check inputs like sensor readings or if a button has been pressed:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "26dc48524910",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    if button_a.was_pressed():\n        display.show('A')",
              },
            ],
            name: "While loops: infinite",
            slug: {
              _type: "slug",
              current: "while-loops-infinite",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "75663c1edc02",
                _type: "block",
                children: [
                  {
                    _key: "85b1ff613630",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "While loops",
                  },
                  {
                    _key: "710bab754cb9",
                    _type: "span",
                    marks: [
                    ],
                    text: " keep a block of code running as long as something is true.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "4e25ae438847",
                _type: "python",
                main: "from microbit import *\n\n\nnumber = 0\nwhile number < 10:\n    display.scroll(number)\n    number = number + 1\ndisplay.scroll('finish')",
              },
            ],
            detailContent: [
              {
                _key: "1d09e9bd4ff4",
                _type: "block",
                children: [
                  {
                    _key: "97558d3b3fe0",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example, the loop keeps running while the variable ",
                  },
                  {
                    _key: "eccd79d02800",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "91eb04c374a6",
                    _type: "span",
                    marks: [
                    ],
                    text: " has a value less than 10. When the value reaches 10, the loop ends, so it will count from 0 to 9.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "082bc0f395c4",
                _type: "block",
                children: [
                  {
                    _key: "8177bd536049",
                    _type: "span",
                    marks: [
                    ],
                    text: "The line ",
                  },
                  {
                    _key: "1b17b0c49dd5",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "display.scroll('finish')",
                  },
                  {
                    _key: "c49e6320a802",
                    _type: "span",
                    marks: [
                    ],
                    text: " is not indented, so it is outside the loop. The code only shows the word 'finish' once the number variable reaches 10 and the loop ends.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "While loops: conditional",
            slug: {
              _type: "slug",
              current: "while-loops-conditional",
            },
          },
          {
            alternatives: [
              {
                _key: "31c1273a20e3",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "9d21f397d474",
                    _type: "block",
                    children: [
                      {
                        _key: "2008fbd2594a0",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will scroll ‘micro:bit’ on the display 3 times:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "fd6c70218080",
                    _type: "python",
                    main: "from microbit import *\n \n \nfor i in range(3):  \n    display.scroll('hello')",
                  },
                ],
                name: "repeat with range",
                slug: {
                  _type: "slug",
                  current: "repeat-with-range",
                },
              },
              {
                _key: "64041648fa62",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b19cb93385d0",
                    _type: "block",
                    children: [
                      {
                        _key: "2f6257f8407f0",
                        _type: "span",
                        marks: [
                        ],
                        text: "The ",
                      },
                      {
                        _key: "3d452a392b1a",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "range()",
                      },
                      {
                        _key: "94e843d2f3e1",
                        _type: "span",
                        marks: [
                        ],
                        text: " function starts counting at 0 and stops before the end of the range, so this shows numbers from 0 to 9:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "a27c4f4acf98",
                    _type: "python",
                    main: "from microbit import *\n \n \nfor i in range(10):\n    display.scroll(i)",
                  },
                ],
                name: "count with range",
                slug: {
                  _type: "slug",
                  current: "count-with-range",
                },
              },
              {
                _key: "7fc52a6089ef",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a0d1d6fb9949",
                    _type: "block",
                    children: [
                      {
                        _key: "8aca2abc6a410",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can specify what number to start counting from:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "17c95b9e05f6",
                    _type: "python",
                    main: "from microbit import *\n \n \nfor i in range(1,11):\n    display.scroll(i)",
                  },
                  {
                    _key: "159551049c3e",
                    _type: "block",
                    children: [
                      {
                        _key: "1844291ccc5b",
                        _type: "span",
                        marks: [
                        ],
                        text: "This shows numbers from 1 to 10, because ",
                      },
                      {
                        _key: "4c551fac94d3",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "range()",
                      },
                      {
                        _key: "4c10ebec622d",
                        _type: "span",
                        marks: [
                        ],
                        text: " stops before the end number.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "count from",
                slug: {
                  _type: "slug",
                  current: "count-from",
                },
              },
              {
                _key: "c751669db5af",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "87aba5ebe433",
                    _type: "block",
                    children: [
                      {
                        _key: "e99a99a3ca67",
                        _type: "span",
                        marks: [
                        ],
                        text: "This counts from 0 to 10 in steps of 3, showing 0, 3, 6, 9:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "6b256fc91ad3",
                    _type: "python",
                    main: "from microbit import *\n\n\nfor i in range(0,10,3):\n    display.scroll(i)",
                  },
                ],
                name: "count in steps",
                slug: {
                  _type: "slug",
                  current: "count-in-steps",
                },
              },
              {
                _key: "b7d3195b61f6",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d5b0b113ff36",
                    _type: "block",
                    children: [
                      {
                        _key: "1dcd810a98700",
                        _type: "span",
                        marks: [
                        ],
                        text: "This counts down from 9 in steps of minus 1:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "b876877204af",
                    _type: "python",
                    main: "from microbit import *\n \n \nfor i in range(9,0,-1):\n    display.scroll(i)",
                  },
                  {
                    _key: "aa37c8a70368",
                    _type: "block",
                    children: [
                      {
                        _key: "e2ff8b0ac5e6",
                        _type: "span",
                        marks: [
                        ],
                        text: "It stops counting at 1 because the ",
                      },
                      {
                        _key: "67179a55e359",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "range()",
                      },
                      {
                        _key: "c0c7ee32f519",
                        _type: "span",
                        marks: [
                        ],
                        text: " function always stops ",
                      },
                      {
                        _key: "3501055259b8",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "before",
                      },
                      {
                        _key: "d1af3b5483a4",
                        _type: "span",
                        marks: [
                        ],
                        text: " the end of the range (0).",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "count backwards",
                slug: {
                  _type: "slug",
                  current: "count-backwards",
                },
              },
              {
                _key: "d25703bdc62c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "4534ebadcb29",
                    _type: "block",
                    children: [
                      {
                        _key: "c3f7b0a9fcb80",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can use 'for' loops to iterate over items in a ",
                      },
                      {
                        _key: "3443e81424df",
                        _type: "span",
                        marks: [
                          "d629316cf4af",
                        ],
                        text: "list",
                      },
                      {
                        _key: "9cb84a7c7222",
                        _type: "span",
                        marks: [
                        ],
                        text: ":",
                      },
                    ],
                    markDefs: [
                      {
                        _key: "d629316cf4af",
                        _type: "toolkitInternalLink",
                        reference: {
                          _ref: "47cd8e43-0c5d-422d-867c-5cb80ee643d3",
                          _type: "reference",
                        },
                      },
                    ],
                    style: "normal",
                  },
                  {
                    _key: "a0b49dd2b75b",
                    _type: "python",
                    main: "from microbit import *\n \n \nfor number in [1, 2, 3]:\n    display.scroll(number)",
                  },
                  {
                    _key: "de71499349d6",
                    _type: "block",
                    children: [
                      {
                        _key: "0831f246c568",
                        _type: "span",
                        marks: [
                        ],
                        text: "In this example ",
                      },
                      {
                        _key: "fd029757635b",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "number",
                      },
                      {
                        _key: "fe33c0d0be90",
                        _type: "span",
                        marks: [
                        ],
                        text: " is a variable name. You can use any valid variable name.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "lists",
                slug: {
                  _type: "slug",
                  current: "lists",
                },
              },
              {
                _key: "bf487b682efb",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "88dcfccdae2c",
                    _type: "block",
                    children: [
                      {
                        _key: "bb75fc7ea4980",
                        _type: "span",
                        marks: [
                        ],
                        text: "'For' loops can iterate over characters in a string:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "c1e4e27a32c2",
                    _type: "python",
                    main: "from microbit import *\n \n \nfor letter in 'micro:bit':\n    display.scroll(letter)",
                  },
                  {
                    _key: "ac20b4280e8b",
                    _type: "block",
                    children: [
                      {
                        _key: "828221ab5f450",
                        _type: "span",
                        marks: [
                        ],
                        text: "In this example ",
                      },
                      {
                        _key: "b833267b48b0",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "letter",
                      },
                      {
                        _key: "ca37ddfe168d",
                        _type: "span",
                        marks: [
                        ],
                        text: " is a variable name. You can use any valid variable name.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "strings",
                slug: {
                  _type: "slug",
                  current: "strings",
                },
              },
            ],
            alternativesLabel: "Select 'for' loop:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "69d80317594f",
                _type: "block",
                children: [
                  {
                    _key: "3eecb3938a270",
                    _type: "span",
                    marks: [
                    ],
                    text: "‘For’ loops are count-controlled loops. They repeat sections of code a fixed number of times.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "For loops",
            slug: {
              _type: "slug",
              current: "for-loops",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "41931367cb0c",
                _type: "block",
                children: [
                  {
                    _key: "9456b32ef66c",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can put loops inside loops. This is called ‘nesting’.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "74f106d46dd3",
                _type: "python",
                main: "from microbit import *\n\n\nfor y in range(5):\n    for x in range(5):\n        display.set_pixel(x, y, 9)\n        sleep(50)",
              },
            ],
            detailContent: [
              {
                _key: "dc1771e2d956",
                _type: "block",
                children: [
                  {
                    _key: "a77fe7980273",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example uses a nested loop to light the LED display one pixel at a time by iterating through the rows (y) and the columns (x) inside each row.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Nested loops",
            slug: {
              _type: "slug",
              current: "nested-loops",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "fe8b72d534fa",
                _type: "block",
                children: [
                  {
                    _key: "756274f86c640",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can break out of a loop using ",
                  },
                  {
                    _key: "120d7fe934cf",
                    _type: "span",
                    marks: [
                      "c4920e4df55e",
                    ],
                    text: "logic",
                  },
                  {
                    _key: "5b014da883be",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "eefe5aa697fb",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "break",
                  },
                  {
                    _key: "4579bffcf7b5",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                  {
                    _key: "c4920e4df55e",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "a349ff8d-2c59-439a-9e46-f523e8ae3463",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "logic",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
              {
                _key: "de3060fce33d",
                _type: "python",
                main: "from microbit import *\n \nfor letter in 'micro:bit':\n    if letter == 'b':\n        break\n    display.show(letter)\n    sleep(1000)",
              },
            ],
            detailContent: [
              {
                _key: "661a15660fa9",
                _type: "block",
                children: [
                  {
                    _key: "490d6bb26c720",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example shows ‘micro:’ on the LED display. The program breaks out of the loop when it reaches the letter ‘b’ in ‘micro:bit.’",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Breaking out of loops",
            slug: {
              _type: "slug",
              current: "breaking-out-of-loops",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-6d99c00b417dd09b9315ec8341c7bcc71943f165-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "6e4fb6c3f4cf",
            _type: "block",
            children: [
              {
                _key: "5c54dea423cb",
                _type: "span",
                marks: [
                ],
                text: "Loops repeat sections of code. Using loops can also be called ‘iteration’ or 'repetition.'",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Loops",
        slug: {
          _type: "slug",
          current: "loops",
        },
        subtitle: "Count and repeat sets of instructions",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "7a43f80cc3c7",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "8c8762d14166",
                    _type: "block",
                    children: [
                      {
                        _key: "1a2ad6835da50",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "e0b25da0208f",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "if",
                      },
                      {
                        _key: "f1277b686567",
                        _type: "span",
                        marks: [
                        ],
                        text: " to test if something is true:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "64c8791579d6",
                    _type: "python",
                    main: "from microbit import *\n \nscore = 1\n \nif score < 2:\n    display.show(Image.SAD)",
                  },
                  {
                    _key: "da3b61343981",
                    _type: "block",
                    children: [
                      {
                        _key: "e2aa4c636c880",
                        _type: "span",
                        marks: [
                        ],
                        text: "This shows a sad face because the score is less than 2.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "if",
                slug: {
                  _type: "slug",
                  current: "if",
                },
              },
              {
                _key: "c601a2a98416",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "fc005071cc3f",
                    _type: "block",
                    children: [
                      {
                        _key: "b589581f32200",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "elif",
                      },
                      {
                        _key: "e25e09c37b51",
                        _type: "span",
                        marks: [
                        ],
                        text: " is short for ‘else if.’ Use it to add another test to your ",
                      },
                      {
                        _key: "6413be9d6157",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "if",
                      },
                      {
                        _key: "730aa21fc55f",
                        _type: "span",
                        marks: [
                        ],
                        text: " statement:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "45fdeb4f0ec9",
                    _type: "python",
                    main: "from microbit import *\n \nscore = 5\n \nif score < 2:\n    display.show(Image.SAD)\nelif score < 6:\n    display.show(Image.MEH)",
                  },
                  {
                    _key: "d2743dec5f60",
                    _type: "block",
                    children: [
                      {
                        _key: "793aa8939ce70",
                        _type: "span",
                        marks: [
                        ],
                        text: "This shows a ‘meh’ face because the score is less than 6 but more than 1.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "elif (else if)",
                slug: {
                  _type: "slug",
                  current: "elif-else-if",
                },
              },
              {
                _key: "3587cfbb551f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "1c9d785a2fa5",
                    _type: "block",
                    children: [
                      {
                        _key: "486faf7b34c30",
                        _type: "span",
                        marks: [
                        ],
                        text: "Add ‘else’ to catch any other possibilities:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "fb49c8b150ad",
                    _type: "python",
                    main: "from microbit import *\n \nscore = 6\n \nif score < 2:\n    display.show(Image.SAD)\nelif score < 6:\n    display.show(Image.MEH)\nelse:\n    display.show(Image.HAPPY)",
                  },
                  {
                    _key: "1e40262e5cc3",
                    _type: "block",
                    children: [
                      {
                        _key: "ce76e07eb4ca0",
                        _type: "span",
                        marks: [
                        ],
                        text: "This shows a happy face for any score 6 or higher.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "else",
                slug: {
                  _type: "slug",
                  current: "else",
                },
              },
            ],
            alternativesLabel: "Select conditional statment:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            detailContent: [
              {
                _key: "5d3679ad3e05",
                _type: "block",
                children: [
                  {
                    _key: "6ba02ba91dd50",
                    _type: "span",
                    marks: [
                    ],
                    text: "Python uses indentations to tell the computer exactly what instructions should be carried out in each case, similar to the way it uses indentations to show which lines of code are in a ",
                  },
                  {
                    _key: "3dcd6a8d3c54",
                    _type: "span",
                    marks: [
                      "25dbc3120e7c",
                    ],
                    text: "loop",
                  },
                  {
                    _key: "04c670323d20",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                  {
                    _key: "25dbc3120e7c",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "c12b474c-848e-4ddd-9d9b-fa3e9b3bbb99",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "loops",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
            ],
            name: "if, elif, else",
            slug: {
              _type: "slug",
              current: "if-elif-else",
            },
          },
          {
            alternatives: [
              {
                _key: "7c5a7c78c5d7",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2db1938bb232",
                    _type: "python",
                    main: "from microbit import *\n\n\nnumber = 5\nif number == 5:\n    display.scroll('Number is 5')",
                  },
                ],
                name: "equal to",
                slug: {
                  _type: "slug",
                  current: "equal-to",
                },
              },
              {
                _key: "005e88e210a8",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "0889f03d6ad0",
                    _type: "python",
                    main: "from microbit import *\n\n\nnumber = 4\nif number < 5:\n    display.scroll('Number is less than 5')",
                  },
                ],
                name: "less than",
                slug: {
                  _type: "slug",
                  current: "less-than",
                },
              },
              {
                _key: "f267052b5cc7",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "600190f32b98",
                    _type: "python",
                    main: "from microbit import *\n\n\nnumber = 6\nif number > 5:\n    display.scroll('Number is greater than 5')",
                  },
                ],
                name: "greater than",
                slug: {
                  _type: "slug",
                  current: "greater-than",
                },
              },
              {
                _key: "88039eb74804",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6fd9e09daeb0",
                    _type: "python",
                    main: "from microbit import *\n\n\nnumber = 5\nif number <= 5:\n    display.scroll('Number is less than or equal to 5')",
                  },
                ],
                name: "less than or equal to",
                slug: {
                  _type: "slug",
                  current: "less-than-or-equal-to",
                },
              },
              {
                _key: "2093868841a2",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f26422a0037e",
                    _type: "python",
                    main: "from microbit import *\n\n\nnumber = 5\nif number >= 5:\n    display.scroll('Number is greater than or equal to 5')",
                  },
                ],
                name: "greater than or equal to",
                slug: {
                  _type: "slug",
                  current: "greater-than-or-equal-to",
                },
              },
              {
                _key: "bac2002e872d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "111f0ba1e8ec",
                    _type: "python",
                    main: "from microbit import *\n\n\nnumber = 23\nif number != 5:\n    display.scroll('Number is not equal to 5')",
                  },
                ],
                name: "not equal to",
                slug: {
                  _type: "slug",
                  current: "not-equal-to",
                },
              },
            ],
            alternativesLabel: "Select an operator:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "8b414bc06bce",
                _type: "block",
                children: [
                  {
                    _key: "a989930d960a",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can compare numbers to make different things happen if they are equal, smaller, larger or not equal to something.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "eb2a67751852",
                _type: "block",
                children: [
                  {
                    _key: "55efd3765832",
                    _type: "span",
                    marks: [
                    ],
                    text: "Remember one equals sign is used to ",
                  },
                  {
                    _key: "ec211805fc53",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "assign",
                  },
                  {
                    _key: "3d1faf23a32a",
                    _type: "span",
                    marks: [
                    ],
                    text: " the value of a variable:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "fdc6899ab5ea",
                _type: "block",
                children: [
                  {
                    _key: "3a42ada50ecd",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number = 5",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7e0f8457d81a",
                _type: "block",
                children: [
                  {
                    _key: "3c3154ab749c",
                    _type: "span",
                    marks: [
                    ],
                    text: "but when ",
                  },
                  {
                    _key: "038568eb5591",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "testing",
                  },
                  {
                    _key: "a61f3f80ba21",
                    _type: "span",
                    marks: [
                    ],
                    text: " the value, the ‘is equals to’ operator uses two equals signs:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "879c024b6fc0",
                _type: "block",
                children: [
                  {
                    _key: "6e98993f3d5a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "if number == 5:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Comparison operators",
            slug: {
              _type: "slug",
              current: "comparison-operators",
            },
          },
          {
            alternatives: [
              {
                _key: "9767806ca253",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "61e0409d2bfc",
                    _type: "block",
                    children: [
                      {
                        _key: "707bf87fe19f0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "cdce4cc1fed6",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "and",
                      },
                      {
                        _key: "7517bc633fab",
                        _type: "span",
                        marks: [
                        ],
                        text: " to build conditions which are True if more than one thing is True. In this example, 'A+B' is shown only if button A ",
                      },
                      {
                        _key: "707bf87fe19f3",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "and",
                      },
                      {
                        _key: "707bf87fe19f4",
                        _type: "span",
                        marks: [
                        ],
                        text: " button B are pressed at the same time:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "d3946b705b80",
                    _type: "python",
                    main: "from microbit import *\n \n \nwhile True:\n    if button_a.is_pressed() and button_b.is_pressed():\n        display.scroll('A+B')\n",
                  },
                ],
                name: "and",
                slug: {
                  _type: "slug",
                  current: "and",
                },
              },
              {
                _key: "676ecc520f0c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "14dd521ec0c8",
                    _type: "block",
                    children: [
                      {
                        _key: "04d1c45546480",
                        _type: "span",
                        marks: [
                        ],
                        text: "This conditional will show 'A or B' if ",
                      },
                      {
                        _key: "04d1c45546481",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "either",
                      },
                      {
                        _key: "04d1c45546482",
                        _type: "span",
                        marks: [
                        ],
                        text: " button A ",
                      },
                      {
                        _key: "04d1c45546483",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "or",
                      },
                      {
                        _key: "04d1c45546484",
                        _type: "span",
                        marks: [
                        ],
                        text: " button B is pressed:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "b90135d0e8b2",
                    _type: "python",
                    main: "from microbit import *\n \n \nwhile True:\n    if button_a.is_pressed() or button_b.is_pressed():\n        display.scroll('A or B')",
                  },
                ],
                name: "or",
                slug: {
                  _type: "slug",
                  current: "or",
                },
              },
              {
                _key: "85233890f671",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "5beb6312a6c1",
                    _type: "block",
                    children: [
                      {
                        _key: "ee59013f4a320",
                        _type: "span",
                        marks: [
                        ],
                        text: "This conditional will show 'A not B' if button A is pressed but ",
                      },
                      {
                        _key: "ee59013f4a321",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "not",
                      },
                      {
                        _key: "ee59013f4a322",
                        _type: "span",
                        marks: [
                        ],
                        text: " button B:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "3b7342e8430b",
                    _type: "python",
                    main: "from microbit import *\n \n \nwhile True:\n    if button_a.is_pressed() and not button_b.is_pressed():\n        display.scroll('A not B')\n    sleep(200)",
                  },
                ],
                name: "not",
                slug: {
                  _type: "slug",
                  current: "not",
                },
              },
            ],
            alternativesLabel: "Select logical operator:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "2e7bc0c28b11",
                _type: "block",
                children: [
                  {
                    _key: "0a36efeee7b1",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can build more complex conditions using ",
                  },
                  {
                    _key: "b229f6ea5b4c",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "and",
                  },
                  {
                    _key: "b8f4d252e360",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "7b7e26d2520a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "or",
                  },
                  {
                    _key: "3174aa4e7273",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "940e595ac2a5",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "not.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Logical operators",
            slug: {
              _type: "slug",
              current: "logical-operators",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "a77a3f7b4123",
                _type: "block",
                children: [
                  {
                    _key: "9337ad300594",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can put ",
                  },
                  {
                    _key: "1c593a2d4c8a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "if",
                  },
                  {
                    _key: "bce1f8822d9d",
                    _type: "span",
                    marks: [
                    ],
                    text: " statements inside each other. This is called ‘nesting’.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "e7bff373ab98",
                _type: "python",
                main: "from microbit import *\n\n\nscore = 10\n \nif score > 5:\n    display.scroll('Well done')\n    if score > 9:\n        display.scroll('Top marks!')",
              },
            ],
            detailContent: [
              {
                _key: "aa2f1913f809",
                _type: "block",
                children: [
                  {
                    _key: "4eda1fc109a40",
                    _type: "span",
                    marks: [
                    ],
                    text: "This shows ‘Well done’ and then ‘Top marks!’ on the LED display.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Nested if",
            slug: {
              _type: "slug",
              current: "nested-if",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "e843c100bdcd",
                _type: "block",
                children: [
                  {
                    _key: "9ea51c9f60c2",
                    _type: "span",
                    marks: [
                    ],
                    text: "Boolean variables can only have two values: ",
                  },
                  {
                    _key: "2c0533573e5d",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "426447c85bed",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "4215f3bb53d2",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "43b548786320",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "26dea3242c76",
                _type: "block",
                children: [
                  {
                    _key: "2b2d921911ba",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use Boolean variables in ",
                  },
                  {
                    _key: "17490f1b78e6",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "if",
                  },
                  {
                    _key: "03b737eecacf",
                    _type: "span",
                    marks: [
                    ],
                    text: " statements to write code that’s easy to read and understand.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1d2511914c3c",
                _type: "python",
                main: "from microbit import *\n\n\non = False\nwhile True:\n    if button_a.was_pressed():\n        on = not on\n    if on:\n        display.show(1)\n    else:\n        display.show(0)",
              },
            ],
            detailContent: [
              {
                _key: "bfcabd3fc5ac",
                _type: "block",
                children: [
                  {
                    _key: "844d07cc1dc1",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example makes a toggle switch. It uses ",
                  },
                  {
                    _key: "59bafc330158",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "not",
                  },
                  {
                    _key: "56dd967ca6c4",
                    _type: "span",
                    marks: [
                    ],
                    text: " to flip the value of the Boolean variable ",
                  },
                  {
                    _key: "6420c9b6c646",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "on",
                  },
                  {
                    _key: "2b75a8e50049",
                    _type: "span",
                    marks: [
                    ],
                    text: " every time you press button A.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "fd9d0df4250c",
                _type: "block",
                children: [
                  {
                    _key: "f1951bd5b61c",
                    _type: "span",
                    marks: [
                    ],
                    text: "If its value was ",
                  },
                  {
                    _key: "45a4d2dcbf59",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "d7260636f1a8",
                    _type: "span",
                    marks: [
                    ],
                    text: ", it becomes ",
                  },
                  {
                    _key: "bfb5e94712b1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "685e910d9407",
                    _type: "span",
                    marks: [
                    ],
                    text: ". If its value was ",
                  },
                  {
                    _key: "489fca1f9875",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "f3180cb330ef",
                    _type: "span",
                    marks: [
                    ],
                    text: ", it becomes ",
                  },
                  {
                    _key: "4546ac10657a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Conditional Booleans",
            slug: {
              _type: "slug",
              current: "conditional-booleans",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "f6cbd1180f2e",
                _type: "block",
                children: [
                  {
                    _key: "6a62e5078708",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can break out of a ",
                  },
                  {
                    _key: "f02eeb8d2ac2",
                    _type: "span",
                    marks: [
                      "2df65bdaaa8e",
                    ],
                    text: "loop",
                  },
                  {
                    _key: "bf4bdfa2bc59",
                    _type: "span",
                    marks: [
                    ],
                    text: " using conditional statements and ",
                  },
                  {
                    _key: "9989942a9332",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "break",
                  },
                ],
                markDefs: [
                  {
                    _key: "2df65bdaaa8e",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "c12b474c-848e-4ddd-9d9b-fa3e9b3bbb99",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "loops",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
              {
                _key: "6799c8d623ed",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    if button_a.was_pressed():\n        display.show('A')\n    elif button_b.was_pressed():\n        display.show('B')\n    if accelerometer.was_gesture('shake'):\n        break\ndisplay.scroll('Game over')",
              },
            ],
            detailContent: [
              {
                _key: "b26f2df39990",
                _type: "block",
                children: [
                  {
                    _key: "e2733afb2cea0",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example, if you shake the micro:bit, the program breaks out of a ",
                  },
                  {
                    _key: "6ec62aedb175",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "while True",
                  },
                  {
                    _key: "bd284a5249af",
                    _type: "span",
                    marks: [
                    ],
                    text: " loop and the words ‘Game over’ scroll on the display.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Break using logic",
            slug: {
              _type: "slug",
              current: "break-using-logic",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-88ab9a4ee0ad6f9bfa3bacf865b034dc15af2dcc-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "0e3931d4521a",
            _type: "block",
            children: [
              {
                _key: "d3fbcc08b3f9",
                _type: "span",
                marks: [
                ],
                text: "Computers use logic and selection to control what happens in different situations.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Logic",
        slug: {
          _type: "slug",
          current: "logic",
        },
        subtitle: "Making decisions in code",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "d1bdde9c653b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "191faa9cc74c",
                    _type: "block",
                    children: [
                      {
                        _key: "ea971488bd90",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when you shake your micro:bit: ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "883f49fbf8af",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('shake'):\n        display.show(Image.CONFUSED)",
                  },
                ],
                name: "shake",
                slug: {
                  _type: "slug",
                  current: "shake",
                },
              },
              {
                _key: "17b3770935a6",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "15dc6e8c54cf",
                    _type: "block",
                    children: [
                      {
                        _key: "a4340fd4bebf",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the micro:bit is upright with the logo at the top: ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "8e5759a8596c",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('up'):\n        display.show(Image.ARROW_N)",
                  },
                ],
                name: "logo up",
                slug: {
                  _type: "slug",
                  current: "logo-up",
                },
              },
              {
                _key: "e42a5e56010c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "881442a96c0c",
                    _type: "block",
                    children: [
                      {
                        _key: "80a351cba1c6",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the micro:bit is upside down with the logo at the bottom: ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "0301b3597ad5",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('down'):\n        display.show(Image.ARROW_N)",
                  },
                ],
                name: "logo down",
                slug: {
                  _type: "slug",
                  current: "logo-down",
                },
              },
              {
                _key: "7ca10cbf3bf3",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e1bd04eee7b5",
                    _type: "block",
                    children: [
                      {
                        _key: "11255d5c56b9",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the micro:bit is lying flat with the LED display facing up:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "d16baf1cfb22",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('face up'):\n        display.show(Image.HAPPY)",
                  },
                  {
                    _key: "60012a53a7bc",
                    _type: "block",
                    children: [
                      {
                        _key: "172ef1a0d205",
                        _type: "span",
                        marks: [
                        ],
                        text: " ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "face up",
                slug: {
                  _type: "slug",
                  current: "face-up",
                },
              },
              {
                _key: "c6edc281788a",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "211c941b2746",
                    _type: "block",
                    children: [
                      {
                        _key: "4a9532aab637",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when your micro:bit is lying flat with its LED display facing down:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "68b337183abf",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('face down'):\n        display.show(Image.ASLEEP)",
                  },
                ],
                name: "face down",
                slug: {
                  _type: "slug",
                  current: "face-down",
                },
              },
              {
                _key: "14a85ceee958",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "138a94cc0dda",
                    _type: "block",
                    children: [
                      {
                        _key: "6f8610791ab8",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the micro:bit is tilted left or held upright with the logo on the left:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "8734e73c33bd",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('left'):\n        display.show(Image.ARROW_W)",
                  },
                ],
                name: "left",
                slug: {
                  _type: "slug",
                  current: "left",
                },
              },
              {
                _key: "883754d9805e",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "5c690ad829a0",
                    _type: "block",
                    children: [
                      {
                        _key: "f003dacb9a620",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the micro:bit is tilted right or held upright with the logo on the right:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "b818f353d58a",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('right'):\n        display.show(Image.ARROW_E)",
                  },
                ],
                name: "right",
                slug: {
                  _type: "slug",
                  current: "right",
                },
              },
              {
                _key: "c93af2239646",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "38b52a7e8c5a",
                    _type: "block",
                    children: [
                      {
                        _key: "1d35cbb0ec62",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the accelerometer detects it's falling towards the ground:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "aef81586b3ad",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('freefall'):\n        display.show(Image.SURPRISED)",
                  },
                ],
                name: "freefall",
                slug: {
                  _type: "slug",
                  current: "freefall",
                },
              },
              {
                _key: "84277cb6a86b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "cd9e306b0cc3",
                    _type: "block",
                    children: [
                      {
                        _key: "571b31d48b72",
                        _type: "span",
                        marks: [
                        ],
                        text: "Triggered when the accelerometer detects acceleration of around 3g: ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "39d3eb5ca280",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if accelerometer.was_gesture('3g'):\n        display.show(3)",
                  },
                ],
                name: "3g",
                slug: {
                  _type: "slug",
                  current: "3g",
                },
              },
            ],
            alternativesLabel: "Select gesture:",
            compatibility: [
              "microbitV1",
            ],
            content: [
              {
                _key: "fa51de3263fa",
                _type: "block",
                children: [
                  {
                    _key: "f5131554191c",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use gestures in your program to detect when your micro:bit is moved in different ways.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "b20e83432314",
                _type: "block",
                children: [
                  {
                    _key: "07184fbf04840",
                    _type: "span",
                    marks: [
                    ],
                    text: "1g is the equivalent to the pull of gravity. 3g and above are the kinds of acceleration experienced by racing car drivers or astronauts leaving Earth. It’s hard to generate accelerations above 3g in a classroom.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "bf03e3a4d316",
                _type: "block",
                children: [
                  {
                    _key: "393c5a664e550",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "f7ed9fb4dd97",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "was_gesture",
                  },
                  {
                    _key: "3c52fa65748d",
                    _type: "span",
                    marks: [
                    ],
                    text: " to test if a gesture has occurred at any point since the last time you tested it.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1e79ac6b20b4",
                _type: "block",
                children: [
                  {
                    _key: "b886a82775dc0",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can also use ",
                  },
                  {
                    _key: "90be57a06921",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "is_gesture",
                  },
                  {
                    _key: "fd34d0f66178",
                    _type: "span",
                    marks: [
                    ],
                    text: " to see if a gesture is currently active.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Gestures",
            slug: {
              _type: "slug",
              current: "gestures",
            },
          },
          {
            alternatives: [
              {
                _key: "bc8359a91e4f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b05c981a820e",
                    _type: "python",
                    main: "from microbit import *\n\nx_strength = accelerometer.get_x()\ndisplay.scroll(x_strength)",
                  },
                ],
                name: "x",
                slug: {
                  _type: "slug",
                  current: "x",
                },
              },
              {
                _key: "d564feb7e22d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "1bf1ba9b868c",
                    _type: "python",
                    main: "from microbit import *\n\ny_strength = accelerometer.get_y()\ndisplay.scroll(y_strength)",
                  },
                ],
                name: "y",
                slug: {
                  _type: "slug",
                  current: "y",
                },
              },
              {
                _key: "269a4c6003c5",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "56613edbc435",
                    _type: "python",
                    main: "from microbit import *\n\nz_strength = accelerometer.get_z()\ndisplay.scroll(z_strength)",
                  },
                ],
                name: "z",
                slug: {
                  _type: "slug",
                  current: "z",
                },
              },
            ],
            alternativesLabel: "Select dimension:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "6281ee9bba5f",
                _type: "block",
                children: [
                  {
                    _key: "1895be130915",
                    _type: "span",
                    marks: [
                    ],
                    text: "The accelerometer can read the strength of acceleration in 3 dimensions: x, y and z.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a6751e4f9bb1",
                _type: "simpleImage",
                alt: "micro:bit showing X axis going across the front, Y axis going down and up, Z axis going back to front",
                asset: {
                  _ref: "image-27191bef107a45c1fc9848c3222464505ac1d993-847x804-png",
                  _type: "reference",
                },
              },
            ],
            detailContent: [
              {
                _key: "69d457ff98f1",
                _type: "block",
                children: [
                  {
                    _key: "5932e859ec2d",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit measures acceleration in milli-g units, one thousandths of the Earth’s gravity.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7ca8bb413592",
                _type: "block",
                children: [
                  {
                    _key: "b6b9fe41c30e",
                    _type: "span",
                    marks: [
                    ],
                    text: "When the micro:bit is lying flat on a table, you should see that the x and y readings are close to 0, but the z reading will be about -1000. This is because the accelerometer is measuring gravity.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a2af027b862c",
                _type: "block",
                children: [
                  {
                    _key: "42cb272a2f01",
                    _type: "span",
                    marks: [
                    ],
                    text: "The minimum and maximum readings you can get from the accelerometer are about -2000 or +2000 milli-g.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Measuring acceleration",
            slug: {
              _type: "slug",
              current: "measuring-acceleration",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-547e99629fe641c82c339c2d739cd4f641e4aaa1-144x144-svg",
            _type: "reference",
          },
        },
        name: "Accelerometer",
        slug: {
          _type: "slug",
          current: "accelerometer",
        },
        subtitle: "Detect gestures and movement",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "73b965eac856",
                _type: "block",
                children: [
                  {
                    _key: "973b68d00a89",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can add comment by putting a # symbol at the start of a line. (On a Mac, press ",
                  },
                  {
                    _key: "f29d7c8fd747",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "Option",
                  },
                  {
                    _key: "b8c75e7de387",
                    _type: "span",
                    marks: [
                    ],
                    text: "+",
                  },
                  {
                    _key: "947c52f4e68b",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "3",
                  },
                  {
                    _key: "b87d5e62cfcc",
                    _type: "span",
                    marks: [
                    ],
                    text: " to type a # symbol.)",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b16806ac0f76",
                _type: "python",
                main: "# function to convert centigrade to Fahrenheit\ndef convertCtoF(c):\n    return c * 1.8 + 32",
              },
            ],
            detailContent: [
              {
                _key: "72393c067f02",
                _type: "block",
                children: [
                  {
                    _key: "1db8fc312307",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can also use a keyboard shortcut ",
                  },
                  {
                    _key: "12920e946bef",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "Ctrl",
                  },
                  {
                    _key: "3360e033fa6b",
                    _type: "span",
                    marks: [
                    ],
                    text: "+",
                  },
                  {
                    _key: "98bb3b8b35c1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "/",
                  },
                  {
                    _key: "0f50602023b8",
                    _type: "span",
                    marks: [
                    ],
                    text: " (",
                  },
                  {
                    _key: "fba13659be95",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "Cmd",
                  },
                  {
                    _key: "3ea6a0fd4581",
                    _type: "span",
                    marks: [
                    ],
                    text: "+",
                  },
                  {
                    _key: "507a4edf0f90",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "/",
                  },
                  {
                    _key: "204c3e0967cf",
                    _type: "span",
                    marks: [
                    ],
                    text: " on a Mac) to comment any lines you have highlighted. The same shortcut removes comments.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "89224e5331ac",
                _type: "block",
                children: [
                  {
                    _key: "1d8c4fcd4d89",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can also add comments at the end of a line:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "e38e957ab2e4",
                _type: "python",
                main: "def convertCtoF(c):\n    return c * 1.8 + 32 # formula to convert centigrade to Fahrenheit",
              },
            ],
            name: "Add and remove comments",
            slug: {
              _type: "slug",
              current: "add-and-remove-comments",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-f903541079813df0bb29b329319a449938319bd4-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "df3fa984e856",
            _type: "block",
            children: [
              {
                _key: "e24228e99923",
                _type: "span",
                marks: [
                ],
                text: "Comments help other people understand and modify your code. They’re useful if you come back to your code a long time after you wrote it. You can also use the # sign to temporarily stop a line from being run when you’re debugging.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Comments",
        slug: {
          _type: "slug",
          current: "comments",
        },
        subtitle: "Explain your Python code",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "da9e167ea157",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a91ae4fe0217",
                    _type: "python",
                    main: "a = 22 + 7",
                  },
                ],
                name: "addition",
                slug: {
                  _type: "slug",
                  current: "addition",
                },
              },
              {
                _key: "0c761358321d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "ea56acfb7966",
                    _type: "python",
                    main: "a = 22 - 7",
                  },
                ],
                name: "subtraction",
                slug: {
                  _type: "slug",
                  current: "subtraction",
                },
              },
              {
                _key: "906af7b145db",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2e4ef414d4be",
                    _type: "python",
                    main: "a = 22 * 7",
                  },
                ],
                name: "multiplication",
                slug: {
                  _type: "slug",
                  current: "multiplication",
                },
              },
              {
                _key: "5a4cfe4a2e94",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b3af8621eb7b",
                    _type: "python",
                    main: "a = 22 / 7",
                  },
                ],
                name: "division",
                slug: {
                  _type: "slug",
                  current: "division",
                },
              },
              {
                _key: "0e15ceb3921c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "cb8dca30cb20",
                    _type: "python",
                    main: "a = 2 ** 8",
                  },
                  {
                    _key: "ca3c755c55c0",
                    _type: "block",
                    children: [
                      {
                        _key: "be6ffadd2d470",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will raise the base number 2 to the exponent (power) 8. The variable ",
                      },
                      {
                        _key: "e62fa3440cf7",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "a",
                      },
                      {
                        _key: "0a927985d9bd",
                        _type: "span",
                        marks: [
                        ],
                        text: " will have the value 256.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "exponent",
                slug: {
                  _type: "slug",
                  current: "exponent",
                },
              },
              {
                _key: "bcfcbdb1aa53",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f7acda37a440",
                    _type: "block",
                    children: [
                      {
                        _key: "39b4ab5a7b4d0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Modular arithmetic shows the remainder of a number when divided.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "8ffe50debc79",
                    _type: "python",
                    main: "from microbit import *\n\nhour = 15 % 12",
                  },
                  {
                    _key: "22881a9c62d4",
                    _type: "block",
                    children: [
                      {
                        _key: "eebbd8446ebf0",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example uses the modulo operator ",
                      },
                      {
                        _key: "facf97d03da6",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "% ",
                      },
                      {
                        _key: "dc7cddb5e095",
                        _type: "span",
                        marks: [
                        ],
                        text: "to convert 24 hour clock to 12 hour clock by dividing 15 by 12 and using only the remainder.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "de6bb6630123",
                    _type: "block",
                    children: [
                      {
                        _key: "28abb8d9f4030",
                        _type: "span",
                        marks: [
                        ],
                        text: "15 divided by 12 is 1 remainder 3. The ",
                      },
                      {
                        _key: "800cebbe537f",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "hour",
                      },
                      {
                        _key: "2873d860b100",
                        _type: "span",
                        marks: [
                        ],
                        text: " variable has the value 3.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "modulo",
                slug: {
                  _type: "slug",
                  current: "modulo",
                },
              },
              {
                _key: "a78e9ab0c723",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "9337e42b5f7c",
                    _type: "block",
                    children: [
                      {
                        _key: "4e18a41e63c40",
                        _type: "span",
                        marks: [
                        ],
                        text: "The ",
                      },
                      {
                        _key: "7721e57b972c",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "//",
                      },
                      {
                        _key: "35c6316f3e60",
                        _type: "span",
                        marks: [
                        ],
                        text: " operator divides two numbers and always rounds down the result:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "aff803a71b8c",
                    _type: "python",
                    main: "from microbit import *\n \n \na = 10 // 6",
                  },
                  {
                    _key: "92a5fb2cebd0",
                    _type: "block",
                    children: [
                      {
                        _key: "0633a685cbf40",
                        _type: "span",
                        marks: [
                        ],
                        text: "10 divided by 6 is 1.6666, but this code will assign the value 1 to the variable ",
                      },
                      {
                        _key: "e45edbc26e78",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "a",
                      },
                      {
                        _key: "249779429d70",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: " ",
                      },
                      {
                        _key: "0633a685cbf42",
                        _type: "span",
                        marks: [
                        ],
                        text: "because it rounds down.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "fc7c7ddd9bd8",
                    _type: "block",
                    children: [
                      {
                        _key: "148648557945",
                        _type: "span",
                        marks: [
                        ],
                        text: "Floor division relates to modular arithmetic. Whereas the modulo operator ",
                      },
                      {
                        _key: "d5a682d5fbf3",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "%",
                      },
                      {
                        _key: "c20ee07ce3d8",
                        _type: "span",
                        marks: [
                        ],
                        text: " gives the remainder of a number as a whole number (integer) when divided, you can use floor division to give you everything ",
                      },
                      {
                        _key: "e34c399eb5ab1",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "except",
                      },
                      {
                        _key: "e34c399eb5ab2",
                        _type: "span",
                        marks: [
                        ],
                        text: " the remainder as a whole number - this is the quotient.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "floor division",
                slug: {
                  _type: "slug",
                  current: "floor-division",
                },
              },
            ],
            alternativesLabel: "Select operation:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            name: "Mathematical operators",
            slug: {
              _type: "slug",
              current: "mathematical-operators",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "9e81250b5348",
                _type: "block",
                children: [
                  {
                    _key: "f35e6054bdc4",
                    _type: "span",
                    marks: [
                    ],
                    text: "You must add ",
                  },
                  {
                    _key: "f95a78d04bd3",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "import random",
                  },
                  {
                    _key: "5ce7a4656da1",
                    _type: "span",
                    marks: [
                    ],
                    text: " near the start of your program. This will show a random number between 1 and 6:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "28dca1743eb7",
                _type: "python",
                main: "from microbit import *\nimport random\n\n\ndisplay.show(random.randint(1, 6))",
              },
            ],
            detailContent: [
              {
                _key: "e022794fe296",
                _type: "block",
                children: [
                  {
                    _key: "85a637632f17",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use ",
                  },
                  {
                    _key: "3713a3732666",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "random.choice()",
                  },
                  {
                    _key: "42edcda80e90",
                    _type: "span",
                    marks: [
                    ],
                    text: " to pick a random item from a list:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3e3acc55332c",
                _type: "python",
                main: "from microbit import *\nimport random\n\n\nhands = ['rock', 'paper', 'scissors']\ndisplay.scroll(random.choice(hands))",
              },
              {
                _key: "5d60af186258",
                _type: "block",
                children: [
                  {
                    _key: "0d52d2243626",
                    _type: "span",
                    marks: [
                    ],
                    text: "See ",
                  },
                  {
                    _key: "5e47c7b62ae3",
                    _type: "span",
                    marks: [
                      "6d5c86d33bd8",
                    ],
                    text: "Lists",
                  },
                  {
                    _key: "1cbe8011aa9f",
                    _type: "span",
                    marks: [
                    ],
                    text: " to learn more about using lists in Python.",
                  },
                ],
                markDefs: [
                  {
                    _key: "6d5c86d33bd8",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "47cd8e43-0c5d-422d-867c-5cb80ee643d3",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "lists-arrays",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
            ],
            name: "Random numbers",
            slug: {
              _type: "slug",
              current: "random-numbers",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "7851a26a73fb",
                _type: "block",
                children: [
                  {
                    _key: "81780ca2708c",
                    _type: "span",
                    marks: [
                    ],
                    text: "Mathematical operations are not calculated in order from left to right. Some operations take precedence over others. In Python, operations are carried out in this order:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "8d0d90b69cd1",
                _type: "block",
                children: [
                  {
                    _key: "c1e588366c8a",
                    _type: "span",
                    marks: [
                    ],
                    text: "Brackets (parentheses) ",
                  },
                  {
                    _key: "a91d6a828848",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "()",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9d9c3fe2a1df",
                _type: "block",
                children: [
                  {
                    _key: "50a52ec27c2b0",
                    _type: "span",
                    marks: [
                    ],
                    text: "Exponentials (raise to the power of) ",
                  },
                  {
                    _key: "51f4a2d97619",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "**",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "80f702baba97",
                _type: "block",
                children: [
                  {
                    _key: "434ad2eca9910",
                    _type: "span",
                    marks: [
                    ],
                    text: "Multiplication ",
                  },
                  {
                    _key: "e2926983b019",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "*",
                  },
                  {
                    _key: "e5f991eaf8f2",
                    _type: "span",
                    marks: [
                    ],
                    text: ", division ",
                  },
                  {
                    _key: "a6fbaf9bca85",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "/",
                  },
                  {
                    _key: "6e2d0a50da45",
                    _type: "span",
                    marks: [
                    ],
                    text: ", modulo (remainder) ",
                  },
                  {
                    _key: "c1e2b3bf6601",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "%",
                  },
                  {
                    _key: "e7f81613f89d",
                    _type: "span",
                    marks: [
                    ],
                    text: ", floor division ",
                  },
                  {
                    _key: "c83e20274505",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "//",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "c6c18b8ea446",
                _type: "block",
                children: [
                  {
                    _key: "b5c557e855180",
                    _type: "span",
                    marks: [
                    ],
                    text: "Addition ",
                  },
                  {
                    _key: "b9daac5d6e44",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "+",
                  },
                  {
                    _key: "8fa907523d68",
                    _type: "span",
                    marks: [
                    ],
                    text: " and subtraction ",
                  },
                  {
                    _key: "79e5bb13bfb2",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "-",
                  },
                ],
                level: 1,
                listItem: "bullet",
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "478251807c4f",
                _type: "block",
                children: [
                  {
                    _key: "d6feea5da7ad",
                    _type: "span",
                    marks: [
                    ],
                    text: "You may know precedence as BODMAS or BIDMAS (brackets, orders or indices, division, multiplication, addition, subtraction).",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3e7381df4ef4",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll(2 + 3 * 4)\ndisplay.scroll((2 + 3) * 4)",
              },
              {
                _key: "f77679a91b74",
                _type: "block",
                children: [
                  {
                    _key: "8b849bd72b65",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example, the first number shown is 14 because the multiplication happens before the addition: 3 is multiplied by 4 to make 12 and then 2 is added.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "74a351967dff",
                _type: "block",
                children: [
                  {
                    _key: "29207e23f5c0",
                    _type: "span",
                    marks: [
                    ],
                    text: "The second number shown is 20. The brackets around ",
                  },
                  {
                    _key: "7a8f63b05c0f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "2 + 3",
                  },
                  {
                    _key: "62c81eeed28e",
                    _type: "span",
                    marks: [
                    ],
                    text: " mean that the addition is done first. 2 + 3 = 5, which is then multiplied by 4 to make 20.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Precedence",
            slug: {
              _type: "slug",
              current: "precedence",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "53faca737888",
                _type: "block",
                children: [
                  {
                    _key: "4192621f6489",
                    _type: "span",
                    marks: [
                    ],
                    text: "The ",
                  },
                  {
                    _key: "99dfadae41eb",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "abs()",
                  },
                  {
                    _key: "d3152fdf946d",
                    _type: "span",
                    marks: [
                    ],
                    text: " function will turn any number, positive or negative into a positive number.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b088a7ca5d4b",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(abs(accelerometer.get_y()))\n",
              },
            ],
            detailContent: [
              {
                _key: "8e9bf1149b89",
                _type: "block",
                children: [
                  {
                    _key: "ca1a347c4165",
                    _type: "span",
                    marks: [
                    ],
                    text: "This is useful if you are only interested in the strength of accelerometer or compass strength readings which can be positive or negative. ",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "f2c7a9645d31",
                _type: "block",
                children: [
                  {
                    _key: "fcea68a846e8",
                    _type: "span",
                    marks: [
                    ],
                    text: "This will show the magnitude of the accelerometer reading in the Y-axis as a positive number, regardless of whether the reading was positive or negative.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Absolute",
            slug: {
              _type: "slug",
              current: "absolute",
            },
          },
          {
            alternatives: [
              {
                _key: "c5b5bce6bc36",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "8441fb7bbe51",
                    _type: "block",
                    children: [
                      {
                        _key: "6893a164d8830",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can use the ",
                      },
                      {
                        _key: "7e9b3c36fc98",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "int()",
                      },
                      {
                        _key: "2cc9cd24ed5d",
                        _type: "span",
                        marks: [
                        ],
                        text: " function to turn a decimal (floating point) number into a whole number (integer). It rounds down. This example shows 4 on the display:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "9586df857b39",
                    _type: "python",
                    main: "from microbit import *\n \ndisplay.scroll(int(4.9))",
                  },
                ],
                name: "integers",
                slug: {
                  _type: "slug",
                  current: "integers",
                },
              },
              {
                _key: "5007a6fdba07",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e4618574df1d",
                    _type: "block",
                    children: [
                      {
                        _key: "36a7e2d4bd980",
                        _type: "span",
                        marks: [
                        ],
                        text: "The ",
                      },
                      {
                        _key: "a095c6d3534d",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "round()",
                      },
                      {
                        _key: "1a4f91b02834",
                        _type: "span",
                        marks: [
                        ],
                        text: " function rounds numbers to a given number of decimal places. If you don’t specify a number of decimal places, it will round to a whole number.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "e3aec02c8760",
                    _type: "block",
                    children: [
                      {
                        _key: "fe1fcf35bf030",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will round 2.317 to two decimal places and show 2.32 on the LED display:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "8f0a788742d3",
                    _type: "python",
                    main: "display.scroll(round(2.317,2))",
                  },
                ],
                name: "decimal places",
                slug: {
                  _type: "slug",
                  current: "decimal-places",
                },
              },
              {
                _key: "3fc12b09a018",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6dbd6232ed95",
                    _type: "block",
                    children: [
                      {
                        _key: "ed1b5287bf5f",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "c2886accb374",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "floor()",
                      },
                      {
                        _key: "536e64b43248",
                        _type: "span",
                        marks: [
                        ],
                        text: " to round down. You must import ",
                      },
                      {
                        _key: "88e57f6a7f86",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "floor",
                      },
                      {
                        _key: "114c0ce33a7b",
                        _type: "span",
                        marks: [
                        ],
                        text: " from the math module near the start of your program. This will round 2.9 down to 2:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "2b560e3ee98f",
                    _type: "python",
                    main: "from microbit import *\nfrom math import floor \n \ndisplay.scroll(floor(2.9))",
                  },
                ],
                name: "floor",
                slug: {
                  _type: "slug",
                  current: "floor",
                },
              },
              {
                _key: "a6dd6823e596da47c2037baf46e80609",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6dbd6232ed95",
                    _type: "block",
                    children: [
                      {
                        _key: "ed1b5287bf5f",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "c2886accb374",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "ceil()",
                      },
                      {
                        _key: "536e64b43248",
                        _type: "span",
                        marks: [
                        ],
                        text: " to round up. You must import ",
                      },
                      {
                        _key: "00262f4f8982",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "ceil",
                      },
                      {
                        _key: "99750d41f192",
                        _type: "span",
                        marks: [
                        ],
                        text: " from the math module near the start of your program. This will round 2.1 up to 3:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "2b560e3ee98f",
                    _type: "python",
                    main: "from microbit import *\nfrom math import ceil \n \ndisplay.scroll(ceil(2.1))",
                  },
                ],
                name: "ceiling",
                slug: {
                  _type: "slug",
                  current: "ceiling",
                },
              },
            ],
            alternativesLabel: "Select rounding type:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            name: " Rounding",
            slug: {
              _type: "slug",
              current: "rounding",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-b6218f0d8d2be6930768fed334f3a7e33ddb977b-144x144-svg",
            _type: "reference",
          },
        },
        name: "Maths",
        slug: {
          _type: "slug",
          current: "maths",
        },
        subtitle: "Basic maths in Python",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "079e8dca5d13",
                _type: "block",
                children: [
                  {
                    _key: "9c21e5783192",
                    _type: "span",
                    marks: [
                    ],
                    text: "Like variables, give a list a name when you create it. The elements in a list are put inside square brackets, and each element is separated by a comma:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6f662aa07530",
                _type: "python",
                main: "numbers = ['zero', 'one', 2]",
              },
            ],
            detailContent: [
              {
                _key: "4b526e53afdf",
                _type: "block",
                children: [
                  {
                    _key: "8408023f5327",
                    _type: "span",
                    marks: [
                    ],
                    text: "Strings must be enclosed in quotation marks.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "4c3ee1b7d6d1",
                _type: "block",
                children: [
                  {
                    _key: "ebaba45a50f4",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can create an empty list which you can add things to later:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "61d23a61b955",
                _type: "python",
                main: "numbers = []",
              },
            ],
            name: "Make a list",
            slug: {
              _type: "slug",
              current: "make-a-list",
            },
          },
          {
            alternatives: [
              {
                _key: "9f06af2ea927",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "fd3339b9ab30",
                    _type: "block",
                    children: [
                      {
                        _key: "2ac8a5ab95da0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Elements in lists can be accessed using a numerical index, starting at 0 for the first item. This will scroll ‘zero’ on the display:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "59d4e0419a3e",
                    _type: "python",
                    main: "from microbit import *\n \n \nnumbers = ['zero', 'one', 2]\ndisplay.scroll(numbers[0])",
                  },
                ],
                name: "by index",
                slug: {
                  _type: "slug",
                  current: "by-index",
                },
              },
              {
                _key: "06284a00ac0e",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "5727bad1bc92",
                    _type: "block",
                    children: [
                      {
                        _key: "4394934fcad60",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can access all the elements of a list in turn, using a ‘for’ loop:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "fa5f49d7bbc2",
                    _type: "python",
                    main: "from microbit import *\n \n \nnumbers = ['zero', 'one', 2]\nfor number in numbers:\n    display.scroll(number)",
                  },
                ],
                name: "iterating",
                slug: {
                  _type: "slug",
                  current: "iterating",
                },
              },
              {
                _key: "237602cc8d27",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6dcd94551670",
                    _type: "block",
                    children: [
                      {
                        _key: "082a19ff13ae0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Import the ",
                      },
                      {
                        _key: "5b62027c5b0d",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "random",
                      },
                      {
                        _key: "38c15dd5384e",
                        _type: "span",
                        marks: [
                        ],
                        text: " module so you can use ",
                      },
                      {
                        _key: "0fe462b95e16",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "random.choice()",
                      },
                      {
                        _key: "bada89d11f53",
                        _type: "span",
                        marks: [
                        ],
                        text: " to retrieve a random element from a list:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "9f721f68905c",
                    _type: "python",
                    main: "from microbit import *\nimport random\n\nhands = ['rock', 'paper', 'scissors']\ndisplay.scroll(random.choice(hands))",
                  },
                ],
                name: "random",
                slug: {
                  _type: "slug",
                  current: "random",
                },
              },
            ],
            alternativesLabel: "Select method:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            name: "Retrieve list element",
            slug: {
              _type: "slug",
              current: "retrieve-list-element",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "22d49559bceb",
                _type: "block",
                children: [
                  {
                    _key: "b68d9fc29efe",
                    _type: "span",
                    marks: [
                    ],
                    text: "This will replace the item at index 1 (the string 'one') with the integer 1:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3ac2767dfdc0",
                _type: "python",
                main: "from microbit import *\n\n\nnumbers = ['zero', 'one', 2]\nnumbers[1] = 1",
              },
            ],
            name: "Change list element",
            slug: {
              _type: "slug",
              current: "change-list-element",
            },
          },
          {
            alternatives: [
              {
                _key: "813ab3c94d8c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "9f986982177c",
                    _type: "block",
                    children: [
                      {
                        _key: "cc515f556561",
                        _type: "span",
                        marks: [
                        ],
                        text: "Add, or append, items to the end of a list like this:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "b95035791762",
                    _type: "python",
                    main: "letters = ['a', 'b', 'c']\nletters.append('d')",
                  },
                ],
                name: "append",
                slug: {
                  _type: "slug",
                  current: "append",
                },
              },
              {
                _key: "fb86197afe5d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "31697ff9f0b4",
                    _type: "block",
                    children: [
                      {
                        _key: "edc8bb6cb3950",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "1ee2aab1d64b",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "insert",
                      },
                      {
                        _key: "da6ff780295d",
                        _type: "span",
                        marks: [
                        ],
                        text: " to add elements at a given point in a list:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "0bde9aba6006",
                    _type: "python",
                    main: "letters = ['a', 'b', 'd']\nletters.insert(2,'c')",
                  },
                  {
                    _key: "eeeb9a61064d",
                    _type: "block",
                    children: [
                      {
                        _key: "50dc036462f20",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will insert the letter 'c' at index 2, the third place in the list when you count from 0.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "insert",
                slug: {
                  _type: "slug",
                  current: "insert",
                },
              },
            ],
            alternativesLabel: "Select method:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            name: "Add element to list",
            slug: {
              _type: "slug",
              current: "add-element-to-list",
            },
          },
          {
            alternatives: [
              {
                _key: "b9118d9e623b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "1f5dcbb072e9",
                    _type: "block",
                    children: [
                      {
                        _key: "b3fa4ee8e858",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can delete items by their position in the list with ",
                      },
                      {
                        _key: "442086dbc372",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "pop()",
                      },
                      {
                        _key: "23cb4e303610",
                        _type: "span",
                        marks: [
                        ],
                        text: ":",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "d73019096ffb",
                    _type: "python",
                    main: "letters = ['a', 'b', 'c', 'd']\nletters.pop(1)",
                  },
                  {
                    _key: "5d3123fb2538",
                    _type: "block",
                    children: [
                      {
                        _key: "23114d89c2b1",
                        _type: "span",
                        marks: [
                        ],
                        text: "This removes the item at index 1, the letter ‘b’, from the list.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "by index (pop)",
                slug: {
                  _type: "slug",
                  current: "by-index-pop",
                },
              },
              {
                _key: "7a4487900565",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "0dfc249d72f1",
                    _type: "block",
                    children: [
                      {
                        _key: "54e7a105fec20",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "ec18acb44d8a",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "remove()",
                      },
                      {
                        _key: "4fbdb06698c7",
                        _type: "span",
                        marks: [
                        ],
                        text: " to delete an element if you know its value but not its position in a list: ",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "c6a6644a67d7",
                    _type: "python",
                    main: "letters = ['a', 'b', 'c', 'd']\nletters.remove('c')\n",
                  },
                  {
                    _key: "b110f6de864f",
                    _type: "block",
                    children: [
                      {
                        _key: "0c24939deb8d",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will remove ‘c’ from any position the list.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "by value (remove)",
                slug: {
                  _type: "slug",
                  current: "by-value-remove",
                },
              },
              {
                _key: "8d3753028c24",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "434a9719e74e",
                    _type: "block",
                    children: [
                      {
                        _key: "59b85d48dac5",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use clear() to delete all elements from a list:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "969aec7a409f",
                    _type: "python",
                    main: "letters = ['a', 'b', 'c', 'd']\nletters.clear()",
                  },
                ],
                name: "clear list",
                slug: {
                  _type: "slug",
                  current: "clear-list",
                },
              },
            ],
            alternativesLabel: "Select method:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            name: "Remove element from list",
            slug: {
              _type: "slug",
              current: "remove-element-from-list",
            },
          },
          {
            alternatives: [
              {
                _key: "403f15f3357d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "4c24d3a512f4",
                    _type: "block",
                    children: [
                      {
                        _key: "d4b84d5cf42a",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "f3caf7181d58",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "sort()",
                      },
                      {
                        _key: "92c3bbf8758e",
                        _type: "span",
                        marks: [
                        ],
                        text: " to sort lists alphanumerically:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "d994995a4520",
                    _type: "python",
                    main: "letters = ['c', 'd', 'b', 'a']\nletters.sort()",
                  },
                  {
                    _key: "d8cc50539e62",
                    _type: "block",
                    children: [
                      {
                        _key: "9cf3516ebb8e",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will sort the list into alphabetical order: 'a', 'b', 'c', 'd'.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "alphanumeric",
                slug: {
                  _type: "slug",
                  current: "alphanumeric",
                },
              },
              {
                _key: "40a1e77a2438",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "52d00425d183",
                    _type: "block",
                    children: [
                      {
                        _key: "ad7a3915111f0",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "4bbc92860224",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "reverse()",
                      },
                      {
                        _key: "3e14223a9855",
                        _type: "span",
                        marks: [
                        ],
                        text: " to reverse the order of a list:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "e6039844aa36",
                    _type: "python",
                    main: "letters = ['a', 'b', 'c', 'd']\nletters.reverse()",
                  },
                  {
                    _key: "46ec4f550f32",
                    _type: "block",
                    children: [
                      {
                        _key: "d1378fff22fd",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will reverse the order of the list to become 'd', 'c', 'b', 'a'.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "reverse",
                slug: {
                  _type: "slug",
                  current: "reverse",
                },
              },
            ],
            alternativesLabel: "Select sort:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            detailContent: [
              {
                _key: "b5cd24a5ca7a",
                _type: "block",
                children: [
                  {
                    _key: "0df2cc8a48c2",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can reverse lists that are made up of different data types, but you can only sort lists alphanumerically that comprise all the same data type, for example all elements must be strings or all integers.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "96416b062c09",
                _type: "block",
                children: [
                  {
                    _key: "9e707895d576",
                    _type: "span",
                    marks: [
                    ],
                    text: "",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Sort list",
            slug: {
              _type: "slug",
              current: "sort-list",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "694457c0f329",
                _type: "block",
                children: [
                  {
                    _key: "ce6bcf166c38",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "5bd14f167e8d",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "len()",
                  },
                  {
                    _key: "c7f1fbeaac2a",
                    _type: "span",
                    marks: [
                    ],
                    text: " to find out how long a list is:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d0cd904253b6",
                _type: "python",
                main: "from microbit import *\n\nnumbers = [5, 7, 0, 5]\ndisplay.scroll(len(numbers))",
              },
              {
                _key: "cfefb9af8442",
                _type: "block",
                children: [
                  {
                    _key: "31b2c265b4ca",
                    _type: "span",
                    marks: [
                    ],
                    text: "This displays 4 because there are 4 elements in the list.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Length of a list",
            slug: {
              _type: "slug",
              current: "length-of-a-list",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "5c77ce2c7099",
                _type: "block",
                children: [
                  {
                    _key: "8b671529ec77",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "3718b8335b79",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "count()",
                  },
                  {
                    _key: "1d09d6453ed5",
                    _type: "span",
                    marks: [
                    ],
                    text: " to count how many times a particular element occurs in a list:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "df37e1af600b",
                _type: "python",
                main: "from microbit import *\n\nnumbers = [5, 7, 0, 5]\ndisplay.scroll(numbers.count(5))",
              },
              {
                _key: "390e1d4e5015",
                _type: "block",
                children: [
                  {
                    _key: "e2032fa13d8a",
                    _type: "span",
                    marks: [
                    ],
                    text: "This displays 2 because 5 occurs twice in the list.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Count occurrences in lists",
            slug: {
              _type: "slug",
              current: "count-occurrences-in-lists",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-e9f0f86026dae06b3d21aefb282614ccb0320823-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "3afd3239f1c3",
            _type: "block",
            children: [
              {
                _key: "d4ca82af01a5",
                _type: "span",
                marks: [
                ],
                text: "Lists, or arrays, are collections of data. They can be made up of variables, numbers, strings, or a mixture of different ",
              },
              {
                _key: "795aaec4fa9e",
                _type: "span",
                marks: [
                  "a8a5b5617fd8",
                ],
                text: "data types",
              },
              {
                _key: "9b0abc64952f",
                _type: "span",
                marks: [
                ],
                text: ".",
              },
            ],
            markDefs: [
              {
                _key: "a8a5b5617fd8",
                _type: "toolkitInternalLink",
                reference: {
                  _ref: "38feee7a-be99-48db-a3ce-c0b366bafa43",
                  _type: "reference",
                },
                slug: {
                  _type: "slug",
                  current: "data-types",
                },
                targetType: "toolkitTopic",
              },
            ],
            style: "normal",
          },
          {
            _key: "d15d95f38118",
            _type: "block",
            children: [
              {
                _key: "ac4a84403482",
                _type: "span",
                marks: [
                ],
                text: "Items in a list are called ",
              },
              {
                _key: "ee3076ab30341",
                _type: "span",
                marks: [
                  "strong",
                ],
                text: "elements.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
          {
            _key: "0a70f1336ba0",
            _type: "block",
            children: [
              {
                _key: "b9bd1f406355",
                _type: "span",
                marks: [
                ],
                text: "The position of an item in a list is its ",
              },
              {
                _key: "99dcc4eacee51",
                _type: "span",
                marks: [
                  "strong",
                ],
                text: "index",
              },
              {
                _key: "99dcc4eacee52",
                _type: "span",
                marks: [
                ],
                text: ". Python starts counting from 0, so the first element in a list is at index 0.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Lists (arrays)",
        slug: {
          _type: "slug",
          current: "lists-arrays",
        },
        subtitle: "Organise data in lists",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "39b68a27ccf3",
                _type: "block",
                children: [
                  {
                    _key: "b97308207153",
                    _type: "span",
                    marks: [
                    ],
                    text: "Procedures, also called sub-routines, are functions that carry out a fixed set of instructions.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ee087fbacfce",
                _type: "python",
                main: "from microbit import *\n\ndef heart_wait():\n    display.show(Image.HEART)\n    sleep(1000)\n\nheart_wait()\ndisplay.clear()",
              },
            ],
            detailContent: [
              {
                _key: "377d54946ca5",
                _type: "block",
                children: [
                  {
                    _key: "b96de5f13db1",
                    _type: "span",
                    marks: [
                    ],
                    text: "This function called",
                  },
                  {
                    _key: "f0d3610877a0",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: " ",
                  },
                  {
                    _key: "2e751054878a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "heart_wait()",
                  },
                  {
                    _key: "c1b51eb5041d",
                    _type: "span",
                    marks: [
                    ],
                    text: " shows a heart on the micro:bit's LED display, then waits 1 second.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Procedures",
            slug: {
              _type: "slug",
              current: "procedures",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "7df59d367afb",
                _type: "block",
                children: [
                  {
                    _key: "ba4598e5c206",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can put data in brackets to pass to a function, which the function can then process.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "954e51d915f3",
                _type: "python",
                main: "from microbit import *\n\ndef image_wait(myImage):\n    display.show(myImage)\n    sleep(1000)\n\nimage_wait(Image.HEART)\nimage_wait(Image.HEART_SMALL)",
              },
            ],
            detailContent: [
              {
                _key: "4b36ca6e50bd",
                _type: "block",
                children: [
                  {
                    _key: "9657308dc892",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example, we define a function called ",
                  },
                  {
                    _key: "a0eaf5e4ff83",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "image_wait()",
                  },
                  {
                    _key: "9466e5197720",
                    _type: "span",
                    marks: [
                    ],
                    text: ". This will show any image we pass to the function.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "73e79a575d0e",
                _type: "block",
                children: [
                  {
                    _key: "62cd74a730ec",
                    _type: "span",
                    marks: [
                    ],
                    text: "Data you pass to a function are called parameters. Parameters make functions more flexible.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "0a955cc6c035",
                _type: "block",
                children: [
                  {
                    _key: "1ac7d6d47dfc",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can send more than one parameter to a function. This function allows you to specify both the image and how long the function waits. The default delay is 1 second, but you can over-ride it by specifying a new time when you call the function:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "e58402640cff",
                _type: "python",
                main: "from microbit import *\n\ndef image_wait(myImage, delay=1000):\n    display.show(myImage)\n    sleep(delay)\n\nimage_wait(Image.HEART)\nimage_wait(Image.HEART_SMALL, delay=3000)\ndisplay.clear()",
              },
            ],
            name: " Functions with parameters",
            slug: {
              _type: "slug",
              current: "functions-with-parameters",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "db745643928f",
                _type: "block",
                children: [
                  {
                    _key: "ef50086038d1",
                    _type: "span",
                    marks: [
                    ],
                    text: "Functions can process information and ",
                  },
                  {
                    _key: "961f14576f0e",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "return",
                  },
                  {
                    _key: "16ff354a7f12",
                    _type: "span",
                    marks: [
                    ],
                    text: " it.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ba9f4bd2283b",
                _type: "python",
                main: "from microbit import *\n\n\ndef convertCtoF(c):\n    return c * 1.8 + 32\n\ndisplay.scroll(convertCtoF(temperature()))",
              },
            ],
            detailContent: [
              {
                _key: "5886383bc883",
                _type: "block",
                children: [
                  {
                    _key: "d0297df1f247",
                    _type: "span",
                    marks: [
                    ],
                    text: "This function takes any number you pass to it, modifies it using the formula to convert centigrade to Fahrenheit, and returns it.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b80831c1d827",
                _type: "block",
                children: [
                  {
                    _key: "4871e25973e8",
                    _type: "span",
                    marks: [
                    ],
                    text: "The function ",
                  },
                  {
                    _key: "ce8c7e4a7c36",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "convertCtoF()",
                  },
                  {
                    _key: "e5f746bafe96",
                    _type: "span",
                    marks: [
                    ],
                    text: " is called inside a ",
                  },
                  {
                    _key: "f13e22f5f686",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "display.scroll()",
                  },
                  {
                    _key: "114b9682f54c",
                    _type: "span",
                    marks: [
                    ],
                    text: " statement. The current temperature in centigrade is passed to the function.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6ba567ad3b29",
                _type: "block",
                children: [
                  {
                    _key: "2d0c80254cf7",
                    _type: "span",
                    marks: [
                    ],
                    text: "‘Function' is a mathematical term describing a relation or expression involving one or more variables, such as F = C x 1.8 + 32.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "0700f73e0d5e",
                _type: "block",
                children: [
                  {
                    _key: "fe79cfb6f2ac",
                    _type: "span",
                    marks: [
                    ],
                    text: "Functions in computer programs work in a similar way, which is why they are good for performing calculations and conversions.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Functions with return",
            slug: {
              _type: "slug",
              current: "functions-with-return",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "4a225834fb71",
                _type: "block",
                children: [
                  {
                    _key: "91f9da9643a4",
                    _type: "span",
                    marks: [
                    ],
                    text: "Variables aren’t always available to all parts of your program. Sometimes this is what you want, but it can cause problems. Variables can be ",
                  },
                  {
                    _key: "c1333d62fac4",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "global",
                  },
                  {
                    _key: "0e21ec45cbbb",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "cc300f1185cb",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "local",
                  },
                  {
                    _key: "5a3691f3badd",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6ebee507faad",
                _type: "block",
                children: [
                  {
                    _key: "fa88c4a86f69",
                    _type: "span",
                    marks: [
                    ],
                    text: "Global variables can be accessed by any part of your program. Local variables only work inside a section of your program such as a function.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Scope of variables",
            slug: {
              _type: "slug",
              current: "scope-of-variables",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "d1c116993562",
                _type: "block",
                children: [
                  {
                    _key: "a8efc4f9e641",
                    _type: "span",
                    marks: [
                    ],
                    text: "If you create a variable outside a function definition, it will be global and can be used by the function.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7d3b9d6ccde8",
                _type: "python",
                main: "from microbit import *\n\n\ndef greeting():\n    display.scroll('Hello ' + name)\n\nname = 'Sam'\ngreeting()",
              },
            ],
            detailContent: [
              {
                _key: "79bbfa382ebb",
                _type: "block",
                children: [
                  {
                    _key: "43b325063b5c0",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example the display shows ‘Hello Sam’.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "57fe05db7f9d",
                _type: "block",
                children: [
                  {
                    _key: "018d213a264d",
                    _type: "span",
                    marks: [
                    ],
                    text: "Although ",
                  },
                  {
                    _key: "71acb10de062",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "name",
                  },
                  {
                    _key: "e936f2a94232",
                    _type: "span",
                    marks: [
                    ],
                    text: " is a global variable, it must be defined before the function ",
                  },
                  {
                    _key: "1e360121af83",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "greeting()",
                  },
                  {
                    _key: "43b325063b5c3",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: " ",
                  },
                  {
                    _key: "43b325063b5c4",
                    _type: "span",
                    marks: [
                    ],
                    text: "is called, or you will get an error.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "73c83d96cbd4",
                _type: "block",
                children: [
                  {
                    _key: "d08c27ccd89c",
                    _type: "span",
                    marks: [
                    ],
                    text: "If you want to modify the value of a ",
                  },
                  {
                    _key: "8d9166286e25",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "global",
                  },
                  {
                    _key: "1c4b478d359e",
                    _type: "span",
                    marks: [
                    ],
                    text: " variable in a function, you must declare that you want to use the global variable of that name using ",
                  },
                  {
                    _key: "5335c3b0e3c9",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "global",
                  },
                  {
                    _key: "b8703df8c3be",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9eca230ae3e4",
                _type: "block",
                children: [
                  {
                    _key: "22d7d4942f27",
                    _type: "span",
                    marks: [
                    ],
                    text: "Here we use the global variable count to track how many times the function has been called:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "1525a1d1b85b",
                _type: "python",
                main: "from microbit import *\n\n\ndef clicker():\n    global count\n    count += 1\n    display.show(count)\n\ncount = 0\nwhile True:\n    if button_a.was_pressed():\n        clicker()",
              },
            ],
            name: "Scope: global variables",
            slug: {
              _type: "slug",
              current: "scope-global-variables",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "0904bfb1fb4d",
                _type: "block",
                children: [
                  {
                    _key: "6f11e775b925",
                    _type: "span",
                    marks: [
                    ],
                    text: "Variables inside functions are assumed to be ",
                  },
                  {
                    _key: "0e1a9d5d2686",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "local",
                  },
                  {
                    _key: "804971e069d1",
                    _type: "span",
                    marks: [
                    ],
                    text: " unless you state otherwise.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "bb7fe26caf9e",
                _type: "python",
                main: "from microbit import *\n\n\ndef count():\n    number = 9\n    display.show(number)\n\nnumber = 5\ncount()\nsleep(1000)\ndisplay.show(number)",
              },
            ],
            detailContent: [
              {
                _key: "5e7a97fbb99c",
                _type: "block",
                children: [
                  {
                    _key: "95c6746534a00",
                    _type: "span",
                    marks: [
                    ],
                    text: "Here we have two instances of a variable called ",
                  },
                  {
                    _key: "af75a0e68b60",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "3205121f1ea9",
                    _type: "span",
                    marks: [
                    ],
                    text: ". Inside the ",
                  },
                  {
                    _key: "a66e7028dad5",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "count()",
                  },
                  {
                    _key: "630d6d475fd7",
                    _type: "span",
                    marks: [
                    ],
                    text: " function ",
                  },
                  {
                    _key: "2723667c492d",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "5fb1559d79a6",
                    _type: "span",
                    marks: [
                    ],
                    text: " is a local variable with a value of 9.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3ada6a8dccb5",
                _type: "block",
                children: [
                  {
                    _key: "657cde9419470",
                    _type: "span",
                    marks: [
                    ],
                    text: "Outside the function there is a ",
                  },
                  {
                    _key: "bd22615fc7cd",
                    _type: "span",
                    marks: [
                      "94be241a7c3a",
                    ],
                    text: "global variable",
                  },
                  {
                    _key: "56697eea6c01",
                    _type: "span",
                    marks: [
                    ],
                    text: " also called ",
                  },
                  {
                    _key: "be0bc192b4c3",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "5c5d3fbb5c12",
                    _type: "span",
                    marks: [
                    ],
                    text: " with a value of 5.",
                  },
                ],
                markDefs: [
                  {
                    _key: "94be241a7c3a",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "b486a595-6422-4526-92e4-d47fc6be98d2",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "scope-global-variables",
                    },
                    targetType: "toolkitTopicEntry",
                  },
                ],
                style: "normal",
              },
              {
                _key: "8b041dc1e071",
                _type: "block",
                children: [
                  {
                    _key: "15165870915e",
                    _type: "span",
                    marks: [
                    ],
                    text: "You might expect that calling the function would change the value of ",
                  },
                  {
                    _key: "527a4536fb69",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "5bc44766cbc1",
                    _type: "span",
                    marks: [
                    ],
                    text: " to 9 and show 9 twice. But in fact this will show 9 and then 5 on the display because we have two different variables both called ",
                  },
                  {
                    _key: "08b5a441dd24",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "d69b98aa4b62",
                    _type: "span",
                    marks: [
                    ],
                    text: ". One is local to the function and has the value 9, the other is global and has the value 5.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Scope: local variables",
            slug: {
              _type: "slug",
              current: "scope-local-variables",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-3fbaaa2548c4e172fe450e5a349ba7b7a9b1cbbc-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "e4e59280d5d5",
            _type: "block",
            children: [
              {
                _key: "60778009a7320",
                _type: "span",
                marks: [
                ],
                text: "Python has many built-in functions like ",
              },
              {
                _key: "ae38710aceae",
                _type: "span",
                marks: [
                  "code",
                ],
                text: "sleep()",
              },
              {
                _key: "a1694e1cd9c5",
                _type: "span",
                marks: [
                ],
                text: " and ",
              },
              {
                _key: "eaf9dc7b7d71",
                _type: "span",
                marks: [
                  "code",
                ],
                text: "int()",
              },
              {
                _key: "2ad065e0bc39",
                _type: "span",
                marks: [
                ],
                text: ", but you can make your own to re-use sections of code in your programs.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
          {
            _key: "cec319049223",
            _type: "block",
            children: [
              {
                _key: "c51e8fd6d83a",
                _type: "span",
                marks: [
                ],
                text: "Functions make code easier to read and modify. Define your function near the start of your program, giving it a descriptive name, then call it using its name followed by round brackets.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Functions",
        slug: {
          _type: "slug",
          current: "functions",
        },
        subtitle: "Make your code more efficient",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "9d940157da6b",
                _type: "block",
                children: [
                  {
                    _key: "2c700b0551bb",
                    _type: "span",
                    marks: [
                    ],
                    text: "First set a radio group so you know your message is going to the right micro:bit or set of micro:bits:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9675fd71eaa8",
                _type: "python",
                main: "import radio\n\n\nradio.config(group=23)",
              },
            ],
            detailContent: [
              {
                _key: "0033c5a49ccd",
                _type: "block",
                children: [
                  {
                    _key: "777efe26eb08",
                    _type: "span",
                    marks: [
                    ],
                    text: "Groups work like radio channels. Any micro:bits using the same group number can share radio messages.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "8ec9a3482e18",
                _type: "block",
                children: [
                  {
                    _key: "77961eaed826",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use any group number between 0 and 255.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Groups",
            slug: {
              _type: "slug",
              current: "groups",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "55e44a7a14f0",
                _type: "block",
                children: [
                  {
                    _key: "6b8a95a61eff",
                    _type: "span",
                    marks: [
                    ],
                    text: "You need to turn the radio on before you use it:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "bb6a362a586d",
                _type: "python",
                main: "import radio\n\n\nradio.on()",
              },
            ],
            detailContent: [
              {
                _key: "613e2f31780c",
                _type: "block",
                children: [
                  {
                    _key: "21596c25255b",
                    _type: "span",
                    marks: [
                    ],
                    text: "Because the radio feature uses more power, you might want to turn it off at certain times to save batteries:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "447a5e4e3e7a",
                _type: "python",
                main: "import radio\n\n\nradio.off()",
              },
            ],
            name: "On and off",
            slug: {
              _type: "slug",
              current: "on-and-off",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "61a70fedb301",
                _type: "block",
                children: [
                  {
                    _key: "dda5f92f8471",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can send strings over radio made up of letters, symbols and numbers:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "02a28f7c2406",
                _type: "python",
                main: "import radio\n\n\nradio.on()\nradio.send('hello')",
              },
            ],
            name: "Send a message",
            slug: {
              _type: "slug",
              current: "send-a-message",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "67d43851f08c",
                _type: "block",
                children: [
                  {
                    _key: "49ca63b6a58f",
                    _type: "span",
                    marks: [
                    ],
                    text: "Because you don’t know when a radio message might be sent, it’s a good idea to keep checking for new messages in a loop. This will show new messages when they arrive:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9b3a6a73f3c4",
                _type: "python",
                main: "from microbit import *\nimport radio\n\n\nradio.on()\nwhile True:\n    message = radio.receive()\n    if message:\n        display.scroll(message)",
              },
            ],
            name: "Receive a message",
            slug: {
              _type: "slug",
              current: "receive-a-message",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "1eb492c86f76",
                _type: "block",
                children: [
                  {
                    _key: "c2245290e5c40",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can optionally set the radio transmit power in a range from 0 to 7:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6a8cdd5f840d",
                _type: "python",
                main: "import radio\n\n\nradio.config(group=23, power=0)",
              },
            ],
            detailContent: [
              {
                _key: "8081ff837a45",
                _type: "block",
                children: [
                  {
                    _key: "dbc198c8fc170",
                    _type: "span",
                    marks: [
                    ],
                    text: "0 is the lowest power setting, 7 is the highest. Radio signals travel further with higher power, but use more energy.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9e6ad3c9d0ac",
                _type: "block",
                children: [
                  {
                    _key: "67dee4064241",
                    _type: "span",
                    marks: [
                    ],
                    text: "You may want to reduce the power to make your batteries last longer, or in a radio treasure hunt game so that you have to get physically close to a micro:bit to pick up its signal.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Transmit power",
            slug: {
              _type: "slug",
              current: "transmit-power",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-9c9c646e17624ab9f91123d44552fc32f2fdbf89-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "1eb3bf50e960",
            _type: "block",
            children: [
              {
                _key: "bca82dcb72a9",
                _type: "span",
                marks: [
                ],
                text: "You can send messages between micro:bits by radio. You need to import the radio module near the start of your program using ",
              },
              {
                _key: "bada0c29d0ab",
                _type: "span",
                marks: [
                  "code",
                ],
                text: "import radio",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Radio",
        slug: {
          _type: "slug",
          current: "radio",
        },
        subtitle: "Send messages between micro:bits",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "64a41741063d",
                _type: "block",
                children: [
                  {
                    _key: "9953ab7404b0",
                    _type: "span",
                    marks: [
                    ],
                    text: "The LEDs on the micro:bit can also act as a light sensor:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "f376dc42c143",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(display.read_light_level())",
              },
            ],
            detailContent: [
              {
                _key: "db33766018f9",
                _type: "block",
                children: [
                  {
                    _key: "825c82082bcc",
                    _type: "span",
                    marks: [
                    ],
                    text: "The strength of light falling on the micro:bit is measured as a number from 0 - 255, with 0 being the darkest and 255 the brightest.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d50cd4004bbe",
                _type: "block",
                children: [
                  {
                    _key: "2df46524b1cc0",
                    _type: "span",
                    marks: [
                    ],
                    text: "This lights up the LED display with a heart when the light level falls below 50:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7f495238c97f",
                _type: "python",
                main: "from microbit import *\n \n \nwhile True:\n    if display.read_light_level() < 50:\n        display.show(Image.HEART)\n    else:\n        display.clear()\n",
              },
              {
                _key: "9787bca354e8",
                _type: "block",
                children: [
                  {
                    _key: "cbc3de39a30b",
                    _type: "span",
                    marks: [
                    ],
                    text: "",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "2b14441ce2af",
                _type: "block",
                children: [
                  {
                    _key: "37320ae2ba4f",
                    _type: "span",
                    marks: [
                    ],
                    text: "",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Light level",
            slug: {
              _type: "slug",
              current: "light-level",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-e27284c6c1598a9b1180a72fbdb3ebf7490144d7-144x144-svg",
            _type: "reference",
          },
        },
        name: "Light level",
        slug: {
          _type: "slug",
          current: "light-level",
        },
        subtitle: "Measure light falling on your micro:bit",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "e53351ceebe8",
                _type: "block",
                children: [
                  {
                    _key: "35b4879eb821",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit has a temperature sensor inside its processor. You can take temperature readings in centigrade like this:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b6c885259f39",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(temperature())",
              },
            ],
            detailContent: [
              {
                _key: "df8f0cc62af6",
                _type: "block",
                children: [
                  {
                    _key: "545807f86f63",
                    _type: "span",
                    marks: [
                    ],
                    text: "Because the processor makes a small amount of heat, readings are normally a bit higher than the temperature in the air around the micro:bit, so you may want to compare readings with a real thermometer and adjust them.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Temperature",
            slug: {
              _type: "slug",
              current: "temperature",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-728914eb6be66372df29552a110a88fac1297894-144x144-svg",
            _type: "reference",
          },
        },
        name: "Temperature",
        slug: {
          _type: "slug",
          current: "temperature",
        },
        subtitle: "Measure your micro:bit’s temperature",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "db885c79e4e7",
                _type: "block",
                children: [
                  {
                    _key: "237ef7699b87",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit has a compass sensor that can measure its heading or bearing from magnetic North in degrees:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "71795d8fd9a3",
                _type: "simpleImage",
                alt: "Diagram of a compass rose and micro:bit showing that bearings are measured from the top of the micro:bit clockwise when held flat",
                asset: {
                  _ref: "image-cf06d948b591b9d8822ba0b3fdbb8a7eafeef15e-1273x1017-png",
                  _type: "reference",
                },
              },
              {
                _key: "8a450c2edf94",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(compass.heading())\n",
              },
            ],
            detailContent: [
              {
                _key: "ad3c0380cff7",
                _type: "block",
                children: [
                  {
                    _key: "b82ed3e109bc",
                    _type: "span",
                    marks: [
                    ],
                    text: "The first time you use the compass you may be asked to calibrate it by playing a little game. ‘TILT TO FILL SCREEN’ scrolls across the display. Tilt the micro:bit until every LED light is lit, and then a happy face appears. This ensures the compass gives accurate readings. You can also make your program calibrate the compass every time it runs:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d0347807b973",
                _type: "python",
                main: "from microbit import *\n\n\ncompass.calibrate()",
              },
            ],
            name: "Heading",
            slug: {
              _type: "slug",
              current: "heading",
            },
          },
          {
            alternatives: [
              {
                _key: "6b9e16d3cf31",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "887a3492097b",
                    _type: "python",
                    main: "from microbit import *\n\nmagnet_strength_x = compass.get_x()\ndisplay.scroll(magnet_strength_x)",
                  },
                ],
                name: "x",
                slug: {
                  _type: "slug",
                  current: "x",
                },
              },
              {
                _key: "84f817330876",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "4b46fcf6a7e2",
                    _type: "python",
                    main: "from microbit import *\n\nmagnet_strength_y = compass.get_y()\ndisplay.scroll(magnet_strength_y)",
                  },
                ],
                name: "y",
                slug: {
                  _type: "slug",
                  current: "y",
                },
              },
              {
                _key: "eb959117f613",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "c4ed18bbce8b",
                    _type: "python",
                    main: "from microbit import *\n\nmagnet_strength_z = compass.get_z()\ndisplay.scroll(magnet_strength_z)",
                  },
                ],
                name: "z",
                slug: {
                  _type: "slug",
                  current: "z",
                },
              },
              {
                _key: "8470ce7e11a1",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "3a041b7bffb8",
                    _type: "python",
                    main: "from microbit import *\n\nmagnet_strength_all = compass.get_field_strength()\ndisplay.scroll(magnet_strength_all)",
                  },
                ],
                name: "overall strength",
                slug: {
                  _type: "slug",
                  current: "overall-strength",
                },
              },
            ],
            alternativesLabel: "Select dimension:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "39fef9d4a0cb",
                _type: "block",
                children: [
                  {
                    _key: "0d7b463c1c33",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use the compass as a magnetometer, to measure the strength of magnetic fields. It can do this in 3 dimensions, and also measure overall strength of magnetic fields in all dimensions at once.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d0c9bd440a58",
                _type: "simpleImage",
                alt: "micro:bit showing X axis going across the front, Y axis going down and up, Z axis going back to front",
                asset: {
                  _ref: "image-27191bef107a45c1fc9848c3222464505ac1d993-847x804-png",
                  _type: "reference",
                },
              },
            ],
            detailContent: [
              {
                _key: "0e278c24e739",
                _type: "block",
                children: [
                  {
                    _key: "a3587c5bafb0",
                    _type: "span",
                    marks: [
                    ],
                    text: "The compass measures magnetic field strength in units called nanoteslas (nT). Readings can be positive or negative, depending on the direction of the magnetic field.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Magnetic field strength",
            slug: {
              _type: "slug",
              current: "magnetic-field-strength",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-c1f3ae4947a65d2ffc6997d59668031a74a46c77-144x144-svg",
            _type: "reference",
          },
        },
        name: "Compass",
        slug: {
          _type: "slug",
          current: "compass",
        },
        subtitle: "Measure direction and strength of magnetic fields",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "c8aaef302be4",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "df6b7ab18806",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.BA_DING)",
                  },
                ],
                name: "BA_DING",
                slug: {
                  _type: "slug",
                  current: "bading",
                },
              },
              {
                _key: "55b91a426e0c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "828a512e5579",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.BADDY)",
                  },
                ],
                name: "BADDY",
                slug: {
                  _type: "slug",
                  current: "baddy",
                },
              },
              {
                _key: "aa046d39f752",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "63cf86de9974",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.BIRTHDAY)",
                  },
                ],
                name: "BIRTHDAY",
                slug: {
                  _type: "slug",
                  current: "birthday",
                },
              },
              {
                _key: "45873617d989",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "96202032f518",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.BLUES)",
                  },
                ],
                name: "BLUES",
                slug: {
                  _type: "slug",
                  current: "blues",
                },
              },
              {
                _key: "6b28ff6d669b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2419663e8aaf",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.CHASE)",
                  },
                ],
                name: "CHASE",
                slug: {
                  _type: "slug",
                  current: "chase",
                },
              },
              {
                _key: "6670f6f87fab",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "3f9abbf20bba",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.DADADADUM)",
                  },
                ],
                name: "DADADADUM",
                slug: {
                  _type: "slug",
                  current: "dadadadum",
                },
              },
              {
                _key: "789b4afd081b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "49000b92357b",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.ENTERTAINER)",
                  },
                ],
                name: "ENTERTAINER",
                slug: {
                  _type: "slug",
                  current: "entertainer",
                },
              },
              {
                _key: "65df25901e1c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d22f7c67ecab",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.FUNERAL)",
                  },
                ],
                name: "FUNERAL",
                slug: {
                  _type: "slug",
                  current: "funeral",
                },
              },
              {
                _key: "c5805dc7fe00",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "91cb39bf9c0c",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.FUNK)",
                  },
                ],
                name: "FUNK",
                slug: {
                  _type: "slug",
                  current: "funk",
                },
              },
              {
                _key: "50bd115ea593",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "5060a10ccfb6",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.JUMP_DOWN)",
                  },
                ],
                name: "JUMP_DOWN",
                slug: {
                  _type: "slug",
                  current: "jumpdown",
                },
              },
              {
                _key: "b7bc5939c13a",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "31dffa32c67b",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.JUMP_UP)",
                  },
                ],
                name: "JUMP_UP",
                slug: {
                  _type: "slug",
                  current: "jumpup",
                },
              },
              {
                _key: "4fb615d8476c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "ab9b743c9719",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.NYAN)",
                  },
                ],
                name: "NYAN",
                slug: {
                  _type: "slug",
                  current: "nyan",
                },
              },
              {
                _key: "9355df14aa8c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b057629b68d1",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.ODE)",
                  },
                ],
                name: "ODE",
                slug: {
                  _type: "slug",
                  current: "ode",
                },
              },
              {
                _key: "57dbd2a69369",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "91c01730854b",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.POWER_DOWN)",
                  },
                ],
                name: "POWER_DOWN",
                slug: {
                  _type: "slug",
                  current: "powerdown",
                },
              },
              {
                _key: "af7aabf351e4",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2215d31872e9",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.POWER_UP)",
                  },
                ],
                name: "POWER_UP",
                slug: {
                  _type: "slug",
                  current: "powerup",
                },
              },
              {
                _key: "789b344a7dc5",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "475a74d2160c",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.PRELUDE)",
                  },
                ],
                name: "PRELUDE",
                slug: {
                  _type: "slug",
                  current: "prelude",
                },
              },
              {
                _key: "10058a2ece60",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "19ff3d0ffaa4",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.PUNCHLINE)",
                  },
                ],
                name: "PUNCHLINE",
                slug: {
                  _type: "slug",
                  current: "punchline",
                },
              },
              {
                _key: "4b64183411fb",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f13e11b1dbbf",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.PYTHON)",
                  },
                ],
                name: "PYTHON",
                slug: {
                  _type: "slug",
                  current: "python",
                },
              },
              {
                _key: "e5d4b20d7068",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a6ffbe21d3d4",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.RINGTONE)",
                  },
                ],
                name: "RINGTONE",
                slug: {
                  _type: "slug",
                  current: "ringtone",
                },
              },
              {
                _key: "df839602bb2e",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "7156e8e5d145",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.WAWAWAWAA)",
                  },
                ],
                name: "WAWAWAWAA",
                slug: {
                  _type: "slug",
                  current: "wawawawaa",
                },
              },
              {
                _key: "c8fe016edd5f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "21421e2db774",
                    _type: "python",
                    main: "import music\n\n\nmusic.play(music.WEDDING)",
                  },
                ],
                name: "WEDDING",
                slug: {
                  _type: "slug",
                  current: "wedding",
                },
              },
            ],
            alternativesLabel: "Select tune:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            detailContent: [
              {
                _key: "a6ecbe1581ce",
                _type: "block",
                children: [
                  {
                    _key: "7c24c182f78e",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit will wait until the tune has finished playing until carrying out the next instruction in your code. If you want something to happen while the music is still playing, add ",
                  },
                  {
                    _key: "73c59f8aff5c",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "wait=False",
                  },
                  {
                    _key: "27b61a2490e6",
                    _type: "span",
                    marks: [
                    ],
                    text: ":",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "520a5e9605af",
                _type: "python",
                main: "from microbit import *\nimport music\n\n\nmusic.play(music.PYTHON, wait=False)\ndisplay.show(Image.SNAKE)",
              },
            ],
            name: "Built-in music",
            slug: {
              _type: "slug",
              current: "builtin-music",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "8785d57d4654",
                _type: "block",
                children: [
                  {
                    _key: "b1080f68fdfc",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can set the tempo, or speed, of music played by the micro:bit: ",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d62fee1d9bac",
                _type: "python",
                main: "import music\n\n\nmusic.set_tempo(bpm=120)",
              },
            ],
            detailContent: [
              {
                _key: "fd089de4cc3e",
                _type: "block",
                children: [
                  {
                    _key: "6835baf63140",
                    _type: "span",
                    marks: [
                    ],
                    text: "Tempo is measured in bpm, beats per minute. Numbers lower than 120 are slower than normal, higher than 120 are faster.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Tempo",
            slug: {
              _type: "slug",
              current: "tempo",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "72b1dfc468e7",
                _type: "block",
                children: [
                  {
                    _key: "036b5d98c35c",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use musical notation to play tunes:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "141853e05e66",
                _type: "python",
                main: "import music\n\n\nmusic.play(['c', 'd', 'e', 'c'])",
              },
            ],
            detailContent: [
              {
                _key: "7cfa833752e1",
                _type: "block",
                children: [
                  {
                    _key: "dd833fe1bdc50",
                    _type: "span",
                    marks: [
                    ],
                    text: "By default it plays notes in the 4th octave. You can specify other octaves.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "902b47e10457",
                _type: "block",
                children: [
                  {
                    _key: "8025c1f6b8b00",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example plays the notes C, D, E, C in the 3rd octave:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "2c5aea61455b",
                _type: "python",
                main: "from microbit import *\nimport music\n \nmusic.play(['c3', 'd3', 'e3', 'c3'])",
              },
              {
                _key: "ea94b3689cab",
                _type: "block",
                children: [
                  {
                    _key: "fc8baac7d14f",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can choose how long each note plays using a colon and number after the note name. Here the note G4 plays for twice as long as the other notes:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "18569e13cd03",
                _type: "python",
                main: "import music\n\n\nmusic.play(['e4:4', 'f4:4', 'g4:8'])",
              },
              {
                _key: "0e0208dee11e",
                _type: "block",
                children: [
                  {
                    _key: "dd0d2bdb920f",
                    _type: "span",
                    marks: [
                    ],
                    text: "Rests can be added using the note name 'r' for any duration you specify.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3426dfcd4122",
                _type: "block",
                children: [
                  {
                    _key: "00d0490536fd",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use flat notes by adding ‘b’ to the note name and sharp notes with ‘#’. This plays A flat, A natural and A sharp:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "47fa35788956",
                _type: "python",
                main: "import music\n\n\nmusic.play(['ab', 'a', 'a#'])",
              },
            ],
            name: "Play notes",
            slug: {
              _type: "slug",
              current: "play-notes",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "c87550f4bd15",
                _type: "block",
                children: [
                  {
                    _key: "513bad8055c2",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit can play sounds defined by frequency or pitch:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ca0e1ceb5ae7",
                _type: "python",
                main: "import music\n\n\nmusic.pitch(440)",
              },
            ],
            detailContent: [
              {
                _key: "23bd904fb256",
                _type: "block",
                children: [
                  {
                    _key: "385f6ab85580",
                    _type: "span",
                    marks: [
                    ],
                    text: "This will play a note at 440Hz (the note A).",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "c16121e1960a",
                _type: "block",
                children: [
                  {
                    _key: "04f62c9b3106",
                    _type: "span",
                    marks: [
                    ],
                    text: "The sound will continue playing until you change or stop it:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d0e3d25283db",
                _type: "python",
                main: "import music\n\n\nmusic.stop()",
              },
              {
                _key: "ad7dcb185611",
                _type: "block",
                children: [
                  {
                    _key: "e6e7f069458b",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example produces a sound like a siren rising from 100 Hz to 1000 Hz:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6740b42fa83d",
                _type: "python",
                main: "from microbit import *\nimport music\n\n\nfor p in range(100,1000):\n    music.pitch(p)\n    sleep(5)\nmusic.stop()",
              },
            ],
            name: "Pitch",
            slug: {
              _type: "slug",
              current: "pitch",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "c667622b7740",
                _type: "block",
                children: [
                  {
                    _key: "f8c1967b3d34",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can make your micro:bit speak using Python:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9dc887ba808e",
                _type: "python",
                main: "import speech\n\n\nspeech.say('Hello, world. How are you?')",
              },
            ],
            detailContent: [
              {
                _key: "67777a91b42b",
                _type: "block",
                children: [
                  {
                    _key: "d1072061b777",
                    _type: "span",
                    marks: [
                    ],
                    text: "Add pauses with a dash (",
                  },
                  {
                    _key: "0f2c4b8583dd",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "-",
                  },
                  {
                    _key: "ef98e2e3bd50",
                    _type: "span",
                    marks: [
                    ],
                    text: "), a longer pause with a comma (",
                  },
                  {
                    _key: "31ab2ccd12c0",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: ",",
                  },
                  {
                    _key: "4bccb37fc57e",
                    _type: "span",
                    marks: [
                    ],
                    text: ") and change the inflection using full stops (",
                  },
                  {
                    _key: "a699b6284fad",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: ".",
                  },
                  {
                    _key: "f59a414bdba3",
                    _type: "span",
                    marks: [
                    ],
                    text: ") and question marks (",
                  },
                  {
                    _key: "4662389e4f8e",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "?",
                  },
                  {
                    _key: "eb47107c900f",
                    _type: "span",
                    marks: [
                    ],
                    text: ").",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7d364910e15b",
                _type: "block",
                children: [
                  {
                    _key: "5686052b82cd",
                    _type: "span",
                    marks: [
                      "e8ef0442145c",
                    ],
                    text: "Read the full documentation",
                  },
                  {
                    _key: "b0543c2be34d",
                    _type: "span",
                    marks: [
                    ],
                    text: " to discover more about how to use speech, create different voices and even make your micro:bit sing.",
                  },
                ],
                markDefs: [
                  {
                    _key: "e8ef0442145c",
                    _type: "toolkitApiLink",
                    name: "speech",
                  },
                ],
                style: "normal",
              },
            ],
            name: "Speech",
            slug: {
              _type: "slug",
              current: "speech",
            },
          },
          {
            alternatives: [
              {
                _key: "b48fa910d634",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e68acd25a2ee",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.GIGGLE)",
                  },
                ],
                name: "giggle",
                slug: {
                  _type: "slug",
                  current: "giggle",
                },
              },
              {
                _key: "6805f774376d",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e6bb89dd57ed",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.HAPPY)",
                  },
                ],
                name: "happy",
                slug: {
                  _type: "slug",
                  current: "happy",
                },
              },
              {
                _key: "336b85888a1b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a8d97570a6e6",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.HELLO)",
                  },
                ],
                name: "hello",
                slug: {
                  _type: "slug",
                  current: "hello",
                },
              },
              {
                _key: "5fa04d293e6f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "896216435f59",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.MYSTERIOUS)",
                  },
                ],
                name: "mysterious",
                slug: {
                  _type: "slug",
                  current: "mysterious",
                },
              },
              {
                _key: "607acf9c2e2c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "8e7889ba9858",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.SAD)",
                  },
                ],
                name: "sad",
                slug: {
                  _type: "slug",
                  current: "sad",
                },
              },
              {
                _key: "81e049615bef",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "96d5fe899c24",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.SLIDE)",
                  },
                ],
                name: "slide",
                slug: {
                  _type: "slug",
                  current: "slide",
                },
              },
              {
                _key: "fdaa6dc078c2",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "543912ef28c9",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.SOARING)",
                  },
                ],
                name: "soaring",
                slug: {
                  _type: "slug",
                  current: "soaring",
                },
              },
              {
                _key: "dc3526065225",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "4763eeb1d2e5",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.SPRING)",
                  },
                ],
                name: "spring",
                slug: {
                  _type: "slug",
                  current: "spring",
                },
              },
              {
                _key: "ad3ef7ea49c1",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f32a43de7120",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.TWINKLE)",
                  },
                ],
                name: "twinkle",
                slug: {
                  _type: "slug",
                  current: "twinkle",
                },
              },
              {
                _key: "25b8caa690cd",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "8bc55bbd7682",
                    _type: "python",
                    main: "from microbit import *\n\n\naudio.play(Sound.YAWN)",
                  },
                ],
                name: "yawn",
                slug: {
                  _type: "slug",
                  current: "yawn",
                },
              },
            ],
            alternativesLabel: "Select sound:",
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "396fd2967318",
                _type: "block",
                children: [
                  {
                    _key: "c19b104ae14c",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit V2 with built-in speaker can also make some expressive sounds to add personality to your projects.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Expressive sounds",
            slug: {
              _type: "slug",
              current: "expressive-sounds",
            },
          },
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "bd395f1d8279",
                _type: "block",
                children: [
                  {
                    _key: "6e250f890e75",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can adjust the volume of the micro:bit V2’s audio output using numbers in the range 0 to 255:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "194574bf5a35",
                _type: "python",
                main: "from microbit import *\n\n\nset_volume(128)",
              },
            ],
            detailContent: [
              {
                _key: "4aa32f508536",
                _type: "block",
                children: [
                  {
                    _key: "f983e9cd87ae",
                    _type: "span",
                    marks: [
                    ],
                    text: "Adjusting the volume affects both the speaker and audio output from the pins. This feature only works on the micro:bit V2. ",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Volume",
            slug: {
              _type: "slug",
              current: "volume",
            },
          },
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "59b7d4ead581",
                _type: "block",
                children: [
                  {
                    _key: "efe759a41754",
                    _type: "span",
                    marks: [
                    ],
                    text: "Mute the V2 micro:bit’s built-in speaker, but still have sound available on the pins for headphones:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "5f498b67f301",
                _type: "python",
                main: "from microbit import *\n\n\nspeaker.off()",
              },
              {
                _key: "f25836a88f96",
                _type: "block",
                children: [
                  {
                    _key: "1be74c206a27",
                    _type: "span",
                    marks: [
                    ],
                    text: "Turn the speaker back on:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9b9ce9b4976f",
                _type: "python",
                main: "from microbit import *\n\n\nspeaker.on()",
              },
            ],
            name: "Turn the speaker off",
            slug: {
              _type: "slug",
              current: "turn-the-speaker-off",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-312e12b67224075c1cdd717013d8dd5c8e6b784f-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "24aeeff8f321",
            _type: "block",
            children: [
              {
                _key: "a3a35f98a5e0",
                _type: "span",
                marks: [
                ],
                text: "The micro:bit can play tunes and even speak. The V2 micro:bit has a built-in speaker, and you can attach headphones or an amplified speaker to any micro:bit’s 0 and GND ",
              },
              {
                _key: "12aa32203342",
                _type: "span",
                marks: [
                  "ee5c47ebcdc0",
                ],
                text: "pins",
              },
              {
                _key: "df1e7f72cb79",
                _type: "span",
                marks: [
                ],
                text: ". You need to ",
              },
              {
                _key: "bfcce1d03e1d",
                _type: "span",
                marks: [
                  "code",
                ],
                text: "import music",
              },
              {
                _key: "84bcded89c56",
                _type: "span",
                marks: [
                ],
                text: " near the start of your program to use the music features.",
              },
            ],
            markDefs: [
              {
                _key: "ee5c47ebcdc0",
                _type: "toolkitInternalLink",
                reference: {
                  _ref: "fd17d39b-948f-43de-b39d-989dbfd7b6c1",
                  _type: "reference",
                },
                slug: {
                  _type: "slug",
                  current: "pins",
                },
                targetType: "toolkitTopic",
              },
            ],
            style: "normal",
          },
        ],
        name: "Sound",
        slug: {
          _type: "slug",
          current: "sound",
        },
        subtitle: "Play music, speech and other sounds",
      },
      {
        compatibility: [
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "78c0a1d66821",
                _type: "block",
                children: [
                  {
                    _key: "7ba7fff4842d",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit can react to quiet or loud sound events:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ecc5c6dafdde",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    if microphone.current_event() == SoundEvent.LOUD:\n        display.show(Image.HAPPY)\n    elif microphone.current_event() == SoundEvent.QUIET:\n        display.show(Image.ASLEEP)",
              },
            ],
            detailContent: [
              {
                _key: "5149d7e96141",
                _type: "block",
                children: [
                  {
                    _key: "984332bf2b47",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can set the threshold for loud or quiet events using numbers in the range 0-255.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b738b853c756",
                _type: "python",
                main: "from microbit import *\n\n\nmicrophone.set_threshold(SoundEvent.LOUD, 200)\nmicrophone.set_threshold(SoundEvent.QUIET, 1)",
              },
            ],
            name: "Sound events",
            slug: {
              _type: "slug",
              current: "sound-events",
            },
          },
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "840eb85bc7f2",
                _type: "block",
                children: [
                  {
                    _key: "02367814dc6b",
                    _type: "span",
                    marks: [
                    ],
                    text: "The microphone can measure sound levels in a range from 0 to 255, with 0 being the quietest and 255 the loudest:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "4a5ca804626d",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(microphone.sound_level())",
              },
            ],
            name: "Measuring sound levels",
            slug: {
              _type: "slug",
              current: "measuring-sound-levels",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-cf32575676628fab956b436797eb94833df5ea42-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "e948591c01ea",
            _type: "simpleImage",
            alt: "Diagram of the microphone on the front and back of the V2 micro:bit.",
            asset: {
              _ref: "image-1981683ecc7d260ad5330f36f98807d702150252-1176x512-png",
              _type: "reference",
            },
            halfWidth: false,
          },
          {
            _key: "d135c6427eb2",
            _type: "block",
            children: [
              {
                _key: "021f50d6c18d",
                _type: "span",
                marks: [
                ],
                text: "The micro:bit V2 has a built-in microphone. It's situated on the back of the micro:bit, and there’s a hole on the front to the right of the touch logo to let the sound in. There’s also a microphone LED on the front that lights up when the microphone is in use.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
          {
            _key: "9606c54d193e",
            _type: "block",
            children: [
              {
                _key: "502113fc4c690",
                _type: "span",
                marks: [
                ],
                text: "",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: " Microphone",
        slug: {
          _type: "slug",
          current: "microphone",
        },
        subtitle: "Measure and react to sound levels",
      },
      {
        compatibility: [
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "b37c057b772f",
                _type: "block",
                children: [
                  {
                    _key: "6670e3367fe0",
                    _type: "span",
                    marks: [
                    ],
                    text: "The micro:bit V2 has a gold logo on the front that you can use as an extra button when you touch it:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "396fdfc2baa6",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    if pin_logo.is_touched():\n        display.show(Image.HAPPY)",
              },
            ],
            detailContent: [
              {
                _key: "775a1a8e2e4d",
                _type: "block",
                children: [
                  {
                    _key: "f2adbc3b9710",
                    _type: "span",
                    marks: [
                    ],
                    text: "By default the touch logo on the micro:bit V2 uses ",
                  },
                  {
                    _key: "ffc8e2440ef0",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "capacitive",
                  },
                  {
                    _key: "063f9682921d",
                    _type: "span",
                    marks: [
                    ],
                    text: " touch. This means that, unlike the default ",
                  },
                  {
                    _key: "65e8c428a585",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "resistive",
                  },
                  {
                    _key: "6343dd52dfaa",
                    _type: "span",
                    marks: [
                    ],
                    text: " touch behaviour of the pins, you can just touch the button with your finger, you don’t also need to hold the GND pin.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "be0cad4469cf",
                _type: "block",
                children: [
                  {
                    _key: "dfe8e82a16bf",
                    _type: "span",
                    marks: [
                    ],
                    text: "See ",
                  },
                  {
                    _key: "9bc18c9134c3",
                    _type: "span",
                    marks: [
                      "09abae4162d4",
                    ],
                    text: "set_touch_mode",
                  },
                  {
                    _key: "b817325ca9f8",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: " ",
                  },
                  {
                    _key: "09ebc094cfbf",
                    _type: "span",
                    marks: [
                    ],
                    text: "in the API documentation for more information.",
                  },
                ],
                markDefs: [
                  {
                    _key: "09abae4162d4",
                    _type: "toolkitApiLink",
                    name: "microbit.MicroBitTouchPin.set_touch_mode",
                  },
                ],
                style: "normal",
              },
            ],
            name: "Touch logo",
            slug: {
              _type: "slug",
              current: "touch-logo",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-89dba1315f0d25502fca43a5928d62f3e63f069e-144x144-svg",
            _type: "reference",
          },
        },
        name: "Touch logo",
        slug: {
          _type: "slug",
          current: "touch-logo",
        },
        subtitle: "Use the gold logo as an extra button",
      },
      {
        compatibility: [
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "6f3c7c4899d9",
                _type: "block",
                children: [
                  {
                    _key: "c79ebc15345d",
                    _type: "span",
                    marks: [
                    ],
                    text: "To set up data logging, you need to provide descriptions for your data. These will appear as the column headings:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d016523ce57b",
                _type: "python",
                main: "from microbit import *\nimport log\n\n\nlog.set_labels('temperature', 'sound', 'light')",
              },
            ],
            detailContent: [
              {
                _key: "8b62e9cc2560",
                _type: "block",
                children: [
                  {
                    _key: "b7194bb46d850",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example will create three column headings titled ",
                  },
                  {
                    _key: "18442431aecf",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "temperature",
                  },
                  {
                    _key: "c674c890d65f",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "42cb5b70ed27",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "sound",
                  },
                  {
                    _key: "3396c4382568",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "24d603b6b79a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "light",
                  },
                  {
                    _key: "d9538b86aa3e",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "f2f75e261fc3",
                _type: "block",
                children: [
                  {
                    _key: "07c6bef73af5",
                    _type: "span",
                    marks: [
                    ],
                    text: "The first column in your log holds the timestamp and will be added to every row of data. You can set the format of the timestamp when you set your column labels to ",
                  },
                  {
                    _key: "bb9e7282dcfb",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "MILLISECONDS",
                  },
                  {
                    _key: "2f2d92d7333b",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "4c14007a5ede",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "SECONDS",
                  },
                  {
                    _key: "88fc63eab625",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "0862bd89f6e3",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "MINUTES",
                  },
                  {
                    _key: "21fef8cb1eaf",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "b07e71ca84dc",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "HOURS",
                  },
                  {
                    _key: "134575f8bf47",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "17af02c4ad4e",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "DAYS",
                  },
                  {
                    _key: "d39c11c04cff",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "b3e7b9bdf408",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "NONE",
                  },
                  {
                    _key: "73695975db64",
                    _type: "span",
                    marks: [
                    ],
                    text: " if you want to disable the timestamp.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "aa14c72bcff4",
                _type: "block",
                children: [
                  {
                    _key: "a74f8e2435b3",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example sets the format to record the time in  'hours':",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "cc0a770930b7",
                _type: "python",
                main: "import log \n\nlog.set_labels('temperature', 'sound', 'light', timestamp=log.HOURS)",
              },
            ],
            name: "Set column labels",
            slug: {
              _type: "slug",
              current: "set-column-labels",
            },
          },
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "214d0954f84a",
                _type: "block",
                children: [
                  {
                    _key: "606e8aa8a2f1",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can log data from any of the built-in sensors on your micro:bit:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "f3d7318dafd3",
                _type: "python",
                main: "from microbit import *\nimport log\n\n\nlog.add({\n  'temperature': temperature(),\n  'sound': microphone.sound_level(),\n  'light': display.read_light_level()\n})",
              },
            ],
            detailContent: [
              {
                _key: "8d6e20fdac32",
                _type: "block",
                children: [
                  {
                    _key: "2c9ddb1e88d9",
                    _type: "span",
                    marks: [
                    ],
                    text: "The example above adds an entry to the ",
                  },
                  {
                    _key: "29bc910ac6f8",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "temperature",
                  },
                  {
                    _key: "b6325965815f",
                    _type: "span",
                    marks: [
                    ],
                    text: ", ",
                  },
                  {
                    _key: "fd046410c047",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "sound",
                  },
                  {
                    _key: "5de801ee1b1c",
                    _type: "span",
                    marks: [
                    ],
                    text: " and",
                  },
                  {
                    _key: "bac27a7eb25e",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: " light",
                  },
                  {
                    _key: "7159186ad2e9",
                    _type: "span",
                    marks: [
                    ],
                    text: " columns using values from the ",
                  },
                  {
                    _key: "3c147e7bbcac",
                    _type: "span",
                    marks: [
                      "2c9fae3e6f44",
                    ],
                    text: "temperature sensor",
                  },
                  {
                    _key: "53d32e40994d",
                    _type: "span",
                    marks: [
                    ],
                    text: ", the ",
                  },
                  {
                    _key: "f8dac40b01af",
                    _type: "span",
                    marks: [
                      "7bbf65ace6a4",
                    ],
                    text: "microphone",
                  },
                  {
                    _key: "bad5151558d6",
                    _type: "span",
                    marks: [
                    ],
                    text: " and the ",
                  },
                  {
                    _key: "c8dc8213e92f",
                    _type: "span",
                    marks: [
                      "577559feec13",
                    ],
                    text: "light level sensor",
                  },
                  {
                    _key: "1d2d917d7b04",
                    _type: "span",
                    marks: [
                    ],
                    text: ".",
                  },
                ],
                markDefs: [
                  {
                    _key: "2c9fae3e6f44",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "ba3dc421-bf4c-407b-9eb2-9993ec9ed82e",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "temperature",
                    },
                    targetType: "toolkitTopic",
                  },
                  {
                    _key: "7bbf65ace6a4",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "93da3d8f-497e-43f3-ae77-c2e1530b4bc0",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "microphone",
                    },
                    targetType: "toolkitTopic",
                  },
                  {
                    _key: "577559feec13",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "7a22d94e-fc54-4f05-8c41-1b6f3097041d",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "light-level",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
              {
                _key: "f27fe962f398",
                _type: "block",
                children: [
                  {
                    _key: "70e68ca37f6b",
                    _type: "span",
                    marks: [
                    ],
                    text: "The headings and values are passed to ",
                  },
                  {
                    _key: "4d91a717b840",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "log.add()",
                  },
                  {
                    _key: "abf52e62e550",
                    _type: "span",
                    marks: [
                    ],
                    text: " using a dictionary.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b3ec1675f6f0",
                _type: "block",
                children: [
                  {
                    _key: "3532f227dac00",
                    _type: "span",
                    marks: [
                    ],
                    text: "A ",
                  },
                  {
                    _key: "71936ab04d93",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "dictionary",
                  },
                  {
                    _key: "c07903869ee0",
                    _type: "span",
                    marks: [
                    ],
                    text: " is a data structure that consists of key:value pairs inside curly brackets ",
                  },
                  {
                    _key: "3cac432b3db5",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "{}",
                  },
                  {
                    _key: "eea31a90e29b",
                    _type: "span",
                    marks: [
                    ],
                    text: ". Each entry contains the label and the value associated with it.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ba052ad6a4ef",
                _type: "block",
                children: [
                  {
                    _key: "e6d6c2b02954",
                    _type: "span",
                    marks: [
                    ],
                    text: "Instead of using dictionary values, you can alternatively use ",
                  },
                  {
                    _key: "0e494a9512b3",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "keyword arguments",
                  },
                  {
                    _key: "6fb29cc6cdaf",
                    _type: "span",
                    marks: [
                    ],
                    text: " with ",
                  },
                  {
                    _key: "09a984a7ba6b",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "log.add()",
                  },
                  {
                    _key: "1c22a2e3f017",
                    _type: "span",
                    marks: [
                    ],
                    text: ". This example logs the same data:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "421b987b0339",
                _type: "python",
                main: "from microbit import *\nimport log\n\n\nlog.add(\n    temperature=temperature(),\n    sound=microphone.sound_level(),\n    light=display.read_light_level()\n)",
              },
            ],
            name: "Log rows of data",
            slug: {
              _type: "slug",
              current: "log-rows-of-data",
            },
          },
          {
            compatibility: [
              "microbitV2",
            ],
            content: [
              {
                _key: "04a8e6cda717",
                _type: "block",
                children: [
                  {
                    _key: "72d5bbf96b63",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use a scheduler to log data automatically at regular intervals:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "2b6c55b7548b",
                _type: "python",
                main: "from microbit import *\n\nimport log\n\n@run_every(s=30)\ndef log_data():\n    log.add({\n      'temperature': temperature(),\n      'sound': microphone.sound_level(),\n      'light': display.read_light_level()\n    })\n    \nwhile True:\n    sleep(100000)",
              },
            ],
            detailContent: [
              {
                _key: "b88c7f29fc9b",
                _type: "block",
                children: [
                  {
                    _key: "7ee1055dd8450",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example logs data every 30 seconds.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "4d856c9e284e",
                _type: "block",
                children: [
                  {
                    _key: "41ec0bf8a344",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use multiple time unit parameters, so for example you could use ",
                  },
                  {
                    _key: "086084b6bdc4",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "@run_every(h=1, min=20, s=30, ms=50)",
                  },
                  {
                    _key: "508cf33ad18f",
                    _type: "span",
                    marks: [
                    ],
                    text: " to log data every 1 hour 20 minutes 30 seconds and 50 ms.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Schedule log entries",
            slug: {
              _type: "slug",
              current: "schedule-log-entries",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-d771c0c4fb8768db93eb9f6b6b4397146b44b145-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "f0efda0b2f0b",
            _type: "block",
            children: [
              {
                _key: "dfe6f5ef98da",
                _type: "span",
                marks: [
                ],
                text: "The micro:bit V2 can log sensor data to a file on the micro:bit. After logging your data, plug the micro:bit into a computer and open ",
              },
              {
                _key: "1c3077596528",
                _type: "span",
                marks: [
                  "code",
                ],
                text: "MY_DATA.HTM",
              },
              {
                _key: "a17149b5303b",
                _type: "span",
                marks: [
                ],
                text: " on the MICROBIT drive to view your data in a web browser, or download it as a CSV file for use in a spreadsheet.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Data logging",
        slug: {
          _type: "slug",
          current: "data-logging",
        },
        subtitle: "Log sensor data on your micro:bit",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "e8480abee387",
                _type: "simpleImage",
                alt: "Diagram showing micro:bit ground pin connected with a crocodile clip lead to the tip of a headphone plug and micro:bit pin 0 connected to the long part of a headphone plug furthest from the tip",
                asset: {
                  _ref: "image-ec82665799acb0b5a79a08039bb5028b8c81ffce-1265x833-png",
                  _type: "reference",
                },
              },
              {
                _key: "192bcf7e84cc",
                _type: "block",
                children: [
                  {
                    _key: "22e091647731",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can attach headphones or an amplified speaker to hear micro:bit ",
                  },
                  {
                    _key: "4b11f84d354e",
                    _type: "span",
                    marks: [
                      "b09f5c0f3f73",
                    ],
                    text: "sound",
                  },
                  {
                    _key: "e6010d14fca5",
                    _type: "span",
                    marks: [
                    ],
                    text: ". ",
                  },
                ],
                markDefs: [
                  {
                    _key: "b09f5c0f3f73",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "0bdc42c3-7bdd-4291-bc5e-fd4957283578",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "sound",
                    },
                    targetType: "toolkitTopic",
                  },
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "f11273a4eb88",
                _type: "block",
                children: [
                  {
                    _key: "5d2acd4da617",
                    _type: "span",
                    marks: [
                    ],
                    text: "Connect pin 0 to the tip of a normal headphone plug, and connect the GND pin to the long part of the plug to complete the circuit. Sound will come out of just one side of the headphones.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "cd0ce6e37384",
                _type: "block",
                children: [
                  {
                    _key: "38e75b1583bb",
                    _type: "span",
                    marks: [
                    ],
                    text: "The sound can be loud, so always test it out with headphones off before wearing them.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Sound on pins",
            slug: {
              _type: "slug",
              current: "sound-on-pins",
            },
          },
          {
            alternatives: [
              {
                _key: "1cc31f06db40",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2f729536e187",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if pin0.is_touched():\n        display.show(0)",
                  },
                ],
                name: "pin 0",
                slug: {
                  _type: "slug",
                  current: "pin-0",
                },
              },
              {
                _key: "92059dae3d8c",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f9795715f89c",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if pin1.is_touched():\n        display.show(1)",
                  },
                ],
                name: "pin 1",
                slug: {
                  _type: "slug",
                  current: "pin-1",
                },
              },
              {
                _key: "c853f866fea3",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "275b316a7ecc",
                    _type: "python",
                    main: "from microbit import *\n\n\nwhile True:\n    if pin2.is_touched():\n        display.show(2)",
                  },
                ],
                name: "pin 2",
                slug: {
                  _type: "slug",
                  current: "pin-2",
                },
              },
            ],
            alternativesLabel: "Select pin:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "3a7c5ef0805b",
                _type: "block",
                children: [
                  {
                    _key: "37155fca1b7c",
                    _type: "span",
                    marks: [
                    ],
                    text: "Pins 0, 1 and 2 can work as touch sensors when you also touch the GND pin to complete an electrical circuit:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "c3cb1d3a6317",
                _type: "block",
                children: [
                  {
                    _key: "37155fca1b7c",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can connect these pins using crocodile clips to metal foil to make your own switches or game controllers.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6535a0675c29",
                _type: "block",
                children: [
                  {
                    _key: "3afdc5947b15",
                    _type: "span",
                    marks: [
                    ],
                    text: "By default the pins work in ",
                  },
                  {
                    _key: "6947d2d9aeef",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "resistive",
                  },
                  {
                    _key: "7120d952004e",
                    _type: "span",
                    marks: [
                    ],
                    text: " touch mode, which means you also need to touch the GND pin as well as pin 0, 1 or 2 to complete a circuit.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9dc451b3ebc6",
                _type: "block",
                children: [
                  {
                    _key: "853d0424ddd6",
                    _type: "span",
                    marks: [
                    ],
                    text: "The pins on the micro:bit V2 can alternatively be made to work in ",
                  },
                  {
                    _key: "855585206f56",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "capacitive",
                  },
                  {
                    _key: "1a71abb8ff00",
                    _type: "span",
                    marks: [
                    ],
                    text: " mode, which means you just need to touch the pin, but not GND as well.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ec52799aaeba",
                _type: "block",
                children: [
                  {
                    _key: "4d31be8dac3d",
                    _type: "span",
                    marks: [
                    ],
                    text: "This changes pin 1 to work with capacitive touch:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9b78faca5e59",
                _type: "python",
                main: "from microbit import *\n\n\npin1.set_touch_mode(pin1.CAPACITIVE)",
              },
              {
                _key: "4382893c2fd3",
                _type: "block",
                children: [
                  {
                    _key: "48a264705b5c",
                    _type: "span",
                    marks: [
                    ],
                    text: "This changes it back to resistive touch:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6aff5dc43594",
                _type: "python",
                main: "from microbit import *\n\n\npin1.set_touch_mode(pin1.RESISTIVE)",
              },
            ],
            name: "Touch pins",
            slug: {
              _type: "slug",
              current: "touch-pins",
            },
          },
          {
            alternatives: [
              {
                _key: "d548d2f67de6",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f4b67f490bf8",
                    _type: "block",
                    children: [
                      {
                        _key: "5cdabb085fee",
                        _type: "span",
                        marks: [
                        ],
                        text: "To read the status of digital signals connected to pins 0, 1 or 2:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "9dc03ed829c2",
                    _type: "python",
                    main: "pin1.read_digital():",
                  },
                ],
                name: "input (read)",
                slug: {
                  _type: "slug",
                  current: "input-read",
                },
              },
              {
                _key: "4aa04b6913b8",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "63afc45837d5",
                    _type: "block",
                    children: [
                      {
                        _key: "cbab2b536e7e",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will send a digital 1 (on) signal to pin 1:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "1518d1685f73",
                    _type: "python",
                    main: "from microbit import *\n\n\npin1.write_digital(1)",
                  },
                  {
                    _key: "1e923354e974",
                    _type: "block",
                    children: [
                      {
                        _key: "f2986bd40364",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will turn it off:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "2709dcc4a3df",
                    _type: "python",
                    main: "from microbit import *\n\n\npin1.write_digital(0)",
                  },
                ],
                name: "output (write)",
                slug: {
                  _type: "slug",
                  current: "output-write",
                },
              },
            ],
            alternativesLabel: "Select input or output:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "403ecf8c45c9",
                _type: "block",
                children: [
                  {
                    _key: "06317b5c2bc9",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use pins 0, 1 and 2 in digital mode. This means they are either on (electricity is flowing through them, represented by the number 1) or off (no electricity is flowing through them, represented by the number 0).",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "6775da49bf8e",
                _type: "block",
                children: [
                  {
                    _key: "66cfcb3b5cfa",
                    _type: "span",
                    marks: [
                    ],
                    text: "You could connect a switch or button between the 3v pin and pin 1 and use this to test if the switch is open (0) or closed (1):",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a89ef48bd080",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    if pin1.read_digital():\n        display.show(1)\n    else:\n        display.show(0)",
              },
              {
                _key: "8d8fce4509b2",
                _type: "block",
                children: [
                  {
                    _key: "22687a5cb951",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "Never connect anything to the pins with a voltage over 3v or you may damage your micro:bit.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Digital input/output",
            slug: {
              _type: "slug",
              current: "digital-input-output",
            },
          },
          {
            alternatives: [
              {
                _key: "3261675fbba0",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "680acf1beb33",
                    _type: "block",
                    children: [
                      {
                        _key: "e0b3d92601fb0",
                        _type: "span",
                        marks: [
                        ],
                        text: "To read the status of analogue signals connected to pins 0, 1 or 2:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "17b0e5c520da",
                    _type: "python",
                    main: "display.scroll(pin1.read_analog())",
                  },
                  {
                    _key: "ea8519aacec9",
                    _type: "block",
                    children: [
                      {
                        _key: "cbceca8b7577",
                        _type: "span",
                        marks: [
                        ],
                        text: "If you attach a wire to the 3V pin and another wire to pin 1 and touch the other ends of the wires across different materials, you can measure how relatively conductive they are. A reading of 1023 would be the most conductive and 0 the least conductive (effectively an insulator).",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "d0f5ec18c9db",
                    _type: "block",
                    children: [
                      {
                        _key: "95c130d55ba60",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "Never connect anything to the pins with a voltage over 3v.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "input (read)",
                slug: {
                  _type: "slug",
                  current: "input-read",
                },
              },
              {
                _key: "be701276809f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2eada49924af",
                    _type: "block",
                    children: [
                      {
                        _key: "9ef3582450ca",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example varies the brightness of an LED connected to pin 1 and GND:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "abad3ea888cb",
                    _type: "python",
                    main: "from microbit import *\n\n\nfor i in range(1024):\n    pin1.write_analog(i)\n    sleep(3)",
                  },
                ],
                name: "output (write)",
                slug: {
                  _type: "slug",
                  current: "output-write",
                },
              },
            ],
            alternativesLabel: "Select input or output:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "b977940c3c1b",
                _type: "block",
                children: [
                  {
                    _key: "7d7d0802feec0",
                    _type: "span",
                    marks: [
                    ],
                    text: "Pins 0, 1 and 2 can work in analogue mode. This means instead of being off or on (0 or 1), they vary in value between 0 and 1023.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Analogue input/output",
            slug: {
              _type: "slug",
              current: "analogue-input-output",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-0849928d57a035e9b823d5d3ea6a8891fd44dad0-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "ac66220bb754",
            _type: "block",
            children: [
              {
                _key: "6eb4b43ea0bc",
                _type: "span",
                marks: [
                ],
                text: "The micro:bit has gold pins on its lower edge you can use to connect input and output devices like headphones, LEDs, switches, motors, sensors and other electronic components.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
          {
            _key: "bd6f7ac15fd9",
            _type: "block",
            children: [
              {
                _key: "3719efacd18c",
                _type: "span",
                marks: [
                ],
                text: "Pins 0, 1 and 2 are GPIO pins: general purpose input/output. There are ",
              },
              {
                _key: "9683feab4e02",
                _type: "span",
                marks: [
                  "2d5eb0317de6",
                ],
                text: "more pins",
              },
              {
                _key: "1d5b72115f4c",
                _type: "span",
                marks: [
                ],
                text: " that you can access with an edge-connector or breakout board.",
              },
            ],
            markDefs: [
              {
                _key: "2d5eb0317de6",
                _type: "toolkitApiLink",
                name: "microbit.pin4",
              },
            ],
            style: "normal",
          },
        ],
        name: "Pins",
        slug: {
          _type: "slug",
          current: "pins",
        },
        subtitle: "Expand your micro:bit",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "cc30b1e9a9fe",
                _type: "block",
                children: [
                  {
                    _key: "1b1c0d0f48d9",
                    _type: "span",
                    marks: [
                    ],
                    text: "Integers are whole numbers:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "4fb175a36197",
                _type: "python",
                main: "a = 23",
              },
            ],
            detailContent: [
              {
                _key: "977fc9c93469",
                _type: "block",
                children: [
                  {
                    _key: "d052ce20ca390",
                    _type: "span",
                    marks: [
                    ],
                    text: "In Python, you don’t have to declare a variable’s data type when you create it. Python will decide for you. In this example, simply declaring the variable ",
                  },
                  {
                    _key: "21c4c456ace1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "a",
                  },
                  {
                    _key: "492a4145bafd",
                    _type: "span",
                    marks: [
                    ],
                    text: " as a whole number means that ",
                  },
                  {
                    _key: "72376b25229f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "a",
                  },
                  {
                    _key: "fd4074eb85f7",
                    _type: "span",
                    marks: [
                    ],
                    text: " has the ",
                  },
                  {
                    _key: "dc5cb545a643",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "integer",
                  },
                  {
                    _key: "6803ef726f48",
                    _type: "span",
                    marks: [
                    ],
                    text: " data type.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Numbers: integers",
            slug: {
              _type: "slug",
              current: "numbers-integers",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "3931d40c2d61",
                _type: "block",
                children: [
                  {
                    _key: "f25ece6324f8",
                    _type: "span",
                    marks: [
                    ],
                    text: "Floats are floating point numbers, numbers that have decimal places:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "aaaf229a7773",
                _type: "python",
                main: "a = 17.42",
              },
            ],
            detailContent: [
              {
                _key: "d71d976bb0ae",
                _type: "block",
                children: [
                  {
                    _key: "332b32d3736e0",
                    _type: "span",
                    marks: [
                    ],
                    text: "In Python, you don’t have to declare a variable’s data type when you create it. Python will decide for you. In this example, simply declaring the variable ",
                  },
                  {
                    _key: "9944da370a7a",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "a",
                  },
                  {
                    _key: "a7905e45d843",
                    _type: "span",
                    marks: [
                    ],
                    text: " as a  number with decimal places means that it has the ",
                  },
                  {
                    _key: "ac3306f2be31",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "float",
                  },
                  {
                    _key: "8f1a540450df",
                    _type: "span",
                    marks: [
                    ],
                    text: " data type.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Numbers: floats",
            slug: {
              _type: "slug",
              current: "numbers-floats",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "100abf621172",
                _type: "block",
                children: [
                  {
                    _key: "4f49eb4dbf28",
                    _type: "span",
                    marks: [
                    ],
                    text: "Text or symbol data is stored in data types called strings. The content of a string must have quotation marks around it:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a6d55b42094a",
                _type: "python",
                main: "from microbit import *\n\n\na = 'micro:bit'\ndisplay.scroll(a)",
              },
            ],
            detailContent: [
              {
                _key: "6f67329567cf",
                _type: "block",
                children: [
                  {
                    _key: "63a0a9ddc6df0",
                    _type: "span",
                    marks: [
                    ],
                    text: "In Python, you don’t have to declare a variable’s data type when you create it. Python will decide for you. In this example, simply declaring the variable ",
                  },
                  {
                    _key: "ee65e67c3291",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "a",
                  },
                  {
                    _key: "5615522ddb5b",
                    _type: "span",
                    marks: [
                    ],
                    text: " as characters inside quotation marks means that it has the ",
                  },
                  {
                    _key: "92391191a239",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "string",
                  },
                  {
                    _key: "0d0499cbce73",
                    _type: "span",
                    marks: [
                    ],
                    text: " data type.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Text data: strings",
            slug: {
              _type: "slug",
              current: "text-data-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "45b9a6d683fd",
                _type: "block",
                children: [
                  {
                    _key: "116be4e0d8fe",
                    _type: "span",
                    marks: [
                      "1d013eda1289",
                    ],
                    text: "Booleans",
                  },
                  {
                    _key: "fb938dfb1ed4",
                    _type: "span",
                    marks: [
                    ],
                    text: " can only have two values, ",
                  },
                  {
                    _key: "49cb87018184",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "bb5dd98c05c9",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "fcd5a6a52c08",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "False",
                  },
                  {
                    _key: "14351db77659",
                    _type: "span",
                    marks: [
                    ],
                    text: ":",
                  },
                ],
                markDefs: [
                  {
                    _key: "1d013eda1289",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "adc59424-433f-4785-afba-8898be01d1e4",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "boolean-variables",
                    },
                    targetType: "toolkitTopicEntry",
                  },
                ],
                style: "normal",
              },
              {
                _key: "2bbc70923d64",
                _type: "python",
                main: "from microbit import *\n\n\nlove = True\ndisplay.scroll(love)",
              },
            ],
            detailContent: [
              {
                _key: "3ae29155358b",
                _type: "block",
                children: [
                  {
                    _key: "f7391ffb30620",
                    _type: "span",
                    marks: [
                    ],
                    text: "In Python, you don’t have to declare a variable’s data type when you create it. Python will decide for you. In this example, simply declaring the variable ",
                  },
                  {
                    _key: "0840652b98be",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "love",
                  },
                  {
                    _key: "2067cf6f2a1f",
                    _type: "span",
                    marks: [
                    ],
                    text: " as ",
                  },
                  {
                    _key: "89ff6d82ca2d",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "True",
                  },
                  {
                    _key: "018836fd6555",
                    _type: "span",
                    marks: [
                    ],
                    text: " means that it has the ",
                  },
                  {
                    _key: "f9900c848071",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "Boolean",
                  },
                  {
                    _key: "f0f21d03a69c",
                    _type: "span",
                    marks: [
                    ],
                    text: " data type. Note that True and False must start with capital letters.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Booleans",
            slug: {
              _type: "slug",
              current: "booleans",
            },
          },
          {
            alternatives: [
              {
                _key: "1625eb288781",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "7d8c8b8c587c",
                    _type: "block",
                    children: [
                      {
                        _key: "4302def06a77",
                        _type: "span",
                        marks: [
                        ],
                        text: "Convert a number (integer or float) to a string using ",
                      },
                      {
                        _key: "6bb504f899fa",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "str()",
                      },
                      {
                        _key: "8a06078f7dbe",
                        _type: "span",
                        marks: [
                        ],
                        text: ":",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "eee850b12b1a",
                    _type: "python",
                    main: "from microbit import *\n\nscore = 17\ndisplay.scroll('Score: ' + str(score))",
                  },
                  {
                    _key: "5443592a96a3",
                    _type: "block",
                    children: [
                      {
                        _key: "e4499dbbe875",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example converts the integer variable ",
                      },
                      {
                        _key: "09a83bc176f0",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "score",
                      },
                      {
                        _key: "ee54f180051d",
                        _type: "span",
                        marks: [
                        ],
                        text: " to a string so it can be joined to another string.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "number to string",
                slug: {
                  _type: "slug",
                  current: "number-to-string",
                },
              },
              {
                _key: "2ac5bb87fc6e",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "fffeb798684a",
                    _type: "block",
                    children: [
                      {
                        _key: "5bdbebb54cc9",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "c41d1c90abe3",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "int()",
                      },
                      {
                        _key: "e1cf70de2917",
                        _type: "span",
                        marks: [
                        ],
                        text: " to convert strings to integers:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "cddd19365d85",
                    _type: "python",
                    main: "from microbit import *\n\n\na = '6'\nb = int(a) * 7\ndisplay.scroll(b)",
                  },
                  {
                    _key: "d914fac42996",
                    _type: "block",
                    children: [
                      {
                        _key: "465082912905",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example turns the string ",
                      },
                      {
                        _key: "87b95b5a06cb",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "a",
                      },
                      {
                        _key: "cba6d1c4f5d6",
                        _type: "span",
                        marks: [
                        ],
                        text: " containing ’6’ into an integer so you can perform a mathematical operation on it.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "string to integer",
                slug: {
                  _type: "slug",
                  current: "string-to-integer",
                },
              },
              {
                _key: "7d58bbc89e16",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2b078b046e00",
                    _type: "block",
                    children: [
                      {
                        _key: "7bda0f37e5f9",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "0e9602cf68ef",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "float()",
                      },
                      {
                        _key: "9e3c0bd640f9",
                        _type: "span",
                        marks: [
                        ],
                        text: " to convert a string to a floating point number with decimals:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "c84e9be8ebc3",
                    _type: "python",
                    main: "a = '6.5'\ndisplay.scroll(float(a) * 2)",
                  },
                  {
                    _key: "6893ae235ab1",
                    _type: "block",
                    children: [
                      {
                        _key: "1319a79d3a1c",
                        _type: "span",
                        marks: [
                        ],
                        text: "This example shows 13.0 on the display.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "string to float",
                slug: {
                  _type: "slug",
                  current: "string-to-float",
                },
              },
              {
                _key: "59da85763834",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "e314d014af53",
                    _type: "block",
                    children: [
                      {
                        _key: "721338d4871d",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "72b4f76aa7cd",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "float()",
                      },
                      {
                        _key: "92ae8690e797",
                        _type: "span",
                        marks: [
                        ],
                        text: " to convert an integer to a floating point number:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "9b655a36ee1c",
                    _type: "python",
                    main: "from microbit import *\n\nscore = 17\ndisplay.scroll(float(score))",
                  },
                  {
                    _key: "cecd20df5434",
                    _type: "block",
                    children: [
                      {
                        _key: "36355f1501d3",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will show 17.0 on the display.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "integer to float",
                slug: {
                  _type: "slug",
                  current: "integer-to-float",
                },
              },
              {
                _key: "d47d5fcbabad",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "93408f93b536",
                    _type: "block",
                    children: [
                      {
                        _key: "d85d00e62181",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can convert a Boolean variable to a text string ‘True’ or ‘False’ using ",
                      },
                      {
                        _key: "258a76e0b261",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "str()",
                      },
                      {
                        _key: "1a531fd1eaf5",
                        _type: "span",
                        marks: [
                        ],
                        text: ":",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "53655c2d3fb2",
                    _type: "python",
                    main: "running = False\nstatus = str(running)",
                  },
                  {
                    _key: "923019cead0f",
                    _type: "block",
                    children: [
                      {
                        _key: "ba490e026cfd",
                        _type: "span",
                        marks: [
                        ],
                        text: "The string variable ",
                      },
                      {
                        _key: "731d86d3bd8a",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "status",
                      },
                      {
                        _key: "7e2512b8f30f",
                        _type: "span",
                        marks: [
                        ],
                        text: " now contains 'False'.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "Boolean to string",
                slug: {
                  _type: "slug",
                  current: "boolean-to-string",
                },
              },
              {
                _key: "947c20e29af6",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "c0bf21f89dcb",
                    _type: "block",
                    children: [
                      {
                        _key: "0539fa7cc7e7",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can convert Booleans to integers using ",
                      },
                      {
                        _key: "6dfc5170bae6",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "int()",
                      },
                      {
                        _key: "f6519b308c31",
                        _type: "span",
                        marks: [
                        ],
                        text: ":",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "bd865443fbdb",
                    _type: "python",
                    main: "from microbit import *\n\n\nrunning = True\nstatus = int(running)\ndisplay.scroll(status)",
                  },
                  {
                    _key: "496de4b0e54a",
                    _type: "block",
                    children: [
                      {
                        _key: "40809027359c",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "False",
                      },
                      {
                        _key: "8d46c7f4bce8",
                        _type: "span",
                        marks: [
                        ],
                        text: " becomes 0 and ",
                      },
                      {
                        _key: "2b4a883dafae",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "True",
                      },
                      {
                        _key: "c8fae97cab17",
                        _type: "span",
                        marks: [
                        ],
                        text: " becomes 1. In this example, 1 is displayed.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "Boolean to integer",
                slug: {
                  _type: "slug",
                  current: "boolean-to-integer",
                },
              },
            ],
            alternativesLabel: "Select conversion:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "9390c710f7d9",
                _type: "block",
                children: [
                  {
                    _key: "b58e7ec839b0",
                    _type: "span",
                    marks: [
                    ],
                    text: "Sometimes you need to convert data from one type to another. This is also called casting.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Converting data types",
            slug: {
              _type: "slug",
              current: "converting-data-types",
            },
          },
          {
            alternatives: [
              {
                _key: "236ca7cdef55",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d461677a54e7",
                    _type: "python",
                    main: "from microbit import *\n\n\na = 23\nif type(a) is int:\n    display.scroll('a is an integer')",
                  },
                ],
                name: "integer",
                slug: {
                  _type: "slug",
                  current: "integer",
                },
              },
              {
                _key: "eff9582b4d5b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "8b2c81cca7c4",
                    _type: "python",
                    main: "from microbit import *\n\n\nb = 17.42\nif type(b) is float:\n    display.scroll('b is a floating point number')",
                  },
                ],
                name: "float",
                slug: {
                  _type: "slug",
                  current: "float",
                },
              },
              {
                _key: "282dac65bc21",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d550ab020ad8",
                    _type: "python",
                    main: "from microbit import *\n\n\nc = 'micro:bit'\nif type(c) is str:\n    display.scroll('c is a string')",
                  },
                ],
                name: "string",
                slug: {
                  _type: "slug",
                  current: "string",
                },
              },
              {
                _key: "e43cae0acd67",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "84d3827d7be8",
                    _type: "block",
                    children: [
                      {
                        _key: "cb9920a145dc",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "c131c65c1f5f",
                    _type: "python",
                    main: "from microbit import *\n\n\nd = False\nif type(d) is bool:\n    display.scroll('d is a Boolean')",
                  },
                ],
                name: "Boolean",
                slug: {
                  _type: "slug",
                  current: "boolean",
                },
              },
            ],
            alternativesLabel: "Select data type:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "1cecea4cf382",
                _type: "block",
                children: [
                  {
                    _key: "0881670e2e98",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can test what data type a variable is with ",
                  },
                  {
                    _key: "3b93943be3c2",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "type().",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "7afe5678c00b",
                _type: "block",
                children: [
                  {
                    _key: "3d62a05d835b",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can also display a variable’s data type. This will scroll <class 'int'> on the LED display:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "88c98be28018",
                _type: "python",
                main: "a = 23\ndisplay.scroll(str(type(a)))",
              },
            ],
            name: " Testing data types",
            slug: {
              _type: "slug",
              current: "testing-data-types",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-d93ac281c0a4ee4f205b534f529574fe62a49223-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "9671525123bf",
            _type: "block",
            children: [
              {
                _key: "9bf991f03d60",
                _type: "span",
                marks: [
                ],
                text: "There are different data types for storing different kinds of information, such as whole numbers, decimals, and text. Sometimes you may need to convert data from one type to another.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Data types",
        slug: {
          _type: "slug",
          current: "data-types",
        },
        subtitle: "Ways of storing different data in Python",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "f51a4247d2d5",
                _type: "block",
                children: [
                  {
                    _key: "8b75543676d1",
                    _type: "span",
                    marks: [
                    ],
                    text: "Join, or concatenate, strings with the + sign:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "21ef3cda1348",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('hello ' + 'world')",
              },
            ],
            name: "Joining strings",
            slug: {
              _type: "slug",
              current: "joining-strings",
            },
          },
          {
            alternatives: [
              {
                _key: "0529072bdbe4",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "a9e2230e9748",
                    _type: "block",
                    children: [
                      {
                        _key: "0573002cc392",
                        _type: "span",
                        marks: [
                        ],
                        text: "This prints from character 1 to 3, so ‘el’ is displayed:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "de2900fc22b2",
                    _type: "python",
                    main: "from microbit import *\ndisplay.scroll('hello'[1:3])",
                  },
                ],
                name: "range",
                slug: {
                  _type: "slug",
                  current: "range",
                },
              },
              {
                _key: "966d2de88221",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "42ff4b022c4a",
                    _type: "block",
                    children: [
                      {
                        _key: "a2a2223c2a63",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will display ‘h’ because it is the first character in the string, counting from 0:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "a987107f77b6",
                    _type: "python",
                    main: "from microbit import *\n\ndisplay.scroll('hello'[0])",
                  },
                ],
                name: "single character",
                slug: {
                  _type: "slug",
                  current: "single-character",
                },
              },
              {
                _key: "761f544e0312",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "39c34f76ae5d",
                    _type: "block",
                    children: [
                      {
                        _key: "3e75911fa596",
                        _type: "span",
                        marks: [
                        ],
                        text: "This will display everything from character 2 onwards, so ‘llo’ is shown:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "6262664ba831",
                    _type: "python",
                    main: "from microbit import *\n\ndisplay.scroll('hello'[2:])",
                  },
                ],
                name: "after",
                slug: {
                  _type: "slug",
                  current: "after",
                },
              },
              {
                _key: "62626c9aa8f1",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "1fe268c46456",
                    _type: "block",
                    children: [
                      {
                        _key: "2a6a860ecc8f",
                        _type: "span",
                        marks: [
                        ],
                        text: "This displays everything before character 2, so ‘he’ is shown:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "a13c99be8a3d",
                    _type: "python",
                    main: "from microbit import *\n\ndisplay.scroll('hello'[:2])",
                  },
                ],
                name: "before",
                slug: {
                  _type: "slug",
                  current: "before",
                },
              },
            ],
            alternativesLabel: "Slice type:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "14c566b12155",
                _type: "block",
                children: [
                  {
                    _key: "52ecfd960208",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can slice strings using the numerical position of characters, counting from 0.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "bd8d3b11dbab",
                _type: "block",
                children: [
                  {
                    _key: "fb20f0f308e30",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can slice string ",
                  },
                  {
                    _key: "fb20f0f308e31",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "variables",
                  },
                  {
                    _key: "fb20f0f308e32",
                    _type: "span",
                    marks: [
                    ],
                    text: " in the same way. This slices the string from character position 1 to 3 and shows ‘el’:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d47a024ea5d3",
                _type: "python",
                main: "from microbit import *\n\nword = 'hello'\ndisplay.scroll(word[1:3])",
              },
            ],
            name: "Slicing strings",
            slug: {
              _type: "slug",
              current: "slicing-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "a84e75b64f8b",
                _type: "block",
                children: [
                  {
                    _key: "fdd137fed2c8",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can repeat strings by multiplying them:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "2285c4e5c7c0",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('hello' * 3)",
              },
            ],
            detailContent: [
              {
                _key: "4ac9191bbc2d",
                _type: "block",
                children: [
                  {
                    _key: "1698e2ef0d42",
                    _type: "span",
                    marks: [
                    ],
                    text: "The example above scrolls ‘hello’ on the LED display 3 times with no spaces.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "52c4131faab1",
                _type: "block",
                children: [
                  {
                    _key: "c15d971eac9d",
                    _type: "span",
                    marks: [
                    ],
                    text: "Multiplying strings like this can be useful for printing simple bar charts in the ",
                  },
                  {
                    _key: "c03bee27ccac",
                    _type: "span",
                    marks: [
                      "c1e9f23a4d9c",
                    ],
                    text: "serial console",
                  },
                  {
                    _key: "3bef598d5fc4",
                    _type: "span",
                    marks: [
                    ],
                    text: ":",
                  },
                ],
                markDefs: [
                  {
                    _key: "c1e9f23a4d9c",
                    _type: "toolkitInternalLink",
                    reference: {
                      _ref: "ac316d5a-cd2f-455b-af69-6f2107d52828",
                      _type: "reference",
                    },
                    slug: {
                      _type: "slug",
                      current: "open-the-serial-console",
                    },
                    targetType: "toolkitTopicEntry",
                  },
                ],
                style: "normal",
              },
              {
                _key: "ab86670d3613",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    print('X' * temperature())\n    sleep(1000)",
              },
            ],
            name: "Multiplying strings",
            slug: {
              _type: "slug",
              current: "multiplying-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "c765c0f47e51",
                _type: "block",
                children: [
                  {
                    _key: "f22d6f68f108",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "dc8da7d170e7",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "replace()",
                  },
                  {
                    _key: "5e0ed866e75e",
                    _type: "span",
                    marks: [
                    ],
                    text: " to swap out sections of a string by their contents:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3d9a62bcde83",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('Hello world'.replace('Hello', 'Hola'))",
              },
              {
                _key: "a76baa82691a",
                _type: "block",
                children: [
                  {
                    _key: "da2ad34e0338",
                    _type: "span",
                    marks: [
                    ],
                    text: "This shows ‘Hola world’.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Replacing parts of strings",
            slug: {
              _type: "slug",
              current: "replacing-parts-of-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "ffbf413d4a0b",
                _type: "block",
                children: [
                  {
                    _key: "f00b829ea8fe",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "1155edab3a9f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "strip()",
                  },
                  {
                    _key: "e5ecadb2e39b",
                    _type: "span",
                    marks: [
                    ],
                    text: " to trim characters from the start or end of a string:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a116828be64f",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('wowow'.strip('w'))",
              },
              {
                _key: "5928df447c67",
                _type: "block",
                children: [
                  {
                    _key: "b3fe380f5cc6",
                    _type: "span",
                    marks: [
                    ],
                    text: "This removes ‘w’ from the start and end of the string, so ‘owo’ is shown.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "e09837007107",
                _type: "block",
                children: [
                  {
                    _key: "5498fa39b324",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can also use this function to strip out spaces at the start or end of a string. ",
                  },
                  {
                    _key: "8ce59d48d0a1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "lstrip()",
                  },
                  {
                    _key: "cfbd59d73b18",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "528eeb31c4b6",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "rstrip()",
                  },
                  {
                    _key: "bf6706d90f9a",
                    _type: "span",
                    marks: [
                    ],
                    text: " will also trim spaces from the left or right of a string.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Trimming strings",
            slug: {
              _type: "slug",
              current: "trimming-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "323f107e4f8d",
                _type: "block",
                children: [
                  {
                    _key: "96069b180708",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "d7a6910c7707",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "len()",
                  },
                  {
                    _key: "3a167fd26c38",
                    _type: "span",
                    marks: [
                    ],
                    text: " to find the length of a string:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "30299809b954",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll(len('hello'))",
              },
              {
                _key: "2e41cc76f4c0",
                _type: "block",
                children: [
                  {
                    _key: "c8ae31efa12a",
                    _type: "span",
                    marks: [
                    ],
                    text: "This shows 5 because the string is 5 characters long.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Length of a string",
            slug: {
              _type: "slug",
              current: "length-of-a-string",
            },
          },
          {
            alternatives: [
              {
                _key: "bd81abd032cd",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "52e4c83555aa",
                    _type: "block",
                    children: [
                      {
                        _key: "b4d147572ce6",
                        _type: "span",
                        marks: [
                        ],
                        text: "This shows ‘HELLO’:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "2aba04ed746a",
                    _type: "python",
                    main: "display.scroll('hello'.upper())",
                  },
                ],
                name: "upper case",
                slug: {
                  _type: "slug",
                  current: "upper-case",
                },
              },
              {
                _key: "52e80a8e48e9",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "302c4f9d9bc8",
                    _type: "block",
                    children: [
                      {
                        _key: "f79833cd58b2",
                        _type: "span",
                        marks: [
                        ],
                        text: "This shows 'hello':",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "8fd3932caacb",
                    _type: "python",
                    main: "from microbit import *\n\ndisplay.scroll('HELLO'.lower())",
                  },
                ],
                name: "lower case",
                slug: {
                  _type: "slug",
                  current: "lower-case",
                },
              },
            ],
            alternativesLabel: "Select conversion:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "4fb198db212d",
                _type: "block",
                children: [
                  {
                    _key: "ba17c4d738fb",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can convert strings to upper or lower case.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Convert case",
            slug: {
              _type: "slug",
              current: "convert-case",
            },
          },
          {
            alternatives: [
              {
                _key: "295b4e1d28a9",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "61594e2515f7",
                    _type: "block",
                    children: [
                      {
                        _key: "6988261268fc",
                        _type: "span",
                        marks: [
                        ],
                        text: "You can locate the position of the first occurrence of a character or set of characters with ",
                      },
                      {
                        _key: "bdba0059f365",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "find()",
                      },
                      {
                        _key: "c98774401f61",
                        _type: "span",
                        marks: [
                        ],
                        text: ".",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "fb0789f93df9",
                    _type: "block",
                    children: [
                      {
                        _key: "1eab3370daa1",
                        _type: "span",
                        marks: [
                        ],
                        text: "Here 1 is shown, because the first ‘i' is at character position 1.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "5db8d67632b0",
                    _type: "python",
                    main: "from microbit import *\n\ndisplay.scroll('micro:bit'.find('i'))",
                  },
                ],
                name: "first occurrence",
                slug: {
                  _type: "slug",
                  current: "first-occurrence",
                },
              },
              {
                _key: "91ce4426ce61",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f60dd926adf1",
                    _type: "block",
                    children: [
                      {
                        _key: "a528fc69e056",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "d3c72e1522df",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "rfind()",
                      },
                      {
                        _key: "2e56f4d54e08",
                        _type: "span",
                        marks: [
                        ],
                        text: " to find the last occurrence of a character or set of characters.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "ddc20ce01d8d",
                    _type: "block",
                    children: [
                      {
                        _key: "6ad19940e034",
                        _type: "span",
                        marks: [
                        ],
                        text: "Here 7 is shown, because the last occurrence ‘i’ is at character position 7:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "382e78d960b0",
                    _type: "python",
                    main: "from microbit import *\n\ndisplay.scroll('micro:bit'.rfind('i'))",
                  },
                ],
                name: "last occurrence",
                slug: {
                  _type: "slug",
                  current: "last-occurrence",
                },
              },
            ],
            alternativesLabel: "Select location:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "6bd8658a38b8",
                _type: "block",
                children: [
                  {
                    _key: "62a4ca089ddc",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can find where the first and last occurrences of a character (or characters) are.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "05a272c3773b",
                _type: "block",
                children: [
                  {
                    _key: "eb9de4844ce40",
                    _type: "span",
                    marks: [
                    ],
                    text: "If nothing is found using ",
                  },
                  {
                    _key: "0868e16fed74",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "find()",
                  },
                  {
                    _key: "03eff3792f8b",
                    _type: "span",
                    marks: [
                    ],
                    text: " or ",
                  },
                  {
                    _key: "74e804f8e7ee",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "rfind()",
                  },
                  {
                    _key: "c6cdc587dcfc",
                    _type: "span",
                    marks: [
                    ],
                    text: ", -1 is returned.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Searching strings",
            slug: {
              _type: "slug",
              current: "searching-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "8069aee6c9e4",
                _type: "block",
                children: [
                  {
                    _key: "51279a11a2970",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "cca1af8a2e32",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "count()",
                  },
                  {
                    _key: "1ff1d2e3d86e",
                    _type: "span",
                    marks: [
                    ],
                    text: " to count the number of times a character or set of characters appear in a string:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "289186b0879e",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('micro:bit'.count('i'))\n",
              },
            ],
            detailContent: [
              {
                _key: "15447a81222d",
                _type: "block",
                children: [
                  {
                    _key: "1dd0f967092b",
                    _type: "span",
                    marks: [
                    ],
                    text: "This will show 2 because the letter 'i' occurs twice in 'micro:bit.'",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b7e659f7dd7d",
                _type: "block",
                children: [
                  {
                    _key: "5e9008e5ca8e0",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can also use ",
                  },
                  {
                    _key: "d03782edb4fa",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "count()",
                  },
                  {
                    _key: "65ec3039d296",
                    _type: "span",
                    marks: [
                    ],
                    text: " to count the number of times a word appears in a string. This will show 2:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6f6d1051600b",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll('The rain it raineth every day'.count('rain'))",
              },
            ],
            name: " Counting occurrences",
            slug: {
              _type: "slug",
              current: "counting-occurrences",
            },
          },
          {
            alternatives: [
              {
                _key: "e90f90d9d06b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "2e6165fb6b36",
                    _type: "python",
                    main: "from microbit import *\n\nif 'hello'.islower():\n    display.scroll('lower case')",
                  },
                  {
                    _key: "5bb3ca8df3b0",
                    _type: "block",
                    children: [
                      {
                        _key: "5fb3e10de977",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "lower case",
                slug: {
                  _type: "slug",
                  current: "lower-case",
                },
              },
              {
                _key: "a471c8c55e07",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b4557ccdd26e",
                    _type: "python",
                    main: "from microbit import *\n \nif 'HELLO'.isupper():\n    display.scroll('upper case')",
                  },
                ],
                name: "upper case",
                slug: {
                  _type: "slug",
                  current: "upper-case",
                },
              },
              {
                _key: "f92b35337cf5",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "fb56a941aa74",
                    _type: "python",
                    main: "from microbit import *\n \nif 'hello'.isalpha():\n    display.scroll('alphabetical')",
                  },
                ],
                name: "alphabetical",
                slug: {
                  _type: "slug",
                  current: "alphabetical",
                },
              },
              {
                _key: "a6ce41ebe76b",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "9ebdb736ded6",
                    _type: "python",
                    main: "from microbit import *\n \nif '42'.isdigit():\n    display.scroll('digits')",
                  },
                ],
                name: "digits",
                slug: {
                  _type: "slug",
                  current: "digits",
                },
              },
              {
                _key: "fec61a9e38a4",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b96e803498b2",
                    _type: "python",
                    main: "from microbit import *\n \nif ' '.isspace():\n    display.scroll('space')",
                  },
                ],
                name: "space",
                slug: {
                  _type: "slug",
                  current: "space",
                },
              },
              {
                _key: "c3f44dc2d11a",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "8f83e2f23859",
                    _type: "python",
                    main: "from microbit import *\n \nif 'lo' in 'hello':\n    display.scroll('contains lo')",
                  },
                ],
                name: "contains",
                slug: {
                  _type: "slug",
                  current: "contains",
                },
              },
              {
                _key: "8d6877f3f639",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "d39fe8a3b730",
                    _type: "python",
                    main: "from microbit import *\n \nif 'hello'.startswith('h'):\n    display.scroll('starts with h')",
                  },
                ],
                name: "starts with",
                slug: {
                  _type: "slug",
                  current: "starts-with",
                },
              },
              {
                _key: "31544ed1e82a",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "6721c56f0fe5",
                    _type: "python",
                    main: "from microbit import *\n \nif 'hello'.endswith('o'):\n    display.scroll('ends with o')",
                  },
                ],
                name: "ends with",
                slug: {
                  _type: "slug",
                  current: "ends-with",
                },
              },
            ],
            alternativesLabel: "Select test",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "d14faac69e90",
                _type: "block",
                children: [
                  {
                    _key: "4f1d677be5b8",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can test strings to discover what kind of characters they contain, or if they contain certain characters.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "837a65fcdbe1",
                _type: "block",
                children: [
                  {
                    _key: "89c24021934c0",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can test string variables the same way:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b394ba55f711",
                _type: "python",
                main: "from microbit import *\n \nmyString = 'hello'\nif myString.islower():\n    display.scroll('lower case')",
              },
            ],
            name: " Testing strings",
            slug: {
              _type: "slug",
              current: "testing-strings",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "befdaffb6d85",
                _type: "block",
                children: [
                  {
                    _key: "853394930cb0",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can put placeholders in strings that are then replaced with the values of variables:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "2d6343065f34",
                _type: "python",
                main: "from microbit import *\n\nname = 'Hana'\nscore = 17\ntime = 23.67\ndisplay.scroll('Hi %s you scored %i points in %1.2f seconds' % (name, score, time))",
              },
            ],
            detailContent: [
              {
                _key: "2df402cb6dd3",
                _type: "block",
                children: [
                  {
                    _key: "1b82bc4c0192",
                    _type: "span",
                    marks: [
                    ],
                    text: "The output in this example is ‘Hi Hana you scored 17 points in 23.67 seconds’",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "7b95d1897685",
                _type: "block",
                children: [
                  {
                    _key: "6348cbfcd519",
                    _type: "span",
                    marks: [
                    ],
                    text: "It uses ",
                  },
                  {
                    _key: "97454790da37",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "%1.2f",
                  },
                  {
                    _key: "1d3929c1fd35",
                    _type: "span",
                    marks: [
                    ],
                    text: " to specify that two decimal places should be displayed.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "a2d28e11b4f5",
                _type: "block",
                children: [
                  {
                    _key: "30a043fed2ef",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "c4aeac301251",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "%s",
                  },
                  {
                    _key: "12daccae8ee0",
                    _type: "span",
                    marks: [
                    ],
                    text: " for strings, ",
                  },
                  {
                    _key: "11d433f16757",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "%i",
                  },
                  {
                    _key: "3762a8ec4f4d",
                    _type: "span",
                    marks: [
                    ],
                    text: " for integers and ",
                  },
                  {
                    _key: "c831393efad0",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "%f",
                  },
                  {
                    _key: "1ca5f14faa63",
                    _type: "span",
                    marks: [
                    ],
                    text: " for floating point numbers. ",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Placeholders",
            slug: {
              _type: "slug",
              current: "placeholders",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "c4de63e600d4",
                _type: "block",
                children: [
                  {
                    _key: "609d1f64d88a",
                    _type: "span",
                    marks: [
                    ],
                    text: "ASCII is a system used for encoding letters and symbols as numbers when they’re stored in a computer system.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "b60672b6c300",
                _type: "block",
                children: [
                  {
                    _key: "e9f9331b2a45",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "13432fb3a6fc",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "ord()",
                  },
                  {
                    _key: "08ce8a773cc4",
                    _type: "span",
                    marks: [
                    ],
                    text: " to discover the ASCII value of a character:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "333869a47cee",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll(ord('a'))",
              },
            ],
            detailContent: [
              {
                _key: "f52c9504725c",
                _type: "block",
                children: [
                  {
                    _key: "2f578ed3dbc9",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example 97 is shown because the letter ‘a’ is encoded in ASCII with the number 97.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "712bd97680b3",
                _type: "block",
                children: [
                  {
                    _key: "deeb3800d815",
                    _type: "span",
                    marks: [
                      "077a4155134d",
                    ],
                    text: "Read more about ASCII",
                  },
                  {
                    _key: "9288bd174f02",
                    _type: "span",
                    marks: [
                    ],
                    text: ".  ",
                  },
                ],
                markDefs: [
                  {
                    _key: "077a4155134d",
                    _type: "link",
                    href: "https://www.bbc.co.uk/bitesize/guides/zscvxfr/revision/4",
                  },
                ],
                style: "normal",
              },
            ],
            name: " ASCII value of character",
            slug: {
              _type: "slug",
              current: "ascii-value-of-character",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "872a215deb3a",
                _type: "block",
                children: [
                  {
                    _key: "2fdfe1dc4abe",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "2c7b27984bc1",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "chr()",
                  },
                  {
                    _key: "44db5ec508a8",
                    _type: "span",
                    marks: [
                    ],
                    text: " to convert a numerical ASCII value to a character string:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "933ceb8c0139",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll(chr(98))",
              },
            ],
            detailContent: [
              {
                _key: "5f98590d67fb",
                _type: "block",
                children: [
                  {
                    _key: "b81dcf787020",
                    _type: "span",
                    marks: [
                    ],
                    text: "In this example the letter ‘b’ is shown.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "bfd23060afaf",
                _type: "block",
                children: [
                  {
                    _key: "b33ec269d22c",
                    _type: "span",
                    marks: [
                    ],
                    text: "Using ",
                  },
                  {
                    _key: "bbcbaca9173f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "chr()",
                  },
                  {
                    _key: "cb866bcc1d69",
                    _type: "span",
                    marks: [
                    ],
                    text: " and ",
                  },
                  {
                    _key: "447ddf62d7f7",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "ord()",
                  },
                  {
                    _key: "b4a845af588f",
                    _type: "span",
                    marks: [
                    ],
                    text: " in Python programs can be useful when making codes like Caesar ciphers.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "02cf1d6ed5bc",
                _type: "block",
                children: [
                  {
                    _key: "417b5b972df1",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example shifts the letter ‘a’ along 5 places in the alphabet so it becomes ‘f’:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "cc8e0bc294a4",
                _type: "python",
                main: "from microbit import *\n\ndisplay.scroll(chr(ord('a') + 5))",
              },
              {
                _key: "6415ad8feddc",
                _type: "block",
                children: [
                  {
                    _key: "a427ed735c770",
                    _type: "span",
                    marks: [
                    ],
                    text: "The following example encodes the string ‘hello’ with an offset of 13. This kind of Caesar cipher is also known as ROT13. You can use the same code to encode and decode messages:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "3e3fb5c75403",
                _type: "python",
                main: "from microbit import *\n\nplaintext = 'hello'\n \nfor letter in plaintext:\n    if ord(letter) > 109:\n        offset = -13\n    else:\n        offset = 13\n    display.scroll(chr(ord(letter) + offset))",
              },
            ],
            name: "Print character from ASCII value",
            slug: {
              _type: "slug",
              current: "print-character-from-ascii-value",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-6fd6b41ab4dc9dcae1d7a45c3c487c774f530ad5-144x144-svg",
            _type: "reference",
          },
        },
        name: " String manipulation",
        slug: {
          _type: "slug",
          current: "string-manipulation",
        },
        subtitle: "Working with text in Python",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "06f50ccb4d19",
                _type: "simpleImage",
                alt: "Screen shot locating the 'show serial' button in the editor.",
                asset: {
                  _ref: "image-c25d478692de44fb8ec6c71b9e5d1f9e20e2e674-826x146-png",
                  _type: "reference",
                },
              },
              {
                _key: "09421cb8e343",
                _type: "block",
                children: [
                  {
                    _key: "349dbc9e4eb50",
                    _type: "span",
                    marks: [
                    ],
                    text: "Plug your micro:bit into your computer’s USB socket, click on ‘connect’ then pair your micro:bit. When you flash a Python program to your micro:bit, you can access the serial console by clicking ‘Show serial’.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Open the serial console",
            slug: {
              _type: "slug",
              current: "open-the-serial-console",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "46c977d67eed",
                _type: "block",
                children: [
                  {
                    _key: "50fa88f8bce4",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can output text or numbers to the serial console using the ",
                  },
                  {
                    _key: "02b29ad6694e",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "print()",
                  },
                  {
                    _key: "aea0e5a05ac6",
                    _type: "span",
                    marks: [
                    ],
                    text: " function:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d2716b172e92",
                _type: "python",
                main: "from microbit import *\n\n\nwhile True:\n    print(display.read_light_level())\n    sleep(1000)",
              },
            ],
            detailContent: [
              {
                _key: "0e1754b0808f",
                _type: "block",
                children: [
                  {
                    _key: "feceba018e8a",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example prints the current light level once a second.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "2aafea1f49a0",
                _type: "block",
                children: [
                  {
                    _key: "66de4201526a",
                    _type: "span",
                    marks: [
                    ],
                    text: "This can be really useful when debugging a program, or if you want to discover what ranges of sensor readings you get in a particular situation.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Print output to the serial console",
            slug: {
              _type: "slug",
              current: "print-output-to-the-serial-console",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "17046d0441f2",
                _type: "block",
                children: [
                  {
                    _key: "02bfa5faeae7",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can interact with Python micro:bit programs by entering text on your computer keyboard:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "145d11d814a9",
                _type: "python",
                main: "name = input('What is your name? ')\nprint('Hello', name)",
              },
            ],
            detailContent: [
              {
                _key: "a25a6b381e0b",
                _type: "block",
                children: [
                  {
                    _key: "626b99464a27",
                    _type: "span",
                    marks: [
                    ],
                    text: "This example asks your name. When you click in the serial console, type your name and press enter, it will greet you.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Keyboard input",
            slug: {
              _type: "slug",
              current: "keyboard-input",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "4053ce501d34",
                _type: "block",
                children: [
                  {
                    _key: "e8fee8a59b640",
                    _type: "span",
                    marks: [
                    ],
                    text: "You can use the serial console to type and execute Python commands directly on your micro:bit.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "85b11fcaddab",
                _type: "simpleImage",
                alt: "Screenshot of the REPL being used in the serial console.",
                asset: {
                  _ref: "image-dca073d9bb995d67c74194bad9a50ba70f34eaf4-843x253-png",
                  _type: "reference",
                },
              },
              {
                _key: "28184319b940",
                _type: "block",
                children: [
                  {
                    _key: "e4adecbab5da",
                    _type: "span",
                    marks: [
                    ],
                    text: "",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            detailContent: [
              {
                _key: "8e26ef902de9",
                _type: "block",
                children: [
                  {
                    _key: "58f2927ba5c7",
                    _type: "span",
                    marks: [
                    ],
                    text: "REPL stands for Read Evaluate Print Loop: the micro:bit reads your instruction, evaluates it, then prints the result in the serial console.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "faf36155661d",
                _type: "block",
                children: [
                  {
                    _key: "91b1191c0b0c",
                    _type: "span",
                    marks: [
                    ],
                    text: "Having connected your micro:bit and flashed a Python program to it, break out of the program by pressing crtl+C to access the command prompt ",
                  },
                  {
                    _key: "e4adecbab5da",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: ">>>",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "fefab1a42bc8",
                _type: "block",
                children: [
                  {
                    _key: "4a9fcdc11104",
                    _type: "span",
                    marks: [
                    ],
                    text: "Type ",
                  },
                  {
                    _key: "9e37e46d6ea0",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "help()",
                  },
                  {
                    _key: "c6d5d35cb465",
                    _type: "span",
                    marks: [
                    ],
                    text: " to get some ideas about what you can do.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "REPL",
            slug: {
              _type: "slug",
              current: "repl",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-f418e2293bd1a2c4e0f57fe8d7fea4d40929f1de-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "71e6a9652cf8",
            _type: "block",
            children: [
              {
                _key: "4eb20e73ac94",
                _type: "span",
                marks: [
                ],
                text: "If you’re using a Chrome or Edge web browser, you can access a serial console here in the editor. A serial console allows you to print information from your micro:bit on your computer screen, and use your computer’s keyboard to input information into your Python programs.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Text input and output",
        slug: {
          _type: "slug",
          current: "text-input-and-output",
        },
        subtitle: "Use your computer’s keyboard & screen in micro:bit programs",
      },
      {
        compatibility: [
          "microbitV1",
          "microbitV2",
        ],
        contents: [
          {
            alternatives: [
              {
                _key: "690196fbb997",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "712a16a034e8",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\ndisplay.scroll(pi)",
                  },
                  {
                    _key: "0beab25a24da",
                    _type: "block",
                    children: [
                      {
                        _key: "46f638aab3020",
                        _type: "span",
                        marks: [
                        ],
                        text: "Pi will be shown as 3.141593",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "π (pi)",
                slug: {
                  _type: "slug",
                  current: "π-pi",
                },
              },
              {
                _key: "8acc9979a393",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "aa6d384c3175",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\ndisplay.scroll(e)",
                  },
                  {
                    _key: "bc8037fae499",
                    _type: "block",
                    children: [
                      {
                        _key: "5c3e24051dc90",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "e",
                      },
                      {
                        _key: "a2b1895200ac",
                        _type: "span",
                        marks: [
                        ],
                        text: " will be shown as 2.718282",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                ],
                name: "e",
                slug: {
                  _type: "slug",
                  current: "e",
                },
              },
            ],
            alternativesLabel: "Select constant:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "d7daffb7010d",
                _type: "block",
                children: [
                  {
                    _key: "72d5cebd2dfa",
                    _type: "span",
                    marks: [
                    ],
                    text: "Mathematical constants ",
                  },
                  {
                    _key: "0f22419884db",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "π",
                  },
                  {
                    _key: "f730122187d4",
                    _type: "span",
                    marks: [
                    ],
                    text: " (pi) and ",
                  },
                  {
                    _key: "841c78a1f2ef",
                    _type: "span",
                    marks: [
                      "strong",
                    ],
                    text: "e",
                  },
                  {
                    _key: "21008d62fe62",
                    _type: "span",
                    marks: [
                    ],
                    text: " can be used.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Constants",
            slug: {
              _type: "slug",
              current: "constants",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "ed95855b11c1",
                _type: "block",
                children: [
                  {
                    _key: "1e027e7b1915",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "bd7f2400de50",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "sqrt()",
                  },
                  {
                    _key: "798225a11125",
                    _type: "span",
                    marks: [
                    ],
                    text: " to calculate the square root of a number.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "9ea5bcf908b7",
                _type: "python",
                main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(sqrt(25))",
              },
            ],
            name: "Square root",
            slug: {
              _type: "slug",
              current: "square-root",
            },
          },
          {
            alternatives: [
              {
                _key: "d4d31dea1947",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "105ac8066dc1",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(cos(1))",
                  },
                ],
                name: "cos",
                slug: {
                  _type: "slug",
                  current: "cos",
                },
              },
              {
                _key: "32d959038a5e",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "68ced0ba1d7d",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(sin(1))",
                  },
                ],
                name: "sin",
                slug: {
                  _type: "slug",
                  current: "sin",
                },
              },
              {
                _key: "34ebf94afb96",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "92125cc77418",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(tan(1))",
                  },
                ],
                name: "tan",
                slug: {
                  _type: "slug",
                  current: "tan",
                },
              },
              {
                _key: "57902ac632b7",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "9c5157e2d354",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(acos(1))",
                  },
                ],
                name: "acos",
                slug: {
                  _type: "slug",
                  current: "acos",
                },
              },
              {
                _key: "f1b4c2d28df7",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "81079dfa9e52",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(asin(1))",
                  },
                ],
                name: "asin",
                slug: {
                  _type: "slug",
                  current: "asin",
                },
              },
              {
                _key: "b9efbaba6072",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "df3d640b2328",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(atan(1))",
                  },
                ],
                name: "atan",
                slug: {
                  _type: "slug",
                  current: "atan",
                },
              },
              {
                _key: "d7340a092918",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "188800471c64",
                    _type: "block",
                    children: [
                      {
                        _key: "2eb7e23c73b3",
                        _type: "span",
                        marks: [
                        ],
                        text: "",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "6cf894beb359",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\ndisplay.scroll(atan2(1,1))",
                  },
                ],
                name: "atan2",
                slug: {
                  _type: "slug",
                  current: "atan2",
                },
              },
            ],
            alternativesLabel: "Select function:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            detailContent: [
              {
                _key: "2a4a8a7c2421",
                _type: "block",
                children: [
                  {
                    _key: "baf266e9175e0",
                    _type: "span",
                    marks: [
                    ],
                    text: "Sine (",
                  },
                  {
                    _key: "5fb7d13f4dfd",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "sin",
                  },
                  {
                    _key: "1d1de7cfd207",
                    _type: "span",
                    marks: [
                    ],
                    text: "), cosine (",
                  },
                  {
                    _key: "b6d1520d3885",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "cos",
                  },
                  {
                    _key: "32882e26a543",
                    _type: "span",
                    marks: [
                    ],
                    text: ") and tangent (",
                  },
                  {
                    _key: "3fddbbff35d8",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "tan",
                  },
                  {
                    _key: "e8c87d6588c9",
                    _type: "span",
                    marks: [
                    ],
                    text: ") are trigonometric functions that represent ratios of pairs of sides of a right-angled triangle. The functions accept values in radians so if your angle is in degrees, it will need converting first using the ",
                  },
                  {
                    _key: "bc2986788351",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "math.radians()",
                  },
                  {
                    _key: "a76a482237e7",
                    _type: "span",
                    marks: [
                    ],
                    text: " function.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Trigonometric functions",
            slug: {
              _type: "slug",
              current: "trigonometric-functions",
            },
          },
          {
            alternatives: [
              {
                _key: "84f1dbbf8298",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "f0a7693ee104",
                    _type: "block",
                    children: [
                      {
                        _key: "8dd42bf7626e",
                        _type: "span",
                        marks: [
                        ],
                        text: "To convert 180 degrees to radians:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "78d342d541ec",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(radians(180))",
                  },
                ],
                name: "degrees to radians",
                slug: {
                  _type: "slug",
                  current: "degrees-to-radians",
                },
              },
              {
                _key: "ec66cb47a573",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "26e9f2531c89",
                    _type: "block",
                    children: [
                      {
                        _key: "c004fb935e3d",
                        _type: "span",
                        marks: [
                        ],
                        text: "To convert 3 radians to degrees:",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "51821cdf5a9f",
                    _type: "python",
                    main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(degrees(3))",
                  },
                ],
                name: "radians to degrees",
                slug: {
                  _type: "slug",
                  current: "radians-to-degrees",
                },
              },
            ],
            alternativesLabel: "Select conversion:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            name: "Convert degrees and radians",
            slug: {
              _type: "slug",
              current: "convert-degrees-and-radians",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "cec4b05b4a66",
                _type: "block",
                children: [
                  {
                    _key: "63c2659bca68",
                    _type: "span",
                    marks: [
                    ],
                    text: "To show 10 to base 2:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "6d7a7b0875fc",
                _type: "python",
                main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(log(10, 2))",
              },
            ],
            detailContent: [
              {
                _key: "b8fe2b8ce663",
                _type: "block",
                children: [
                  {
                    _key: "84c58fcdcef7",
                    _type: "span",
                    marks: [
                    ],
                    text: "To show log 2 to base 10:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "edef0ff6aa82",
                _type: "python",
                main: "from microbit import *\nfrom math import *\n\n\ndisplay.scroll(log(2,10))\n",
              },
            ],
            name: "Logarithms",
            slug: {
              _type: "slug",
              current: "logarithms",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "e0868399fa96",
                _type: "block",
                children: [
                  {
                    _key: "01240393c029",
                    _type: "span",
                    marks: [
                    ],
                    text: "Python has some useful ways of representing and converting binary and hexadecimal numbers. These do not need ",
                  },
                  {
                    _key: "3f02623b9d44",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "import math",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "aac66dbdd729",
                _type: "block",
                children: [
                  {
                    _key: "dd4b93f96c00",
                    _type: "span",
                    marks: [
                    ],
                    text: "Binary numbers are represented with ",
                  },
                  {
                    _key: "cb983b1f435c",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "0b",
                  },
                  {
                    _key: "7e097106de6b",
                    _type: "span",
                    marks: [
                    ],
                    text: " at the start:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "ba5ef0e7c24a",
                _type: "python",
                main: "from microbit import *\n\n\nnumber = 0b11111111\ndisplay.scroll(number)",
              },
            ],
            detailContent: [
              {
                _key: "04827773c556",
                _type: "block",
                children: [
                  {
                    _key: "dd61df65f050",
                    _type: "span",
                    marks: [
                    ],
                    text: "The example above displays 255, the base 10 (denary) equivalent of binary 11111111.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "e2efaf3836f3",
                _type: "block",
                children: [
                  {
                    _key: "5a4564913ee1",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "5ea18e6fe64d",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "bin()",
                  },
                  {
                    _key: "8c44787effd8",
                    _type: "span",
                    marks: [
                    ],
                    text: " to convert a number to a binary string representation:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "d04ed4900945",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(bin(255))",
              },
              {
                _key: "cebce990671d",
                _type: "block",
                children: [
                  {
                    _key: "bf1e36221bdd",
                    _type: "span",
                    marks: [
                    ],
                    text: "This shows base 10 (denary) 255 as a binary string '0b11111111'.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Binary numbers",
            slug: {
              _type: "slug",
              current: "binary-numbers",
            },
          },
          {
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "0f5a0f271c8d",
                _type: "block",
                children: [
                  {
                    _key: "9251b6858dc0",
                    _type: "span",
                    marks: [
                    ],
                    text: "Hexadecimal numbers are represented with ",
                  },
                  {
                    _key: "852f4e24899f",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "0x",
                  },
                  {
                    _key: "1e9da88faa6a",
                    _type: "span",
                    marks: [
                    ],
                    text: " at the start:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "191b5099f1c8",
                _type: "python",
                main: "from microbit import *\n\n\nnumber = 0xFF\ndisplay.scroll(number)",
              },
            ],
            detailContent: [
              {
                _key: "164937954792",
                _type: "block",
                children: [
                  {
                    _key: "4b2e401dd125",
                    _type: "span",
                    marks: [
                    ],
                    text: "This assigns hexadecimal number FF to the variable ",
                  },
                  {
                    _key: "53b1f402f5e8",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "number",
                  },
                  {
                    _key: "f1d89565c6d6",
                    _type: "span",
                    marks: [
                    ],
                    text: ", and displays it as base 10 (denary) 255.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "fc980575f8e4",
                _type: "block",
                children: [
                  {
                    _key: "d2dee7fcebad",
                    _type: "span",
                    marks: [
                    ],
                    text: "Use ",
                  },
                  {
                    _key: "0a1f3567e5a5",
                    _type: "span",
                    marks: [
                      "code",
                    ],
                    text: "hex()",
                  },
                  {
                    _key: "75d768c8022b",
                    _type: "span",
                    marks: [
                    ],
                    text: " to convert a number to a hexadecimal string representation:",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
              {
                _key: "cdac1da1d8ca",
                _type: "python",
                main: "from microbit import *\n\n\ndisplay.scroll(hex(255))",
              },
              {
                _key: "5f578e06bd98",
                _type: "block",
                children: [
                  {
                    _key: "6e4144591cf1",
                    _type: "span",
                    marks: [
                    ],
                    text: "This shows base 10 (denary) 255 as hexadecimal 0xFF.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: " Hexadecimal numbers",
            slug: {
              _type: "slug",
              current: "hexadecimal-numbers",
            },
          },
          {
            alternatives: [
              {
                _key: "1ab4cb947a8a",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "49621e416124",
                    _type: "block",
                    children: [
                      {
                        _key: "1c6e24d7a4bc",
                        _type: "span",
                        marks: [
                        ],
                        text: "The ‘and’ operator ",
                      },
                      {
                        _key: "b8a1fc9f3564",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "&",
                      },
                      {
                        _key: "23b0f0abe1d6",
                        _type: "span",
                        marks: [
                        ],
                        text: " output of any given digit will be 1 if both digits are 1. This example will mask out the the last (least significant) 4 bits and only keep the first (most significant) 4 bits of y. It will display 0b1010000.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "51205b1c4c25",
                    _type: "python",
                    main: "from microbit import *\n\n\nx = 0b11110000\ny = 0b10101010\ndisplay.scroll(bin(x & y))",
                  },
                ],
                name: "bitwise and",
                slug: {
                  _type: "slug",
                  current: "bitwise-and",
                },
              },
              {
                _key: "7553ce592123",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "73c7c109b3c3",
                    _type: "block",
                    children: [
                      {
                        _key: "7271282aa17e",
                        _type: "span",
                        marks: [
                        ],
                        text: "The 'or' operator ",
                      },
                      {
                        _key: "74b137b3afd4",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "|",
                      },
                      {
                        _key: "9cd24ca35aff",
                        _type: "span",
                        marks: [
                        ],
                        text: " will output a 1 if either digit is 1.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "2d2fda578789",
                    _type: "python",
                    main: "from microbit import *\n\n\nx = 0b11110000\ny = 0b10101010\ndisplay.scroll(bin(x | y))",
                  },
                ],
                name: "bitwise or",
                slug: {
                  _type: "slug",
                  current: "bitwise-or",
                },
              },
              {
                _key: "d7d3e8a5879f",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b8e887a653d5",
                    _type: "block",
                    children: [
                      {
                        _key: "caa0e0579158",
                        _type: "span",
                        marks: [
                        ],
                        text: "The ‘exclusive or’ operator ",
                      },
                      {
                        _key: "9cff7fe14076",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "^",
                      },
                      {
                        _key: "3f07e1cf7e31",
                        _type: "span",
                        marks: [
                        ],
                        text: " will output a 1 if either digit is 1 ",
                      },
                      {
                        _key: "11b22ab1f1fe",
                        _type: "span",
                        marks: [
                          "strong",
                        ],
                        text: "but not both.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "a6056f5d4851",
                    _type: "block",
                    children: [
                      {
                        _key: "81213b059bd2",
                        _type: "span",
                        marks: [
                        ],
                        text: "This displays 0b11101111",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "525851b282b3",
                    _type: "python",
                    main: "from microbit import *\n\n\nx = 0b11110000\ny = 0b00011111\ndisplay.scroll(bin(x ^ y))",
                  },
                ],
                name: "bitwise exclusive or",
                slug: {
                  _type: "slug",
                  current: "bitwise-exclusive-or",
                },
              },
              {
                _key: "453ac90c5f6e",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "1db2f7ce2a29",
                    _type: "block",
                    children: [
                      {
                        _key: "c2354bbafbac",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "fb50c599f2e1",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "<<",
                      },
                      {
                        _key: "29450d04db16",
                        _type: "span",
                        marks: [
                        ],
                        text: " to shift binary digits left. Each shift to the left multiplies the number by 2. This shifts the number left by 4 digits. The result here is 0b11110000. Note that spaces are filled with zeroes.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "501982337a25",
                    _type: "python",
                    main: "from microbit import *\n\n\nx = 0b00001111\ndisplay.scroll(bin(x << 4))",
                  },
                ],
                name: "bitwise shift left",
                slug: {
                  _type: "slug",
                  current: "bitwise-shift-left",
                },
              },
              {
                _key: "aaf7247f2d2a",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "85960c2c282d",
                    _type: "block",
                    children: [
                      {
                        _key: "c650699e8bdc",
                        _type: "span",
                        marks: [
                        ],
                        text: "Use ",
                      },
                      {
                        _key: "f569ab02219d",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: ">>",
                      },
                      {
                        _key: "ae9adb191be2",
                        _type: "span",
                        marks: [
                        ],
                        text: " to shift right. Each shift to the right divides the number by 2. This example shifts the digits 2 digits to the right. The result here is 0b11. Digits are lost as they move beyond the least significant digit.",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "c1858ae7f935",
                    _type: "python",
                    main: "from microbit import *\n\n\nx = 0b00001111\ndisplay.scroll(bin(x >> 2))",
                  },
                ],
                name: "bitwise shift right",
                slug: {
                  _type: "slug",
                  current: "bitwise-shift-right",
                },
              },
              {
                _key: "558ec072be89",
                _type: "toolkitAlternativeContent",
                content: [
                  {
                    _key: "b485a6494c92",
                    _type: "block",
                    children: [
                      {
                        _key: "49439a850815",
                        _type: "span",
                        marks: [
                        ],
                        text: "The bitwise complement operator ",
                      },
                      {
                        _key: "ac3a13c9b390",
                        _type: "span",
                        marks: [
                          "code",
                        ],
                        text: "~",
                      },
                      {
                        _key: "cda7f8a1708b",
                        _type: "span",
                        marks: [
                        ],
                        text: " performs a binary one’s complement operation on a number. In base 10 this has the effect of changing the number’s sign and subtracting one. This displays -0b1001 (-9 in denary / base 10):",
                      },
                    ],
                    markDefs: [
                    ],
                    style: "normal",
                  },
                  {
                    _key: "176288afec35",
                    _type: "python",
                    main: "from microbit import *\n\n\nx = 0b1000\ndisplay.scroll(bin(~x))",
                  },
                ],
                name: "bitwise complement",
                slug: {
                  _type: "slug",
                  current: "bitwise-complement",
                },
              },
            ],
            alternativesLabel: "Select operator:",
            compatibility: [
              "microbitV1",
              "microbitV2",
            ],
            content: [
              {
                _key: "0bd45c71cbe4",
                _type: "block",
                children: [
                  {
                    _key: "46e1d733fa03",
                    _type: "span",
                    marks: [
                    ],
                    text: "Bitwise operators allow you to perform operations on numbers at the bit, or binary, level.",
                  },
                ],
                markDefs: [
                ],
                style: "normal",
              },
            ],
            name: "Bitwise operators",
            slug: {
              _type: "slug",
              current: "bitwise-operators",
            },
          },
        ],
        image: {
          _type: "image",
          asset: {
            _ref: "image-e41ddb530173e3a6ab5ade0b6b0f7a51504e1f76-144x144-svg",
            _type: "reference",
          },
        },
        introduction: [
          {
            _key: "bd52ae031b84",
            _type: "block",
            children: [
              {
                _key: "3956abe72aad",
                _type: "span",
                marks: [
                ],
                text: "Using ",
              },
              {
                _key: "75ab558b0839",
                _type: "span",
                marks: [
                  "code",
                ],
                text: "from math import *",
              },
              {
                _key: "7a55acb50727",
                _type: "span",
                marks: [
                ],
                text: " at the start of your program gives you access to some advanced mathematical functions.",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
          {
            _key: "d033261821e1",
            _type: "block",
            children: [
              {
                _key: "16ccd4005c820",
                _type: "span",
                marks: [
                ],
                text: "",
              },
            ],
            markDefs: [
            ],
            style: "normal",
          },
        ],
        name: "Advanced maths",
        slug: {
          _type: "slug",
          current: "advanced-maths",
        },
        subtitle: "Add more maths functions to Python",
      },
    ],
    description: "Explore the Python language and the features of your micro:bit",
    language: "en",
    name: "Reference",
  }
  toolkit.contents?.forEach((topic: { contents: any[]; }) => {
    topic.contents?.forEach((entry) => {
      entry.parent = topic;
    });
  });
  return toolkit;
  
};
