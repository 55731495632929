/**
 * @author Simon
 * @description usb serial
 */
// const FakeWebsocket = require('./fake-websocket');
// const Base64Util = require('./base64-util');
import FakeWebsocket from './fake-websocket.js';
import Base64Util from './base64-util.js';

class ScratchLinkUsb {
    constructor(type) {
        this._type = type;
        this._onOpen = null;
        this._onClose = null;
        this._onError = null;
        this._handleMessage = null;
        this._onConnect = null;

        this._ws = null;
        this._device = null;
        this._reader = null;
        this._writer = null;
        this._readyToClose = false;
        this._keepReading = false;
    }

    open() {
        if (!(this._onOpen && this._onClose && this._onError && this._handleMessage)) {
            throw new Error('Must set open, close, message and error handlers before calling open on the socket');
        }

        this._ws = new FakeWebsocket();
        this._ws.onopen = this._onOpen;
        this._ws.onclose = this._onClose;
        this._ws.onerror = this._onError;
        this._ws.onmessage = this._onMessage.bind(this);

        this._onOpen();
        if ('serial' in navigator) {
            navigator.serial.requestPort({ filters: [{ usbVendorId: 0x303a }] })
                .then(device => {
                    this._device = device;
                    this.openSerial(device);
                })
                .catch(error => {
                    console.error(error);
                    this._onError(error);
                });
            navigator.serial.addEventListener('connect', (event) => {
                console.log('usb连接成功了');
            });
            navigator.serial.addEventListener('disconnect', (event) => {
                console.log('usb断开了');
                this._onClose();
            });
            // navigator.usb.requestDevice({filters: [{vendorId: 0x4d61}]})
            //     .then(device => {
            //         // this.openSerial(device);
            //         console.log(device.productName);
            //         console.log(device.manufacturerName);
            //         return device.open();
            //     })
            //     .catch(error => {
            //         console.error(error);
            //         this._ws.onerror(error);
            //     });
        } else {
            this._onError('not support');
        }
    }

    _getPorts() {
        return new Promise(async(resolve, reject) => {
            await navigator.serial.requestPort({ filters: [{ usbVendorId: 0x303a }] })
                .then(device => {})
                .catch(error => {});
        });
    }

    getInfo() {
        // eslint-disable-next-line eqeqeq
        if (this._device !== null) {
            const info = this._device.getInfo();
            // eslint-disable-next-line max-len
            console.log(`WebSerial VendorID 0x${info.usbVendorId.toString(16)} ProductID 0x${info.usbProductId.toString(16)}`);
            return info.usbVendorId;
        }
        return null;
    }

    async write(data) {
        this._writer = this._device.writable.getWriter();
        console.log('writeData', data);
        await this._writer.write(data.buffer);
        this._writer.releaseLock();
    }

    portWrite(value) {
        return new Promise(async(resolve, reject) => {
            if (this._device === null) {
                reject();
                return;
            }
            this._writer = this._device.writable.getWriter();
            await this._writer.write(value.buffer);
            this._writer.releaseLock();
            resolve(value);
        });
    }

    async readText() {
        while (this._device && this._device.readable && this._keepReading) {
            this._reader = this._device.readable.getReader();
            this._readyToClose = false;
            try {
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    const { value, done } = await this._reader.read();
                    if (done) {
                        // this._reader.releaseLock();
                        break;
                    }
                    if (value) {
                        // console.log(value);
                        const data = Base64Util.uint8ArrayToBase64(value);
                        // eslint-disable-next-line max-len
                        this._onMessage(`{"jsonrpc":"2.0","method":"characteristicDidChange","params":{"message":"${data}","encoding":"base64"}}`);
                    }
                }
            } catch (error) {
                this._onError();
            } finally {
                this._reader.releaseLock();
                this._readyToClose = true;
            }
        }
    }

    writeText(value) {
        // console.log(value, '写入');
        this.portWrite(value)
            .then(res => {
                // console.log(res);
            });
    }

    openSerial(device) {
        device.open({ baudRate: 921600, bufferSize: 10000 })
            .then(() => {
                this._onConnect(this.getInfo());
            });
    }

    closeSerial() {
        return new Promise(async(resolve, reject) => {
            if (this._device) {
                this._keepReading = false;
                try {
                    await this._reader.cancel();
                    // await this._writer.close();
                    await this._device.close();
                    // eslint-disable-next-line no-empty
                } catch (e) {}
                this._readyToClose = false;
                resolve();
            } else {
                reject();
            }
        });
    }

    close() {
        this._ws = null;
        this.closeSerial()
            .then(r => {
                this._device = null;
                this._reader = null;
                this._writer = null;
            });
    }

    sendMessage(message) {
        // const messageText = JSON.stringify(message);
        this.parseMessage(message);
    }

    setOnOpen(fn) {
        this._onOpen = fn;
    }

    setOnClose(fn) {
        this._onClose = fn;
    }

    setOnError(fn) {
        this._onError = fn;
    }

    setHandleMessage(fn) {
        this._handleMessage = fn;
    }

    setOnConnect(fn) {
        this._onConnect = fn;
    }

    isOpen() {
        return this._ws;
    }

    _onMessage(e) {
        // console.log(e);
        const json = JSON.parse(e);
        this._handleMessage(json);
    }

    parseMessage(messageText) {
        // console.log(messageText);
        const { method, params, id } = messageText;
        // console.log(method);
        // console.log(params);
        let data;
        switch (method) {
            case 'discover':
            case 'connect':
                this._onMessage(`{"id":${id},"jsonrpc":"2.0","result":null}`);
                break;
            case 'disconnect':
                // this.closeSerial()
                //     .then(r => console.log('close'));
                // this._onClose();
                break;
            case 'startNotifications':
                this._onMessage(`{"id":${id},"jsonrpc":"2.0","result":null}`);
                this._keepReading = true;
                this.readText()
                    .then(r => console.log(r));
                break;
            case 'write':
                data = Base64Util.base64ToUint8Array(params.message);
                this.portWrite(data)
                    .then(res => {
                        this._onMessage(`{"id":${id},"jsonrpc":"2.0","result":${data.length}}`);
                    });
                break;
            default:
                break;
        }
    }
}

// module.exports = ScratchLinkUsb;
export default ScratchLinkUsb;